import React from "react";

function ArrowCircleDownWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.875"
      height="14.875"
      viewBox="0 0 14.875 14.875"
    >
      <path
        id="Icon_awesome-arrow-circle-down"
        data-name="Icon awesome-arrow-circle-down"
        d="M15.438,8A7.438,7.438,0,1,1,8,.563,7.436,7.436,0,0,1,15.438,8Zm-4.307-.867L8.96,9.4V3.921a.718.718,0,0,0-.72-.72H7.76a.718.718,0,0,0-.72.72V9.4L4.869,7.133A.721.721,0,0,0,3.84,7.121l-.327.33a.717.717,0,0,0,0,1.017l3.977,3.98a.717.717,0,0,0,1.017,0l3.98-3.98a.717.717,0,0,0,0-1.017l-.327-.33a.721.721,0,0,0-1.029.012Z"
        transform="translate(-0.563 -0.563)"
        fill="#fff"
      />
    </svg>
  );
}
export default ArrowCircleDownWhite;
