const textResolver = (jsonData, dataKey, languageCode = "en") => {
  let noDataFound = "No Data Found";
  return jsonData
    ? jsonData[dataKey]
      ? jsonData[dataKey][languageCode]
        ? jsonData[dataKey][languageCode]
        : jsonData[dataKey]["default"]
        ? jsonData[dataKey]["default"]
        : noDataFound
      : noDataFound
    : noDataFound;
};

export default textResolver;
