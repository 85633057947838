import React from "react";

function SecureIcon() {
  return (
    <svg
      id="Group_3646"
      data-name="Group 3646"
      xmlns="http://www.w3.org/2000/svg"
      width="24.047"
      height="26.489"
      viewBox="0 0 24.047 26.489"
    >
      <path
        id="Path_3655"
        data-name="Path 3655"
        d="M1201.089,379.567c.274.125.3.335.234.6a18.7,18.7,0,0,1-7.013,10.577,25.694,25.694,0,0,1-4.073,2.466c-.088.045-.18.08-.271.12h-.466c-.847-.467-1.721-.883-2.544-1.394a19.251,19.251,0,0,1-8.035-9.433,18,18,0,0,1-.74-2.384.442.442,0,0,1,.2-.556c.315-.105.506-.009.594.33s.171.668.268,1a17.251,17.251,0,0,0,3.428,6.316,19.507,19.507,0,0,0,6.276,4.891c.177.089.345.194.52.289a.567.567,0,0,0,.587,0,27.2,27.2,0,0,0,3.054-1.788,19.361,19.361,0,0,0,1.869-1.466,17.459,17.459,0,0,0,2.295-2.446,17.135,17.135,0,0,0,3.081-6.039c.069-.249.125-.5.195-.75C1200.63,379.6,1200.833,379.48,1201.089,379.567Z"
        transform="translate(-1177.731 -366.842)"
        fill="#00173a"
      />
      <path
        id="Path_3656"
        data-name="Path 3656"
        d="M1194.689,229.283a18.083,18.083,0,0,1-2.469,1.489c-.228.121-.458.237-.684.361a.405.405,0,0,1-.413.006,23.548,23.548,0,0,1-4.445-2.8,17.807,17.807,0,0,1-6.179-9.707c-.1-.4-.191-.475-.558-.439.116-.168,0-.328-.021-.49a5.481,5.481,0,0,1-.083-.753c.372-.381.159-.844.162-1.271,0-.247-.032-.5-.032-.748q0-1.927,0-3.853c0-.5.091-.574.586-.667a4.86,4.86,0,0,0,4.019-3.335,5.259,5.259,0,0,0,.123-.529.4.4,0,0,1,.447-.376c.086,0,.172,0,.259,0h10.938a1.146,1.146,0,0,1,.435.037.149.149,0,0,1,.084.122,6.9,6.9,0,0,0,.559,1.87.169.169,0,0,1-.033.119c-.169,0-.208-.142-.257-.25a1.256,1.256,0,0,0-1.3-.777q-4.538.023-9.076,0a1.235,1.235,0,0,0-1.219.736,6.643,6.643,0,0,1-3.44,3.177,1.322,1.322,0,0,0-.894,1.337c0,.715-.015,1.431,0,2.146a16.283,16.283,0,0,0,3.429,9.754,19.174,19.174,0,0,0,5.753,4.922,1.7,1.7,0,0,0,1.919-.021,21.786,21.786,0,0,0,3.349-2.371,17.48,17.48,0,0,0,3.662-4.483,16.017,16.017,0,0,0,1.925-5.51.646.646,0,0,1,.111-.339c.1-.009.1.072.12.136a12.207,12.207,0,0,1-.3,2.171,16.882,16.882,0,0,1-1.467,4.182,17.674,17.674,0,0,1-2.47,3.712,16.2,16.2,0,0,1-1.827,1.857C1195.173,228.9,1194.927,229.086,1194.689,229.283Z"
        transform="translate(-1179.294 -205.474)"
        fill="#c8e2ff"
      />
      <path
        id="Path_3657"
        data-name="Path 3657"
        d="M1189.513,196.875c-.077-.005-.154-.015-.231-.015q-5.69,0-11.379,0c-.246,0-.38.065-.429.325a4.742,4.742,0,0,1-3.333,3.732,4.863,4.863,0,0,1-1.081.242.294.294,0,0,0-.3.336c.022,1.75-.049,3.5.052,5.249a1.9,1.9,0,0,0,.028.257.465.465,0,0,1-.292.584c-.349-.051-.437-.147-.463-.5-.138-1.886-.052-3.774-.067-5.661A.988.988,0,0,1,1173,200.4a4.494,4.494,0,0,0,2.14-.831,3.832,3.832,0,0,0,1.6-2.555,1.018,1.018,0,0,1,1.073-.911q6.219,0,12.438,0a.991.991,0,0,1,1.054.868,4.179,4.179,0,0,0,3.684,3.42,1.039,1.039,0,0,1,1.059,1.194q-.005,2.314-.018,4.629c0,.31-.036.619-.057.929a.41.41,0,0,1-.3.4.4.4,0,0,1-.526-.465c.16-1.737.06-3.478.088-5.217,0-.052,0-.1,0-.155,0-.4-.052-.446-.449-.511a5.3,5.3,0,0,1-3-1.508,4.548,4.548,0,0,1-1.261-2.388c-.056-.3-.108-.335-.41-.351C1189.914,196.937,1189.7,197.007,1189.513,196.875Z"
        transform="translate(-1172.002 -196.1)"
        fill="#00173a"
      />
      <path
        id="Path_3658"
        data-name="Path 3658"
        d="M1396.786,207.111c.258-.005.515-.011.773-.015.236,0,.27.173.3.348a4.466,4.466,0,0,0,1.43,2.6,5.162,5.162,0,0,0,2.778,1.318c.5.077.512.079.507.575-.018,1.637.048,3.274-.045,4.91-.008.137-.021.275-.035.412-.038.364.084.507.45.526-.046.058-.139.068-.152.163-.051.373-.106.745-.159,1.118-.333.01-.421.081-.5.429a17.609,17.609,0,0,1-5.2,8.855,15.1,15.1,0,0,1-2.255,1.8c.33-.309.644-.64,1-.922a11.649,11.649,0,0,0,1.316-1.342,16.523,16.523,0,0,0,1.663-2.191,17.737,17.737,0,0,0,2.524-6.268,10.212,10.212,0,0,0,.223-1.756c.056-.081.008-.158-.011-.235a1.873,1.873,0,0,0-.048-.43c.041-.988.1-1.976.084-2.966a7.073,7.073,0,0,0-.072-1.209,3,3,0,0,0,.03-.54.534.534,0,0,0-.506-.6,4.889,4.889,0,0,1-1.47-.469,5.127,5.127,0,0,1-.857-.574c-.216-.239-.469-.443-.668-.7a3.051,3.051,0,0,1-.516-.863A4.7,4.7,0,0,1,1396.786,207.111Z"
        transform="translate(-1379.274 -206.336)"
        fill="#e8f3ff"
      />
      <path
        id="Path_3659"
        data-name="Path 3659"
        d="M1219.057,226.045a2.474,2.474,0,0,1,.082.662c-.008,1.007.02,2.014-.037,3.02-.012.206-.028.411-.043.616a.771.771,0,0,1-.01.355.186.186,0,0,1-.042.11,16.245,16.245,0,0,1-2.346,6.471,18.354,18.354,0,0,1-7.125,6.511,1.132,1.132,0,0,1-1.115.013,17.674,17.674,0,0,1-8.833-10.1,15.528,15.528,0,0,1-.8-4.949c0-.715,0-1.431,0-2.146a1.247,1.247,0,0,1,.84-1.26,6.651,6.651,0,0,0,3.524-3.255,1.2,1.2,0,0,1,1.163-.708q4.642,0,9.283,0a1.238,1.238,0,0,1,1.209.762c.059.122.134.237.2.355.214.222.332.515.543.739a7.189,7.189,0,0,0,.638.677,6.884,6.884,0,0,0,2.116,1.363,1.506,1.506,0,0,1,.609.422A.478.478,0,0,1,1219.057,226.045Z"
        transform="translate(-1196.936 -219.631)"
        fill="#00173a"
      />
      <path
        id="Path_3660"
        data-name="Path 3660"
        d="M1433.924,236.654a3.007,3.007,0,0,1-.519-.77l.009-.122.568.828C1433.963,236.612,1433.957,236.645,1433.924,236.654Z"
        transform="translate(-1415.331 -233.02)"
        fill="#ced2d4"
      />
      <path
        id="Path_3661"
        data-name="Path 3661"
        d="M1491.242,356.257q0-.052,0-.1c.054-.025.067.025.094.05-.006.072.024.152-.05.208C1491.283,356.356,1491.3,356.3,1491.242,356.257Z"
        transform="translate(-1469.17 -345.08)"
        fill="#cfd4d7"
      />
      <path
        id="Path_3662"
        data-name="Path 3662"
        d="M1452.707,259.605a1.42,1.42,0,0,0-.821-.731,6.179,6.179,0,0,1-2.088-1.375c-.019-.056.008-.072.059-.066a4.82,4.82,0,0,0,1.91.955c.158.034.319.052.478.082a.579.579,0,0,1,.506.608A1.04,1.04,0,0,1,1452.707,259.605Z"
        transform="translate(-1430.586 -253.191)"
        fill="#c8e2ff"
      />
      <path
        id="Path_3663"
        data-name="Path 3663"
        d="M1441.6,248.4l-.059.066a3.253,3.253,0,0,1-.62-.674l.057-.064Z"
        transform="translate(-1422.325 -244.162)"
        fill="#d7dbde"
      />
      <path
        id="Path_3664"
        data-name="Path 3664"
        d="M1491.348,351.438l-.094-.05.051-.361C1491.394,351.157,1491.33,351.3,1491.348,351.438Z"
        transform="translate(-1469.182 -340.316)"
        fill="#dce0e2"
      />
      <path
        id="Path_3665"
        data-name="Path 3665"
        d="M1219.145,253.371a.363.363,0,0,1-.269-.068,17.224,17.224,0,0,1-7.75-8,14.791,14.791,0,0,1-1.4-6.2c-.006-.767,0-1.534-.005-2.3a.527.527,0,0,1,.368-.552,7.391,7.391,0,0,0,3.9-3.6c.071-.142.153-.272.333-.291.411-.031.823-.013,1.235-.012,1.193,0,2.386-.016,3.579.01a.161.161,0,0,1,.064.108,4.886,4.886,0,0,1-.023,1.277c-.069.1-.178.091-.279.1a3.613,3.613,0,0,0-3.354,3.724c0,.457,0,.913,0,1.37,0,.337-.052.381-.4.388-.086,0-.172,0-.259,0a1.112,1.112,0,0,0-1.153,1.164c-.008.672,0,1.344,0,2.016q0,1.59,0,3.18a1.156,1.156,0,0,0,1.131,1.282,2.866,2.866,0,0,0,.387.017c1.172,0,2.344,0,3.515,0,.142,0,.3-.035.41.1a.85.85,0,0,1,.047.381q0,2.741,0,5.481A.571.571,0,0,1,1219.145,253.371Z"
        transform="translate(-1207.118 -229.836)"
        fill="#f5faff"
      />
      <path
        id="Path_3666"
        data-name="Path 3666"
        d="M1345.958,253.317V247a.522.522,0,0,1,.372-.068q1.759,0,3.517,0a2.982,2.982,0,0,0,.489-.033,1.149,1.149,0,0,0,1.076-1.233q.018-2.56,0-5.12a1.18,1.18,0,0,0-.71-1.188,1.36,1.36,0,0,0-.557-.086c-.572,0-.59-.021-.591-.6,0-.466.009-.931,0-1.4a3.605,3.605,0,0,0-3.364-3.487c-.085-.006-.175,0-.232-.086v-1.378c.077-.061.166-.029.249-.029,1.06,0,2.12.011,3.179-.01a.541.541,0,0,1,.56.348,7.736,7.736,0,0,0,1.108,1.677,7.3,7.3,0,0,0,2.37,1.756c.117.055.236.106.356.155a.848.848,0,0,1,.569.82c0,1.093.036,2.186-.029,3.281a15.489,15.489,0,0,1-1.359,5.435,17.742,17.742,0,0,1-2.429,3.892,17.373,17.373,0,0,1-2.134,2.241,14.708,14.708,0,0,1-2.24,1.394A.281.281,0,0,1,1345.958,253.317Z"
        transform="translate(-1333.931 -229.781)"
        fill="#eef6ff"
      />
      <path
        id="Path_3667"
        data-name="Path 3667"
        d="M1283.531,251.645c.033-.127.143-.192.228-.273a20.626,20.626,0,0,0,3.159-3.829,16.207,16.207,0,0,0,2.247-5.641,26.6,26.6,0,0,0,.252-5.141.715.715,0,0,0-.332-.611,2.949,2.949,0,0,0-.46-.229,7.247,7.247,0,0,1-1.82-1.154,7.148,7.148,0,0,1-1.793-2.364.534.534,0,0,0-.558-.337c-1.12.021-2.241.011-3.362.012l-4.484-.006c-.111,0-.223-.007-.334-.011a.432.432,0,0,1,.276-.034q4.551,0,9.1,0a.624.624,0,0,1,.629.387,7.325,7.325,0,0,0,3.717,3.472c.493.217.517.235.51.766-.014,1.025.033,2.05-.039,3.075a15.211,15.211,0,0,1-2.174,6.823,17.075,17.075,0,0,1-4.525,4.944C1283.694,251.551,1283.61,251.6,1283.531,251.645Z"
        transform="translate(-1269.066 -229.538)"
        fill="#c8e2ff"
      />
      <path
        id="Path_3668"
        data-name="Path 3668"
        d="M1272.091,252.793a3.664,3.664,0,0,1,3.241,1.921,3.416,3.416,0,0,1,.452,1.756c0,.526.008,1.052,0,1.577,0,.2.058.255.25.243a2.519,2.519,0,0,1,.618.02,1.17,1.17,0,0,1,.96,1.184q.019,2.689,0,5.378a1.156,1.156,0,0,1-1.148,1.193c-.593.035-1.189.013-1.783.017l-2.585.012q-1.874-.007-3.747-.014a6.368,6.368,0,0,1-.671-.02,1.167,1.167,0,0,1-1.066-1.189q-.008-2.689,0-5.378a1.182,1.182,0,0,1,1.212-1.209c.6-.009.594-.009.6-.625a9.635,9.635,0,0,1,.074-1.907,3.675,3.675,0,0,1,3.517-2.951C1272.04,252.8,1272.066,252.8,1272.091,252.793Z"
        transform="translate(-1260.064 -248.873)"
        fill="#00173a"
      />
      <path
        id="Path_3669"
        data-name="Path 3669"
        d="M1285.9,349.388l-4.675.007q-1.511,0-3.022,0c-.389,0-.542-.145-.542-.522q0-2.622,0-5.244c0-.32.082-.424.366-.5a.618.618,0,0,1,.255-.019h7.39c.336,0,.392.049.448.387a1.9,1.9,0,0,1,.019.309q0,2.429,0,4.858a1.667,1.667,0,0,1-.05.461A.354.354,0,0,1,1285.9,349.388Z"
        transform="translate(-1270.35 -332.944)"
        fill="#ff7b16"
      />
      <path
        id="Path_3670"
        data-name="Path 3670"
        d="M1306.927,263.981a2.9,2.9,0,0,1,2.959,2.421,20.543,20.543,0,0,1,.05,2.143c.007.192-.124.148-.227.148q-1.5,0-3,0c-.793,0-1.586,0-2.379,0-.078,0-.173.046-.237-.045a.538.538,0,0,1,.393-.084c.689,0,1.377,0,2.066,0,.335,0,.352-.016.352-.348q0-1.963,0-3.926A1.223,1.223,0,0,1,1306.927,263.981Z"
        transform="translate(-1294.961 -259.282)"
        fill="#c8e2ff"
      />
      <path
        id="Path_3671"
        data-name="Path 3671"
        d="M1306.52,264.067c.083.063.06.155.06.238,0,1.393,0,2.785.006,4.178,0,.217-.079.255-.27.253-.877-.008-1.754,0-2.63,0a12.584,12.584,0,0,1,.05-2.347A2.869,2.869,0,0,1,1306.52,264.067Z"
        transform="translate(-1294.554 -259.368)"
        fill="#f5faff"
      />
      <path
        id="Path_3672"
        data-name="Path 3672"
        d="M1290.846,349.135a1.208,1.208,0,0,0,.155-.616q0-2.532,0-5.064a1.236,1.236,0,0,0-.005-.181c-.052-.368-.079-.39-.453-.39h-7.234c-.112,0-.224,0-.335-.007a.429.429,0,0,1,.276-.037l8.171,0c.537,0,.668.134.668.672v4.991c0,.5-.136.637-.648.636C1291.242,349.142,1291.044,349.138,1290.846,349.135Z"
        transform="translate(-1275.301 -332.691)"
        fill="#c25f14"
      />
      <path
        id="Path_3673"
        data-name="Path 3673"
        d="M1333.9,372.2a2.337,2.337,0,0,1,0-.284.471.471,0,0,0-.214-.473.858.858,0,0,1-.058-1.242.887.887,0,0,1,1.251-.045.853.853,0,0,1,.019,1.243.665.665,0,0,0-.24.624,5.23,5.23,0,0,1,0,.593c-.017.275-.192.451-.411.434s-.344-.19-.348-.464C1333.9,372.456,1333.9,372.327,1333.9,372.2Z"
        transform="translate(-1322.238 -357.899)"
        fill="#00173a"
      />
    </svg>
  );
}
export default SecureIcon;
