import React from "react";

function BusinessCustomersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.751"
      height="25.017"
      viewBox="0 0 29.751 25.017"
    >
      <defs></defs>
      <g transform="translate(182.466 -359.132)">
        <path
          className="a"
          fill="#ff7b16"
          d="M-182.466,383.451c.152-.463.2-.952.393-1.406a5.5,5.5,0,0,1,4.377-3.469,17.715,17.715,0,0,1,2.666-.065c.687-.011,1.375,0,2.062,0,.136,0,.2.027.212.187a5.317,5.317,0,0,0,1.541,3.441,1.189,1.189,0,0,1,.082.112,6.626,6.626,0,0,0-2.1,1.084,6.523,6.523,0,0,0-2.606,5.086c-.016.406-.024.814,0,1.219.016.275-.068.329-.332.307a27.367,27.367,0,0,1-5.626-1.071l-.665-.2Z"
          transform="translate(0 -10.358)"
        />
        <path
          className="a"
          fill="#ff7b16"
          d="M-151.134,395.632c0,.793-.007,1.587.006,2.38a.342.342,0,0,1-.235.368,12.977,12.977,0,0,1-3.546,1.031,23.4,23.4,0,0,1-10.539-.9c-.554-.166-.555-.165-.554-.738,0-1.384-.021-2.768.009-4.151a5.518,5.518,0,0,1,5.246-5.431,41.077,41.077,0,0,1,4.584.009,5.594,5.594,0,0,1,4.811,4.024,7.038,7.038,0,0,1,.218,1.749C-151.132,394.529-151.134,395.08-151.134,395.632Z"
          transform="translate(-8.804 -15.517)"
        />
        <path
          className="a"
          fill="#ff7b16"
          d="M-141.945,382.261a8.727,8.727,0,0,0,.724-.884,5.169,5.169,0,0,0,.889-2.6c.01-.186.054-.264.267-.262,1.21.013,2.42-.012,3.63.015a5.542,5.542,0,0,1,5.365,5.232c.065,1.566.016,3.136.028,4.7,0,.158-.09.19-.193.241a12.263,12.263,0,0,1-3.083.969,20.538,20.538,0,0,1-2.677.317c-.189.012-.226-.031-.225-.208a11.943,11.943,0,0,0-.087-2.173,6.537,6.537,0,0,0-4.372-5.255C-141.759,382.329-141.838,382.3-141.945,382.261Z"
          transform="translate(-21.678 -10.37)"
        />
        <path
          className="a"
          fill="#ff7b16"
          d="M-159.381,373.155a4.36,4.36,0,0,1,4.357-4.353,4.38,4.38,0,0,1,4.362,4.358,4.365,4.365,0,0,1-4.382,4.359A4.343,4.343,0,0,1-159.381,373.155Z"
          transform="translate(-12.35 -5.173)"
        />
        <path
          className="a"
          fill="#ff7b16"
          d="M-171.495,359.137a4.357,4.357,0,0,1,4.355,4.319.517.517,0,0,1-.2.446,5.227,5.227,0,0,0-1.8,3.058.414.414,0,0,1-.213.315,4.364,4.364,0,0,1-6.437-2.938A4.364,4.364,0,0,1-171.495,359.137Z"
          transform="translate(-3.53 -0.003)"
        />
        <path
          className="a"
          fill="#ff7b16"
          d="M-139.492,359.132a4.358,4.358,0,0,1,4.339,4.1,4.35,4.35,0,0,1-3.965,4.6,4.332,4.332,0,0,1-1.987-.289.38.38,0,0,1-.284-.338,5.275,5.275,0,0,0-2.314-3.659.36.36,0,0,1-.146-.393A4.362,4.362,0,0,1-139.492,359.132Z"
          transform="translate(-20.657)"
        />
      </g>
    </svg>
  );
}

export default BusinessCustomersIcon;
