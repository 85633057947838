import React from "react";

function ConfidentialityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      viewBox="0 0 65 65"
    >
      <g
        id="Group_3855"
        data-name="Group 3855"
        transform="translate(-101 -3341)"
      >
        <path
          id="Path_3464"
          data-name="Path 3464"
          d="M32.5,0A32.5,32.5,0,1,1,0,32.5,32.5,32.5,0,0,1,32.5,0Z"
          transform="translate(101 3341)"
          fill="#ff7b16"
        />
        <g
          id="Group_3854"
          data-name="Group 3854"
          transform="translate(-1175.167 1042.93)"
        >
          <path
            id="Path_4185"
            data-name="Path 4185"
            d="M1307.637,2346.648c-4.064,0-8.047,0-12.031,0a5.282,5.282,0,0,1-.976-.1,2.692,2.692,0,0,1-2.382-2.7c-.042-.434-.066-.871-.067-1.307q-.009-9.271-.016-18.542c0-.351.069-.452.439-.452,1.459,0,2.92.083,4.377-.046a4.62,4.62,0,0,0,4.35-4.332c.149-1.566.05-3.136.062-4.7,0-.327.092-.4.4-.394q6.467.015,12.933.006a7.035,7.035,0,0,1,1.826.189,2.733,2.733,0,0,1,2.039,2.824c.1,2.059.018,4.12.045,6.18,0,.367-.215.217-.377.193a13.465,13.465,0,0,0-4.8.017,7.668,7.668,0,0,0-6.135,7.553,1.112,1.112,0,0,1-.569,1.11,3.264,3.264,0,0,0-1.6,3.06c.043,1.786.026,3.574.007,5.362a8.125,8.125,0,0,0,2.333,5.919C1307.533,2346.518,1307.563,2346.558,1307.637,2346.648Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_4186"
            data-name="Path 4186"
            d="M1292.381,2321.6a1.318,1.318,0,0,1,.4-.981q2.847-2.933,5.7-5.863a1.052,1.052,0,0,1,.123-.108c.219-.164.479-.4.72-.292.171.074.064.419.06.641-.026,1.443.083,2.889-.072,4.33s-.715,2.044-2.152,2.2C1295.574,2321.686,1293.98,2321.548,1292.381,2321.6Z"
            transform="translate(-0.19 -0.229)"
            fill="#fff"
          />
          <path
            id="Path_4187"
            data-name="Path 4187"
            d="M1447.848,2426.649a1.82,1.82,0,0,0-1.889-1.992c-.215-.013-.272-.068-.268-.277.012-.655.01-1.31,0-1.964a5.564,5.564,0,0,0-4.135-5.309,11.54,11.54,0,0,0-3.3-.165,5.493,5.493,0,0,0-5.194,4.21,13.629,13.629,0,0,0-.147,3.136c-.005.276-.063.357-.352.374a1.825,1.825,0,0,0-1.828,1.938q0,1.412,0,2.824c0,.914-.006,1.828,0,2.742a5.975,5.975,0,0,0,6.1,6.07c1.637,0,3.273,0,4.91,0a6.15,6.15,0,0,0,1.26-.115,5.924,5.924,0,0,0,4.833-5.909C1447.861,2430.359,1447.849,2428.5,1447.848,2426.649Zm-6.992,5.846a1.559,1.559,0,0,1-3.11-.046c-.015-.354,0-.709,0-1.063s-.013-.709,0-1.063a1.558,1.558,0,0,1,3.109-.035C1440.89,2431.022,1440.891,2431.76,1440.857,2432.495Zm1.321-7.844c-.968-.005-1.936,0-2.9,0-.927,0-1.854-.016-2.78.009-.321.009-.383-.1-.372-.389.023-.6,0-1.2.01-1.8a2.271,2.271,0,0,1,2.334-2.319c.586-.005,1.172-.01,1.758,0a2.2,2.2,0,0,1,2.212,2.04c.06.731.017,1.471.041,2.206C1442.485,2424.649,1442.361,2424.652,1442.177,2424.651Z"
            transform="translate(-123.41 -91.583)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default ConfidentialityIcon;
