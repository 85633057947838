import React from "react";

function SecurityManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      viewBox="0 0 65 65"
    >
      <g
        id="Group_3846"
        data-name="Group 3846"
        transform="translate(-743 -2452)"
      >
        <path
          id="Path_3464"
          data-name="Path 3464"
          d="M32.5,0A32.5,32.5,0,1,1,0,32.5,32.5,32.5,0,0,1,32.5,0Z"
          transform="translate(743 2452)"
          fill="#ff7b16"
        />
        <g
          id="Group_3845"
          data-name="Group 3845"
          transform="translate(-2308.111 285.971)"
        >
          <path
            id="Path_4182"
            data-name="Path 4182"
            d="M3094.35,2206.483a27.27,27.27,0,0,0-.071-3.246,3.249,3.249,0,0,0-2.708-2.714c-.308-.054-.4-.177-.437-.476a7.773,7.773,0,0,0-15.348-.324c-.1.517-.234.746-.789.874a3.265,3.265,0,0,0-2.481,3.191c-.016,1.975-.011,3.951,0,5.926,0,.3-.045.4-.38.4a9.282,9.282,0,0,1-8.588-6.539,9.479,9.479,0,0,1,8.088-12.262c.276-.027.312-.163.344-.366a11.317,11.317,0,0,1,5.763-8.408,11.577,11.577,0,0,1,17.093,8.122c.066.417.129.616.641.687a9.464,9.464,0,0,1,.291,18.734c-.4.057-.817.01-1.226.027-.277.012-.192-.184-.193-.317C3094.348,2208.762,3094.35,2207.732,3094.35,2206.483Z"
            transform="translate(0)"
            fill="#fff"
          />
          <path
            id="Path_4183"
            data-name="Path 4183"
            d="M3181.917,2316.25a1.98,1.98,0,0,0-.052-.5c-.186-.7-.7-1.012-1.638-1.016-.463,0-1.072.162-1.35-.069s-.08-.872-.086-1.331a5.755,5.755,0,0,0-11.28-1.572,6.433,6.433,0,0,0-.238,1.964c0,.321.108.753-.056.937-.194.218-.635.051-.969.07-.183.011-.367,0-.55,0a1.391,1.391,0,0,0-1.554,1.569q0,4.974,0,9.948c0,1.133.49,1.629,1.612,1.63q3.619,0,7.238,0,3.7,0,7.407,0a1.358,1.358,0,0,0,1.517-1.513Q3181.921,2321.308,3181.917,2316.25Zm-6.147-1.512c-.916-.011-1.832,0-2.747,0s-1.8-.012-2.7.007c-.286.006-.358-.083-.34-.352a11.472,11.472,0,0,1,.075-1.642,3.043,3.043,0,0,1,6.02.518c.013.394-.012.79.011,1.183C3176.1,2314.707,3175.992,2314.741,3175.77,2314.738Z"
            transform="translate(-89.601 -112.253)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default SecurityManagementIcon;
