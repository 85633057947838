import React from "react";

function FastDeliveryIconThree() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="65.241"
      viewBox="0 0 95 65.241"
    >
      <g
        id="Group_3829"
        data-name="Group 3829"
        transform="translate(29.736 1610.01)"
      >
        <path
          id="Path_4037"
          data-name="Path 4037"
          d="M84.456-1536.914a1.429,1.429,0,0,1,1.6-1.328c3.122-.009,6.244-.027,9.366.008a2.368,2.368,0,0,1,2.6,2.413c.029.676.388.557.769.557q4.729,0,9.459,0c2.926,0,4.25,1.317,4.259,4.23.013,3.987.039,7.974.018,11.961a1.423,1.423,0,0,0,.713,1.354c1,.664,1.944,1.423,2.953,2.079a5.015,5.015,0,0,1,2.268,2.764v1.67c-.857,2.371-1.341,4.846-2.039,7.262-.3,1.036-.543,2.088-.85,3.122a5.492,5.492,0,0,1-4.984,4.034,1.016,1.016,0,0,0-.946.664,9.606,9.606,0,0,1-7.307,5.234,5.754,5.754,0,0,1-6.837-5.241c-.056-.529-.208-.638-.7-.638q-15.16.021-30.321,0c-.54,0-.72.236-.946.651a9.612,9.612,0,0,1-7.309,5.232,5.714,5.714,0,0,1-6.8-5.247c-.053-.555-.263-.638-.74-.63-1.607.024-3.214.02-4.822.006-2.159-.019-3.256-1.415-2.711-3.483.864-3.277,1.774-6.543,2.631-9.823a1.539,1.539,0,0,1,1.763-1.371,1.343,1.343,0,0,1,1.265,1.7,21.382,21.382,0,0,1-.546,2.147c-.6,2.263-1.211,4.521-1.8,6.785-.254.972-.22,1.02.738,1.027,1.2.009,2.4-.017,3.6.01a1.322,1.322,0,0,0,1.347-.75,8.661,8.661,0,0,1,1.727-2.27,9.269,9.269,0,0,1,6.748-2.923,5.87,5.87,0,0,1,5.4,3.078,5.288,5.288,0,0,1,.574,1.656c.243,1.153.258,1.169,1.47,1.17q7.139.007,14.278,0c1.329,0,2.658-.019,3.986.006a1.162,1.162,0,0,0,1.314-1.011c.14-.6.291-1.2.465-1.794,2.072-7.531,4.052-15.088,6.08-22.631.794-2.952,1.6-5.9,2.368-8.86.277-1.074.229-1.153-.91-1.156-2.378-.007-4.756,0-7.134,0a3.919,3.919,0,0,1-1.195-.107A1.19,1.19,0,0,1,84.456-1536.914Z"
          transform="translate(-53.204 -53.962)"
          fill="#00173a"
        />
        <path
          id="Path_4038"
          data-name="Path 4038"
          d="M105.62-1532.846c.181,1.108.536,1.439,1.642,1.443,2.782.011,5.564.017,8.346-.007.606-.005.749.168.592.751q-4.278,15.906-8.536,31.816a1.217,1.217,0,0,1-.13.244,1.705,1.705,0,0,1-1.227.3q-10.078.009-20.156,0a1.973,1.973,0,0,1-1.234-.3c-1.994-3.149-5.6-3.352-8.523-2.145a11.935,11.935,0,0,0-3.211,2.14,4.443,4.443,0,0,1-2.9.129,1.585,1.585,0,0,1-1.1-1.9,37.82,37.82,0,0,1,1.166-4.387,1.744,1.744,0,0,1,2.379-1.144,27.3,27.3,0,0,0,7.394,1.129,23.559,23.559,0,0,0,15.253-5.36,26.542,26.542,0,0,0,9.816-18.1,16.616,16.616,0,0,0-.015-3.517c-.03-.336-.143-.675.121-.972Z"
          transform="translate(-74.369 -58.029)"
          fill="#91c3fc"
        />
        <path
          id="Path_4039"
          data-name="Path 4039"
          d="M34.023-1569.371a22.67,22.67,0,0,1-8.4-10.673,22.218,22.218,0,0,1,17.128-29.673,22.2,22.2,0,0,1,25.617,18.5,2.855,2.855,0,0,0,.139.434,6.2,6.2,0,0,1,.4,3.068,23.261,23.261,0,0,1-1.31,7.355,22.385,22.385,0,0,1-10.486,12.26,21.686,21.686,0,0,1-9.275,2.659,22.337,22.337,0,0,1-12.861-3.075A2.308,2.308,0,0,1,34.023-1569.371Z"
          transform="translate(-40.562 0)"
          fill="#00173a"
        />
        <path
          id="Path_4040"
          data-name="Path 4040"
          d="M55.294-1511.058a22.4,22.4,0,0,0,11.1,3.7,21.545,21.545,0,0,0,17.075-6.572,21.522,21.522,0,0,0,6.373-16.968c-.028-.523-.043-1.047-.064-1.572l2.988.026c.855,6.981-1.376,13.1-5.536,18.525a24.182,24.182,0,0,1-15.894,9.436,24.362,24.362,0,0,1-11.126-.885,1.554,1.554,0,0,0-2.25,1.174c-.365,1.334-.7,2.676-1.029,4.02a1.365,1.365,0,0,0,1.4,1.852c.772.02,1.545,0,2.317,0a11.425,11.425,0,0,0-1.872,2.739.6.6,0,0,1-.64.4c-1.851-.014-3.7-.007-5.649-.007.663-2.494,1.3-4.9,1.945-7.311.23-.865.472-1.726.689-2.594.268-1.068.078-1.451-.947-1.993Z"
          transform="translate(-61.832 -58.313)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4041"
          data-name="Path 4041"
          d="M-20.928-1448.135c2.409,0,4.817-.012,7.226.005,1.155.008,1.851.8,1.558,1.747-.264.858-.956,1.037-1.759,1.035-2.779-.009-5.558,0-8.338,0-1.915,0-3.829.01-5.744,0-1.136-.008-1.785-.565-1.751-1.454.033-.852.642-1.32,1.766-1.324C-25.622-1448.14-23.275-1448.135-20.928-1448.135Z"
          transform="translate(0 -121.731)"
          fill="#00173a"
        />
        <path
          id="Path_4042"
          data-name="Path 4042"
          d="M-5.935-1424.129c1.419,0,2.837-.01,4.255,0,1.039.01,1.612.508,1.615,1.363s-.578,1.4-1.6,1.409q-4.255.03-8.511,0a1.414,1.414,0,0,1-1.582-1.435c.021-.822.632-1.329,1.655-1.338C-8.71-1424.138-7.323-1424.129-5.935-1424.129Z"
          transform="translate(-13.524 -139.785)"
          fill="#00173a"
        />
        <path
          id="Path_4043"
          data-name="Path 4043"
          d="M9.117-1397.379c-.495,0-.992.032-1.483-.007a1.416,1.416,0,0,1-1.4-1.432,1.393,1.393,0,0,1,1.479-1.331c.958-.021,1.918-.025,2.875,0a1.326,1.326,0,0,1,1.387,1.311,1.352,1.352,0,0,1-1.281,1.434,14.6,14.6,0,0,1-1.575.011Z"
          transform="translate(-27.048 -157.808)"
          fill="#00173a"
        />
        <path
          id="Path_4044"
          data-name="Path 4044"
          d="M268.295-1426.138c.007,1.337.4,1.785,1.755,1.783.692,0,.733.2.554.792-.521,1.734-.978,3.487-1.46,5.233a4.372,4.372,0,0,1-3.264,0c-2.179-3.374-5.906-3.284-8.587-2.148a12.707,12.707,0,0,0-3.2,2.142,14.13,14.13,0,0,1-4.948.168.735.735,0,0,1-.683-.857,13.758,13.758,0,0,1,1.515-5.265q6.613-.022,13.226-.047c1.405-.006,1.845-.443,1.851-1.8A11.592,11.592,0,0,0,268.295-1426.138Z"
          transform="translate(-209.203 -138.277)"
          fill="#ff7b16"
        />
        <path
          id="Path_4045"
          data-name="Path 4045"
          d="M274.911-1453.285a3.565,3.565,0,0,1-3.244,0,1.668,1.668,0,0,0-.985-.977c-1.632-.131-3.268-.042-4.9-.053-2.562-.017-5.125-.028-7.687.01-.717.011-.974-.211-.787-.9.584-2.144,1.127-4.3,1.752-6.432.084-.285.125-.731.646-.567a3.571,3.571,0,0,0,3.919,3.258q4.494.027,8.989,0a2.124,2.124,0,0,1,1.363.441c1.162.867,2.356,1.691,3.535,2.536a4.487,4.487,0,0,1,.575.464.9.9,0,0,1,.15.958c-.108.355-.434.276-.7.28-.34.005-.68.017-1.019,0A1.475,1.475,0,0,0,274.911-1453.285Z"
          transform="translate(-215.82 -111.13)"
          fill="#ff8f3a"
        />
        <path
          id="Path_4046"
          data-name="Path 4046"
          d="M251.118-1501.9c-.644.574-.575,1.435-.811,2.158-.514,1.577-.878,3.2-1.327,4.8-.168.6-.114.853.634.846,3.834-.039,7.668-.021,11.5-.013.328,0,.685-.112.978.148-5.156,0-10.311-.018-15.467.007-.757,0-.928-.125-.712-.916,1.764-6.446,3.483-12.905,5.2-19.363.116-.434.163-.8.783-.795,3.612.025,7.224.01,10.836.013a1.43,1.43,0,0,1,.35.093c-.33.384-.787.3-1.2.3-2.06.014-4.121.031-6.181-.006a1.24,1.24,0,0,0-1.425,1.081,32.511,32.511,0,0,1-1.291,4.407A31.3,31.3,0,0,0,251.118-1501.9Z"
          transform="translate(-207.233 -71.432)"
          fill="#ff7b16"
        />
        <path
          id="Path_4047"
          data-name="Path 4047"
          d="M132.715-1394.753l22.617,0c-.211.866-.441,1.727-.627,2.6-.1.468-.375.54-.794.539q-9.9-.014-19.807,0c-.515,0-.689-.145-.705-.669A6.648,6.648,0,0,0,132.715-1394.753Z"
          transform="translate(-122.167 -161.879)"
          fill="#da4655"
        />
        <path
          id="Path_4048"
          data-name="Path 4048"
          d="M86.1-1388.949c.027-3.347,3.9-6.565,7.153-5.947a2.89,2.89,0,0,1,2.328,2.557c.386,3.3-3.832,7.12-7.082,6.406A2.722,2.722,0,0,1,86.1-1388.949Z"
          transform="translate(-87.111 -161.713)"
          fill="#e6e6e6"
        />
        <path
          id="Path_4049"
          data-name="Path 4049"
          d="M281.275-1391.781c-.021,3.109-3.542,6.313-6.64,6.04a3,3,0,0,1-2.827-3.592,6.826,6.826,0,0,1,6.521-5.5A2.775,2.775,0,0,1,281.275-1391.781Z"
          transform="translate(-226.737 -161.821)"
          fill="#e6e6e6"
        />
        <path
          id="Path_4050"
          data-name="Path 4050"
          d="M288.3-1486.493c0,.985.359,2.24-.112,2.88-.453.614-1.788.172-2.734.187-1.916.029-3.832.016-5.749.006-1.17-.006-1.416-.343-1.117-1.494.341-1.313.682-2.626,1.044-3.933a2.728,2.728,0,0,1,2.827-2.193,46.977,46.977,0,0,1,5.8.1c-.443.4-.989.251-1.5.268-.829.027-1.659,0-2.489.013a.91.91,0,0,0-.985.774c-.154.658-.4,1.293-.524,1.958-.173.905-.007,1.131.9,1.156,1.167.033,2.335,0,3.5.014A1.531,1.531,0,0,1,288.3-1486.493Z"
          transform="translate(-231.78 -89.435)"
          fill="#c2dcfe"
        />
        <path
          id="Path_4051"
          data-name="Path 4051"
          d="M238.227-1418.861c-.4,1.483-.8,2.967-1.2,4.449-.327,1.194-.1,1.486,1.149,1.489l4.17.015a11.957,11.957,0,0,0-1.809,2.664.658.658,0,0,1-.7.458c-2.159-.014-4.319-.023-6.478,0-.585.007-.581-.252-.465-.677.711-2.613,1.425-5.226,2.11-7.846.111-.425.258-.634.731-.608C236.563-1418.868,237.4-1418.875,238.227-1418.861Z"
          transform="translate(-197.457 -143.708)"
          fill="#e66909"
        />
        <path
          id="Path_4052"
          data-name="Path 4052"
          d="M274.7-1508.813a15.442,15.442,0,0,1,.88-3.881c.462-1.877.527-1.856,2.466-1.863,2.252-.007,4.5-.025,6.755-.038.486,0,.608.331.664.725.321,2.228.213,2.355-2.015,2.355-1.047,0-2.1.053-3.141-.011A5.933,5.933,0,0,0,274.7-1508.813Z"
          transform="translate(-228.943 -71.755)"
          fill="#ff8f3a"
        />
        <path
          id="Path_4053"
          data-name="Path 4053"
          d="M300.1-1486.069c-1.669,0-3.34-.027-5.008.014-.779.019-1.031-.357-.893-1.019.212-1.022.5-2.03.761-3.041.117-.448.5-.389.834-.391q2.133-.011,4.266-.015A41.1,41.1,0,0,1,300.1-1486.069Z"
          transform="translate(-243.58 -89.859)"
          fill="#dae9fe"
        />
        <path
          id="Path_4054"
          data-name="Path 4054"
          d="M318.742-1394.659l3.264,0a3.305,3.305,0,0,1-1.763,2.851c-.5.241-.884.317-.864-.458A6.46,6.46,0,0,0,318.742-1394.659Z"
          transform="translate(-262.065 -161.949)"
          fill="#fc8a59"
        />
        <path
          id="Path_4055"
          data-name="Path 4055"
          d="M74.3-1579.489a19.509,19.509,0,0,1-19.49,19.442,19.506,19.506,0,0,1-19.392-19.444,19.5,19.5,0,0,1,19.393-19.436A19.5,19.5,0,0,1,74.3-1579.489Z"
          transform="translate(-48.998 -8.335)"
          fill="#c8e2ff"
        />
        <path
          id="Path_4056"
          data-name="Path 4056"
          d="M102.422-1380.777a1.538,1.538,0,0,1-1.389,1.466,1.409,1.409,0,0,1-1.379-1.34,1.356,1.356,0,0,1,1.259-1.426A1.415,1.415,0,0,1,102.422-1380.777Z"
          transform="translate(-97.303 -171.41)"
          fill="#012458"
        />
        <path
          id="Path_4057"
          data-name="Path 4057"
          d="M286.8-1379.312a1.543,1.543,0,0,1-1.4-1.467,1.416,1.416,0,0,1,1.517-1.3,1.288,1.288,0,0,1,1.251,1.423A1.4,1.4,0,0,1,286.8-1379.312Z"
          transform="translate(-236.993 -171.409)"
          fill="#012458"
        />
        <path
          id="Path_4058"
          data-name="Path 4058"
          d="M63.662-1553.885a3.584,3.584,0,0,1-2.512-.12A16.164,16.164,0,0,1,48.7-1564.826a7.9,7.9,0,0,1-.662-4.664,4.371,4.371,0,0,0,2.658.49c.609-.049,1.092-.237,1.18-.925a1.155,1.155,0,0,0-1.041-1.3,3.061,3.061,0,0,0-1.985.193c-.24.117-.453.339-.764.237-.52-.81-.095-1.642.051-2.409a16.4,16.4,0,0,1,13.578-13.017c.6-.079,1.185-.367,1.768.032-.136.767-.685,1.438-.5,2.285a2.824,2.824,0,0,0,.262.97,1.1,1.1,0,0,0,1.191.518.929.929,0,0,0,.8-.886,4.466,4.466,0,0,0-.455-2.934,5.3,5.3,0,0,1,3.243.256,16.264,16.264,0,0,1,11.008,8.993,18.541,18.541,0,0,1,1.5,5.494.921.921,0,0,1-.149.878,3.929,3.929,0,0,0-2.431-.673,2.2,2.2,0,0,0-1.033.359,1.089,1.089,0,0,0-.351,1.169.9.9,0,0,0,.861.716,4.538,4.538,0,0,0,2.942-.454c.44,1.15-.053,2.245-.3,3.31A16.155,16.155,0,0,1,66.463-1553.9c-.562.076-1.122.391-1.684,0a4.55,4.55,0,0,0,.484-2.751,1.023,1.023,0,0,0-.941-1.066,1.062,1.062,0,0,0-1.227.866A3.606,3.606,0,0,0,63.662-1553.885Z"
          transform="translate(-58.335 -17.752)"
          fill="#ecf5ff"
        />
        <path
          id="Path_4059"
          data-name="Path 4059"
          d="M110.032-1585.974a3.379,3.379,0,0,1,.56,3.54,1.392,1.392,0,0,1-2.378-.132,3.662,3.662,0,0,1,.518-3.362A1.966,1.966,0,0,1,110.032-1585.974Z"
          transform="translate(-103.586 -18.006)"
          fill="#00173a"
        />
        <path
          id="Path_4060"
          data-name="Path 4060"
          d="M48.416-1525.611a3.7,3.7,0,0,1,3.365-.512,1.39,1.39,0,0,1,.123,2.376,3.359,3.359,0,0,1-3.537-.564A1.922,1.922,0,0,1,48.416-1525.611Z"
          transform="translate(-58.665 -62.93)"
          fill="#00173a"
        />
        <path
          id="Path_4061"
          data-name="Path 4061"
          d="M166.435-1524.347a3.391,3.391,0,0,1-3.547.574,1.4,1.4,0,0,1,.034-2.336,3.3,3.3,0,0,1,3.525.645Z"
          transform="translate(-144.396 -62.897)"
          fill="#00173a"
        />
        <path
          id="Path_4062"
          data-name="Path 4062"
          d="M108.88-1467.909a3.268,3.268,0,0,1-.651-3.521,1.4,1.4,0,0,1,2.384.041,3.45,3.45,0,0,1-.616,3.468Z"
          transform="translate(-103.553 -103.727)"
          fill="#00173a"
        />
        <path
          id="Path_4063"
          data-name="Path 4063"
          d="M91.467-1552.014a1.662,1.662,0,0,1-.718-.077c-1.028-.592-1.615.02-2.233.707-.557.62-1.165,1.195-1.765,1.775a1.439,1.439,0,0,1-2.129.18,1.372,1.372,0,0,1,.141-2.117c.776-.8,1.556-1.592,2.366-2.354a.908.908,0,0,0,.282-1.116,4.084,4.084,0,0,1,1.057-4.428,4.072,4.072,0,0,1,4.433-1.031.918.918,0,0,0,1.109-.309q3.359-3.4,6.749-6.759c.762-.758,1.48-.829,2.116-.247a1.428,1.428,0,0,1-.136,2.193q-3.332,3.357-6.694,6.683a1.109,1.109,0,0,0-.358,1.331A4.316,4.316,0,0,1,91.467-1552.014Z"
          transform="translate(-85.673 -31.461)"
          fill="#00173a"
        />
        <path
          id="Path_4064"
          data-name="Path 4064"
          d="M110.5-1525.383a1.449,1.449,0,0,1-1.57,1.528,1.574,1.574,0,0,1-1.566-1.594,1.758,1.758,0,0,1,1.576-1.539A1.624,1.624,0,0,1,110.5-1525.383Z"
          transform="translate(-103.098 -62.435)"
          fill="#012458"
        />
      </g>
    </svg>
  );
}
export default FastDeliveryIconThree;
