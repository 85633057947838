import React from "react";

function DepositionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <g
        id="Group_3835"
        data-name="Group 3835"
        transform="translate(-199 -4442)"
      >
        <path
          id="Path_4119"
          data-name="Path 4119"
          d="M155.206-2597.276a25.06,25.06,0,0,0-25.009,25.017,25.048,25.048,0,0,0,24.99,24.983,25.061,25.061,0,0,0,25.009-25.017A25.048,25.048,0,0,0,155.206-2597.276Zm0,47.706a20.155,20.155,0,0,1-3.743-.3,21.942,21.942,0,0,1-10.652-4.854,22.469,22.469,0,0,1-7.783-12.593,23.347,23.347,0,0,1-.541-4.2c0-.22,0-.4,0-.573,0-.763.415-1.244,1.066-1.247a1.071,1.071,0,0,1,1.077,1.183,23.078,23.078,0,0,0,.393,3.735,19.958,19.958,0,0,0,3.258,7.792,20.468,20.468,0,0,0,8.611,7.142,20.9,20.9,0,0,0,7.684,1.764c.23.01.46,0,.688.029a1.052,1.052,0,0,1,1,1.091A1.044,1.044,0,0,1,155.2-2549.57Zm3.551-44.1c-.055.63-.588,1.006-1.344.948l-.264-.021v-.006c-.635-.031-1.27-.056-1.9-.1a1.068,1.068,0,0,1-1.11-1.1,1.074,1.074,0,0,1,1.16-1.046,20.929,20.929,0,0,1,2.274.115C158.34-2594.806,158.812-2594.336,158.754-2593.673Zm18.116,22.462a1.043,1.043,0,0,1-1.1-1.052,26.249,26.249,0,0,0-.507-4.468,20.241,20.241,0,0,0-7.453-11.787,21.448,21.448,0,0,0-4.937-2.839c-.624-.263-.894-.652-.851-1.138a1.083,1.083,0,0,1,1.561-.9,21.822,21.822,0,0,1,4.556,2.444,22.671,22.671,0,0,1,8.678,11.694,22.537,22.537,0,0,1,1.088,6.185c0,.247.007.5,0,.742A1.069,1.069,0,0,1,176.87-2571.211Z"
          transform="translate(68.803 7039.276)"
          fill="#ff7b16"
        />
        <g
          id="Group_3834"
          data-name="Group 3834"
          transform="translate(1210.17 4275.568)"
        >
          <path
            id="Path_3456"
            data-name="Path 3456"
            d="M-920.105,181.432a1.251,1.251,0,0,1,.457.332c.222.23.455.451.677.681a.593.593,0,0,1,0,.889q-2.537,2.543-5.08,5.079a.591.591,0,0,1-.888-.008q-.407-.4-.806-.807a.594.594,0,0,1,0-.9q2.531-2.535,5.064-5.067a.983.983,0,0,1,.335-.2Z"
            transform="translate(-66.455)"
            fill="#fff"
          />
          <path
            id="Path_3457"
            data-name="Path 3457"
            d="M-805.217,497.2l0,2.732a.567.567,0,0,1-.527.436c-.06,0-.12,0-.18,0h-9.365c-.522,0-.74-.217-.74-.739q0-1.1,0-2.2a.6.6,0,0,1,.665-.67q4.763,0,9.525,0A.562.562,0,0,1-805.217,497.2Z"
            transform="translate(-170.49 -298.438)"
            fill="#fff"
          />
          <path
            id="Path_3458"
            data-name="Path 3458"
            d="M-996.17,360.389a1.748,1.748,0,0,1,.46-1.116c.049-.055.1-.108.152-.159q3.084-3.084,6.164-6.172c.134-.135.2-.115.317.008q1.131,1.147,2.278,2.278c.1.1.091.15,0,.244q-3.131,3.122-6.256,6.25a1.789,1.789,0,0,1-2,.417A1.837,1.837,0,0,1-996.17,360.389Z"
            transform="translate(0 -162.235)"
            fill="#fff"
          />
          <path
            id="Path_3459"
            data-name="Path 3459"
            d="M-857.594,245.535c-.377.374-.729.719-1.078,1.068q-1.543,1.541-3.081,3.086c-.105.107-.152.1-.256,0q-2-2.015-4.018-4.017c-.107-.107-.1-.154,0-.256q2.007-2,4-4c.109-.11.162-.109.27,0q1.988,2,3.987,3.991A1.747,1.747,0,0,0-857.594,245.535Z"
            transform="translate(-123.099 -56.689)"
            fill="#fff"
          />
          <path
            id="Path_3460"
            data-name="Path 3460"
            d="M-782.538,330.328a.653.653,0,0,1,.237-.487q1.89-1.887,3.778-3.776.6-.6,1.2-1.2a.616.616,0,0,1,.991,0c.25.249.5.5.749.75a.6.6,0,0,1,0,.918q-2.522,2.528-5.05,5.05a.6.6,0,0,1-.917,0c-.267-.261-.528-.528-.793-.791A.6.6,0,0,1-782.538,330.328Z"
            transform="translate(-202.189 -135.511)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default DepositionIcon;
