import React from "react";

function CourtIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <g
        id="Group_3836"
        data-name="Group 3836"
        transform="translate(-268 -4442)"
      >
        <path
          id="Path_4119"
          data-name="Path 4119"
          d="M155.206-2597.276a25.06,25.06,0,0,0-25.009,25.017,25.048,25.048,0,0,0,24.99,24.983,25.061,25.061,0,0,0,25.009-25.017A25.048,25.048,0,0,0,155.206-2597.276Zm0,47.706a20.155,20.155,0,0,1-3.743-.3,21.942,21.942,0,0,1-10.652-4.854,22.469,22.469,0,0,1-7.783-12.593,23.347,23.347,0,0,1-.541-4.2c0-.22,0-.4,0-.573,0-.763.415-1.244,1.066-1.247a1.071,1.071,0,0,1,1.077,1.183,23.078,23.078,0,0,0,.393,3.735,19.958,19.958,0,0,0,3.258,7.792,20.468,20.468,0,0,0,8.611,7.142,20.9,20.9,0,0,0,7.684,1.764c.23.01.46,0,.688.029a1.052,1.052,0,0,1,1,1.091A1.044,1.044,0,0,1,155.2-2549.57Zm3.551-44.1c-.055.63-.588,1.006-1.344.948l-.264-.021v-.006c-.635-.031-1.27-.056-1.9-.1a1.068,1.068,0,0,1-1.11-1.1,1.074,1.074,0,0,1,1.16-1.046,20.929,20.929,0,0,1,2.274.115C158.34-2594.806,158.812-2594.336,158.754-2593.673Zm18.116,22.462a1.043,1.043,0,0,1-1.1-1.052,26.249,26.249,0,0,0-.507-4.468,20.241,20.241,0,0,0-7.453-11.787,21.448,21.448,0,0,0-4.937-2.839c-.624-.263-.894-.652-.851-1.138a1.083,1.083,0,0,1,1.561-.9,21.822,21.822,0,0,1,4.556,2.444,22.671,22.671,0,0,1,8.678,11.694,22.537,22.537,0,0,1,1.088,6.185c0,.247.007.5,0,.742A1.069,1.069,0,0,1,176.87-2571.211Z"
          transform="translate(137.803 7039.276)"
          fill="#ff7b16"
        />
        <path
          id="Icon_material-business-center"
          data-name="Icon material-business-center"
          d="M12.112,19.307V18.168H4.15l-.011,4.556A2.27,2.27,0,0,0,6.417,25H22.362a2.27,2.27,0,0,0,2.278-2.278V18.168H16.668v1.139ZM23.5,9.056H18.934V6.778L16.656,4.5H12.1L9.822,6.778V9.056H5.278A2.285,2.285,0,0,0,3,11.334v3.417a2.27,2.27,0,0,0,2.278,2.278h6.834V14.751h4.556v2.278H23.5a2.285,2.285,0,0,0,2.278-2.278V11.334A2.285,2.285,0,0,0,23.5,9.056Zm-6.834,0H12.112V6.778h4.556Z"
          transform="translate(279 4452.5)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default CourtIcon;
