import React from "react";

const LogoFooterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 222.034 45.406"
  >
    <defs>
      {/* <style>
.a{fill:#042c7f;isolation:isolate;}
.b{fill:#fff;}</style> */}
    </defs>
    <path fill="#042c7f" className="a" d="M0,0H202.427V34.761H0Z" />
    <g transform="translate(13.524 8.853)">
      <path
        d="M51.785,25.262H50.536v-7.6H41.954V50.626h4.019v1.249H30.766V50.626h4.019V17.658H26.149V24.99H24.9V16.3H51.84l-.054,8.962Z"
        transform="translate(-24.9 -16.3)"
      />
      <path
        d="M98.653,46.807a12.763,12.763,0,0,1-3.8,9.233,12.6,12.6,0,0,1-18.249,0,12.8,12.8,0,0,1-3.8-9.288,12.975,12.975,0,0,1,3.8-9.288,12.541,12.541,0,0,1,18.249-.054A13.457,13.457,0,0,1,98.653,46.807ZM90.4,53.976V39.583a4.517,4.517,0,0,0-1.412-3.313A4.728,4.728,0,0,0,81,39.583V53.976a4.467,4.467,0,0,0,1.358,3.313,4.593,4.593,0,0,0,6.626,0A4.607,4.607,0,0,0,90.4,53.976Z"
        transform="translate(-46.784 -24.158)"
      />
      <path
        d="M164.927,52.575a7.938,7.938,0,0,1-1.792,5.377,6.046,6.046,0,0,1-4.78,2.064,6.7,6.7,0,0,1-4.671-1.847,6.123,6.123,0,0,1-2.01-4.725V38.78a3.7,3.7,0,0,0-.652-2.064,1.934,1.934,0,0,0-1.684-.978c-1.086,0-1.9.652-2.444,2.01a10.324,10.324,0,0,0-.869,3.748V60.016h-7.278V35.3a5.513,5.513,0,0,0-3.911,1.629,5.046,5.046,0,0,0-1.738,3.693V59.962h-7.278V35.3H124.3V34h.869a19.415,19.415,0,0,1,2.01.109,9.857,9.857,0,0,1,1.847.434,4.4,4.4,0,0,1,1.847,1.141,8.075,8.075,0,0,1,1.467,2.118,5.853,5.853,0,0,1,2.607-2.77,7.827,7.827,0,0,1,3.856-.978,7.979,7.979,0,0,1,3.856.978,6.245,6.245,0,0,1,2.661,2.77,5.765,5.765,0,0,1,5.812-3.748,7.5,7.5,0,0,1,5.431,2.173,7.372,7.372,0,0,1,2.281,5.54V58.713a5.226,5.226,0,0,0,3.367-1.738,5.313,5.313,0,0,0,1.358-3.53V52.63h1.358v-.054Z"
        transform="translate(-70.312 -24.386)"
      />
      <path
        d="M221.8,46.492H205.238v7.5a4.825,4.825,0,0,0,4.725,4.725,12.029,12.029,0,0,0,5.866-1.575,11.34,11.34,0,0,0,4.291-4.182l1.195.652a12.74,12.74,0,0,1-4.78,4.671,13.313,13.313,0,0,1-6.518,1.738,12.278,12.278,0,0,1-9.07-3.856,13.048,13.048,0,0,1,0-18.3A12.159,12.159,0,0,1,209.964,34c3.856,0,6.735,1.195,8.69,3.53A14.531,14.531,0,0,1,221.8,46.492Zm-7.061-1.249V39.975a4.517,4.517,0,0,0-1.412-3.313,4.467,4.467,0,0,0-3.313-1.358,4.729,4.729,0,0,0-3.367,1.412,4.487,4.487,0,0,0-1.412,3.259v5.214h9.5v.054Z"
        transform="translate(-103.618 -24.386)"
      />
      <path
        d="M277.053,45.3a6.123,6.123,0,0,1-2.009,4.725,6.761,6.761,0,0,1-4.671,1.9,6.284,6.284,0,0,1-4.454-1.792,12.244,12.244,0,0,1-6.355,1.792A12.889,12.889,0,0,1,246.8,38.949a12.5,12.5,0,0,1,3.8-9.233,12.129,12.129,0,0,1,8.908-3.8,13,13,0,0,1,4.345.815V24.284q0-4.074,1.955-6.029a5.375,5.375,0,0,1,2.444-1.575,12.686,12.686,0,0,1,3.1-.38h1.358v1.3h-1.629V50.518a5.227,5.227,0,0,0,3.313-1.738,5.313,5.313,0,0,0,1.358-3.53v-.815h1.3V45.3Zm-12,3.8a6.5,6.5,0,0,1-1.249-3.8V28.086a11.763,11.763,0,0,0-4.4-.869,4.517,4.517,0,0,0-3.313,1.412,4.432,4.432,0,0,0-1.358,3.259V45.9a4.825,4.825,0,0,0,4.725,4.725A10.7,10.7,0,0,0,265.049,49.105Z"
        transform="translate(-126.278 -16.3)"
      />
      <path
        d="M325.2,46.492H308.638v7.5a4.825,4.825,0,0,0,4.725,4.725,12.029,12.029,0,0,0,5.866-1.575,11.339,11.339,0,0,0,4.291-4.182l1.195.652a12.74,12.74,0,0,1-4.78,4.671,13.313,13.313,0,0,1-6.518,1.738,12.278,12.278,0,0,1-9.07-3.856,13.048,13.048,0,0,1,0-18.3A12.159,12.159,0,0,1,313.364,34c3.856,0,6.735,1.195,8.69,3.53A15.225,15.225,0,0,1,325.2,46.492Zm-7.061-1.249V39.975a4.517,4.517,0,0,0-1.412-3.313,4.467,4.467,0,0,0-3.313-1.358,4.73,4.73,0,0,0-3.367,1.412,4.487,4.487,0,0,0-1.412,3.259v5.214h9.5v.054Z"
        transform="translate(-150.857 -24.386)"
      />
      <path
        d="M372.8,52.73c0,2.118-1.086,3.856-3.2,5.323a14.6,14.6,0,0,1-8.2,2.173,12.707,12.707,0,0,1-8.038-2.553,7.61,7.61,0,0,1-3.259-6.246h1.3a6.208,6.208,0,0,0,3.1,5.431,12.485,12.485,0,0,0,7.007,2.064c.326,0,.76-.054,1.3-.109a19.546,19.546,0,0,0,2.281-.38,5.053,5.053,0,0,0,2.444-1.086,2.494,2.494,0,0,0,.978-2.01q0-1.955-5.214-4.074c-.163-.054-.815-.326-1.955-.76s-2.064-.815-2.77-1.086A22.414,22.414,0,0,1,355.8,48a15.513,15.513,0,0,1-2.716-1.847,10.712,10.712,0,0,1-1.792-2.227,5.391,5.391,0,0,1-.76-2.824A5.7,5.7,0,0,1,353.848,36a16.429,16.429,0,0,1,8.256-1.9,12.036,12.036,0,0,1,5.486,1.358c1.847.869,2.77,2.064,2.77,3.53a2.869,2.869,0,0,1-.869,2.118,2.826,2.826,0,0,1-2.173.869,2.787,2.787,0,0,1-2.173-.923,3.278,3.278,0,0,1-.815-2.173,2.669,2.669,0,0,1,1.847-2.661,11.833,11.833,0,0,0-4.454-.869c-.434,0-.869.054-1.412.109a15.345,15.345,0,0,0-2.173.38,4.916,4.916,0,0,0-2.281,1.032,2.342,2.342,0,0,0-.869,1.9,1.891,1.891,0,0,0,.652,1.358,4.136,4.136,0,0,0,1.412.978c1.032.435,2.661,1.195,4.888,2.173,2.281,1.032,4.019,1.792,5.214,2.335a11.217,11.217,0,0,1,4.073,2.933A6.543,6.543,0,0,1,372.8,52.73Z"
        transform="translate(-173.472 -24.432)"
      />
      <path
        d="M401.92,70.132a3.362,3.362,0,0,1,1.032,2.444,3.245,3.245,0,0,1-1.032,2.444A3.476,3.476,0,0,1,396,72.576a3.245,3.245,0,0,1,1.032-2.444,3.363,3.363,0,0,1,2.444-1.032A3.814,3.814,0,0,1,401.92,70.132Z"
        transform="translate(-194.442 -40.422)"
      />
    </g>
    <g transform="translate(12.927 9.614)">
      <path
        className="b"
        fill="#fff"
        d="M50.685,26.662H49.436v-7.6H40.854V52.026h4.019v1.249H29.666V52.026h4.019V19.058H25.049V26.39H23.8V17.7H50.74l-.054,8.962Z"
        transform="translate(-23.8 -17.7)"
      />
      <path
        className="b"
        fill="#fff"
        d="M97.553,48.253a12.763,12.763,0,0,1-3.8,9.233,12.6,12.6,0,0,1-18.249,0A12.8,12.8,0,0,1,71.7,48.2a12.975,12.975,0,0,1,3.8-9.288,12.6,12.6,0,0,1,18.249,0A13.236,13.236,0,0,1,97.553,48.253ZM89.3,55.422V41.029a4.517,4.517,0,0,0-1.412-3.313A4.728,4.728,0,0,0,79.9,41.029V55.422a4.467,4.467,0,0,0,1.358,3.313A4.726,4.726,0,0,0,89.3,55.422Z"
        transform="translate(-45.684 -25.604)"
      />
      <path
        className="b"
        fill="#fff"
        d="M163.918,54.021a7.939,7.939,0,0,1-1.792,5.377,6.046,6.046,0,0,1-4.78,2.064,6.7,6.7,0,0,1-4.671-1.847,6.123,6.123,0,0,1-2.01-4.725V40.225a3.7,3.7,0,0,0-.652-2.064,1.934,1.934,0,0,0-1.684-.978c-1.086,0-1.9.652-2.444,2.01a10.325,10.325,0,0,0-.869,3.748V61.462h-7.169V36.8a5.513,5.513,0,0,0-3.911,1.629,5.046,5.046,0,0,0-1.738,3.693V61.462h-7.278V36.8H123.4V35.5h.869a19.41,19.41,0,0,1,2.01.109,9.852,9.852,0,0,1,1.847.435,4.394,4.394,0,0,1,1.847,1.141,8.073,8.073,0,0,1,1.466,2.118,5.854,5.854,0,0,1,2.607-2.77,7.828,7.828,0,0,1,3.856-.978,7.979,7.979,0,0,1,3.856.978,6.245,6.245,0,0,1,2.661,2.77,5.765,5.765,0,0,1,5.812-3.748,7.5,7.5,0,0,1,5.431,2.173,7.372,7.372,0,0,1,2.281,5.54V60.213a5.226,5.226,0,0,0,3.367-1.738,5.313,5.313,0,0,0,1.358-3.53V54.13h1.249v-.109Z"
        transform="translate(-69.304 -25.832)"
      />
      <path
        className="b"
        fill="#fff"
        d="M220.75,47.892H204.238v7.5a4.825,4.825,0,0,0,4.725,4.725,12.029,12.029,0,0,0,5.866-1.575,11.34,11.34,0,0,0,4.291-4.182l1.195.652a12.74,12.74,0,0,1-4.78,4.671,13.313,13.313,0,0,1-6.518,1.738,12.278,12.278,0,0,1-9.07-3.856,13.048,13.048,0,0,1,0-18.3,12.16,12.16,0,0,1,9.016-3.856c3.856,0,6.735,1.195,8.69,3.53A14.837,14.837,0,0,1,220.75,47.892Zm-7.061-1.249V41.429a4.518,4.518,0,0,0-1.412-3.313,4.467,4.467,0,0,0-3.313-1.358A4.729,4.729,0,0,0,205.6,38.17a4.487,4.487,0,0,0-1.412,3.259v5.214Z"
        transform="translate(-102.563 -25.786)"
      />
      <path
        className="b"
        fill="#fff"
        d="M275.953,46.7a6.124,6.124,0,0,1-2.01,4.725,6.761,6.761,0,0,1-4.671,1.9,6.283,6.283,0,0,1-4.454-1.792,12.245,12.245,0,0,1-6.355,1.792A12.889,12.889,0,0,1,245.7,40.349a12.5,12.5,0,0,1,3.8-9.233,12.129,12.129,0,0,1,8.908-3.8,13,13,0,0,1,4.345.815V25.684q0-4.074,1.955-6.029a5.376,5.376,0,0,1,2.444-1.575,12.685,12.685,0,0,1,3.1-.38h1.358V19h-1.63V51.918a5.227,5.227,0,0,0,3.313-1.738,5.313,5.313,0,0,0,1.358-3.53v-.815h1.3V46.7Zm-12,3.8a6.5,6.5,0,0,1-1.249-3.8V29.486a11.764,11.764,0,0,0-4.4-.869,4.518,4.518,0,0,0-3.313,1.412,4.433,4.433,0,0,0-1.358,3.259V47.3a4.825,4.825,0,0,0,4.725,4.725A10.7,10.7,0,0,0,263.949,50.505Z"
        transform="translate(-125.178 -17.7)"
      />
      <path
        className="b"
        fill="#fff"
        d="M324.1,47.892H307.539v7.5a4.825,4.825,0,0,0,4.725,4.725,12.029,12.029,0,0,0,5.866-1.575,11.339,11.339,0,0,0,4.291-4.182l1.195.652a12.739,12.739,0,0,1-4.78,4.671,13.313,13.313,0,0,1-6.518,1.738,12.278,12.278,0,0,1-9.07-3.856,13.048,13.048,0,0,1,0-18.3,12.16,12.16,0,0,1,9.016-3.856c3.856,0,6.735,1.195,8.69,3.53A15.224,15.224,0,0,1,324.1,47.892Zm-7.061-1.249V41.429a4.517,4.517,0,0,0-1.412-3.313,4.467,4.467,0,0,0-3.313-1.358,4.73,4.73,0,0,0-3.367,1.412,4.487,4.487,0,0,0-1.412,3.259v5.214Z"
        transform="translate(-149.757 -25.786)"
      />
      <path
        className="b"
        fill="#fff"
        d="M371.7,54.13c0,2.118-1.086,3.856-3.2,5.323a14.6,14.6,0,0,1-8.2,2.173,12.708,12.708,0,0,1-8.039-2.553A7.61,7.61,0,0,1,349,52.826h1.3a6.208,6.208,0,0,0,3.1,5.431,12.485,12.485,0,0,0,7.006,2.064c.326,0,.761-.054,1.3-.109a19.55,19.55,0,0,0,2.281-.38,5.052,5.052,0,0,0,2.444-1.086,2.493,2.493,0,0,0,.978-2.01q0-1.955-5.214-4.074c-.163-.054-.815-.326-1.955-.76s-2.064-.815-2.77-1.086A22.4,22.4,0,0,1,354.7,49.4a15.527,15.527,0,0,1-2.716-1.847,10.725,10.725,0,0,1-1.792-2.227,5.393,5.393,0,0,1-.76-2.824,5.7,5.7,0,0,1,3.313-5.105A16.429,16.429,0,0,1,361,35.5a12.036,12.036,0,0,1,5.486,1.358c1.847.869,2.77,2.064,2.77,3.53a2.869,2.869,0,0,1-.869,2.118,2.825,2.825,0,0,1-2.172.869,2.787,2.787,0,0,1-2.173-.923,3.277,3.277,0,0,1-.815-2.173,2.669,2.669,0,0,1,1.847-2.661,11.833,11.833,0,0,0-4.454-.869c-.435,0-.869.054-1.412.109a15.336,15.336,0,0,0-2.173.38,4.916,4.916,0,0,0-2.281,1.032,2.342,2.342,0,0,0-.869,1.9,1.891,1.891,0,0,0,.652,1.358,4.134,4.134,0,0,0,1.412.978c1.032.435,2.661,1.195,4.888,2.173,2.281,1.032,4.019,1.792,5.214,2.335a11.215,11.215,0,0,1,4.073,2.933A6.543,6.543,0,0,1,371.7,54.13Z"
        transform="translate(-172.372 -25.832)"
      />
      <path
        className="b"
        fill="#fff"
        d="M400.82,71.632a3.362,3.362,0,0,1,1.032,2.444,3.244,3.244,0,0,1-1.032,2.444,3.362,3.362,0,0,1-2.444,1.032,3.524,3.524,0,0,1-3.476-3.476,3.245,3.245,0,0,1,1.032-2.444,3.362,3.362,0,0,1,2.444-1.032A3.328,3.328,0,0,1,400.82,71.632Z"
        transform="translate(-193.342 -41.868)"
      />
    </g>
  </svg>
);

export default LogoFooterIcon;
