import React from "react";

const LocationFooterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.196"
    height="16.172"
    viewBox="0 0 11.196 16.172"
  >
    <defs>{/* <style>.a{fill:#3a7aff;}</style> */}</defs>
    <path
      className="a"
      fill="#3a7aff"
      d="M13.473,3.375a5.411,5.411,0,0,0-5.6,5.2c0,4.043,5.6,10.974,5.6,10.974s5.6-6.931,5.6-10.974A5.411,5.411,0,0,0,13.473,3.375Zm0,7.421A1.823,1.823,0,1,1,15.3,8.973,1.823,1.823,0,0,1,13.473,10.8Z"
      transform="translate(-7.875 -3.375)"
    />
  </svg>
);

export default LocationFooterIcon;
