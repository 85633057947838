import React from "react";

function LegalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3401"
        data-name="Group 3401"
        transform="translate(816.243 -2408.757)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-816.243 2408.757)"
          fill="#ff7b16"
        />
        <path
          id="Icon_metro-justice"
          data-name="Icon metro-justice"
          d="M20.9,7.325l-2.76,6.126h-.8l2.943-6.522H15.739V22.123a53.328,53.328,0,0,1,5.715,1.585v.746H8.61v-.8a57.91,57.91,0,0,1,5.621-1.535V6.929h-4.4l2.944,6.522h-.8L9.212,7.325l-2.76,6.126h-.8L8.61,6.892V6.136h4.94a1.665,1.665,0,0,1,1.437-1.8,1.662,1.662,0,0,1,1.437,1.8h5.031v.649l3.011,6.666h-.8L20.9,7.325ZM12.825,14.2a3.683,3.683,0,0,1-3.613,3.864A3.715,3.715,0,0,1,5.6,14.2S12.825,14.2,12.825,14.2Zm4.566,0s7.226,0,7.226,0a3.621,3.621,0,1,1-7.226,0Z"
          transform="translate(-813.352 2412.361)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default LegalIcon;
