import React from "react";

function RatingIcon() {
  return (
    <svg
      id="Group_3654"
      data-name="Group 3654"
      xmlns="http://www.w3.org/2000/svg"
      width="35.369"
      height="27.728"
      viewBox="0 0 35.369 27.728"
    >
      <path
        id="Path_3675"
        data-name="Path 3675"
        d="M-223.838,710.585q0-1.931,0-3.863c0-.663.165-.833.817-.833,1.349,0,2.7,0,4.046,0a.954.954,0,0,0,.55-.16,4.557,4.557,0,0,0,2.091-3.537c.037-.353.1-.7.148-1.055.041-.283.186-.513.476-.526a6.312,6.312,0,0,1,1.9.065,2.312,2.312,0,0,1,1.736,2.2q.023,1.324,0,2.649c-.005.277.052.373.354.368,1.214-.019,2.428-.012,3.642-.007a1.759,1.759,0,0,1,1.769,1.117,1.728,1.728,0,0,1-.54,2.015.223.223,0,0,0-.064.3,1.7,1.7,0,0,1-.2,1.706c-.089.127-.066.195.011.311a1.7,1.7,0,0,1,0,2.025.253.253,0,0,0,0,.342,1.749,1.749,0,0,1-1.458,2.764c-2.293.027-4.586.019-6.879,0a5.222,5.222,0,0,1-2.99-.979,1.052,1.052,0,0,0-.647-.194c-1.324.01-2.649.006-3.973,0-.607,0-.8-.189-.8-.785Q-223.839,712.553-223.838,710.585Z"
        transform="translate(232.721 -688.765)"
        fill="#00173a"
      />
      <path
        id="Path_3676"
        data-name="Path 3676"
        d="M-179.964,583.118c.367,0,.829.135,1.073-.038s.27-.652.387-1c.1-.289.179-.584.284-.871a1.038,1.038,0,0,1,1-.726,1.036,1.036,0,0,1,.992.736c.189.542.371,1.087.53,1.639.062.214.149.291.376.283.551-.018,1.1-.01,1.654,0a1.054,1.054,0,0,1,1.1.745,1.036,1.036,0,0,1-.459,1.21q-.678.5-1.37.989a.283.283,0,0,0-.123.393c.192.528.357,1.065.525,1.6a1.07,1.07,0,0,1-.369,1.275,1.065,1.065,0,0,1-1.3-.074c-.448-.32-.9-.636-1.333-.976a.321.321,0,0,0-.471.009c-.436.336-.885.656-1.334.975a1.06,1.06,0,0,1-1.3.058,1.057,1.057,0,0,1-.364-1.243c.152-.49.3-.983.481-1.462.112-.294.094-.473-.2-.654-.436-.269-.839-.593-1.252-.9a1.028,1.028,0,0,1-.447-1.213,1.05,1.05,0,0,1,1.068-.735c.282-.006.564,0,.846,0Z"
        transform="translate(194.938 -580.484)"
        fill="#00173a"
      />
      <path
        id="Path_3677"
        data-name="Path 3677"
        d="M-79.157,626.8c.9,0,.9,0,1.175-.843.068-.209.125-.422.2-.627a.958.958,0,0,1,.892-.648.954.954,0,0,1,.911.674q.207.587.381,1.185c.056.19.137.276.355.265.415-.022.833-.012,1.249,0a.911.911,0,0,1,.912.658.91.91,0,0,1-.337,1.043c-.348.27-.706.527-1.067.779a.233.233,0,0,0-.109.327c.147.4.27.811.393,1.219a.943.943,0,0,1-.317,1.082.931.931,0,0,1-1.158-.01c-.312-.215-.628-.429-.917-.672a.391.391,0,0,0-.592.01c-.281.234-.587.44-.888.65a.938.938,0,0,1-1.158.033.962.962,0,0,1-.333-1.113c.112-.374.222-.75.368-1.112a.332.332,0,0,0-.163-.475c-.318-.206-.62-.437-.923-.665a.946.946,0,0,1-.4-1.123.946.946,0,0,1,.97-.632C-79.524,626.8-79.341,626.8-79.157,626.8Z"
        transform="translate(103.689 -620.338)"
        fill="#00173a"
      />
      <path
        id="Path_3678"
        data-name="Path 3678"
        d="M-312.589,704.642c.89,0,.89,0,1.169-.852.072-.22.134-.445.221-.659a.919.919,0,0,1,.88-.6.913.913,0,0,1,.878.612c.152.4.283.807.4,1.219a.338.338,0,0,0,.41.29c.4-.021.808-.012,1.212,0a.909.909,0,0,1,.893.632.908.908,0,0,1-.306,1.053,11.336,11.336,0,0,1-1.009.732.35.35,0,0,0-.159.505c.143.376.261.762.374,1.148a.929.929,0,0,1-.336,1.043.916.916,0,0,1-1.1.018c-.365-.245-.719-.508-1.069-.776a.265.265,0,0,0-.381,0c-.328.255-.667.5-1.008.734a.931.931,0,0,1-1.158.021.952.952,0,0,1-.332-1.081c.117-.4.238-.8.387-1.183a.28.28,0,0,0-.125-.393c-.333-.228-.659-.466-.982-.709a.942.942,0,0,1-.386-1.126.952.952,0,0,1,.977-.623C-312.957,704.64-312.773,704.642-312.589,704.642Z"
        transform="translate(314.178 -690.526)"
        fill="#00173a"
      />
      <path
        id="Path_3679"
        data-name="Path 3679"
        d="M-31.253,704.625c.88,0,.88,0,1.15-.824.065-.2.122-.4.191-.592a.971.971,0,0,1,.929-.7.957.957,0,0,1,.9.688c.13.368.259.738.362,1.114.064.232.156.342.422.322.39-.029.783-.012,1.175-.006a.917.917,0,0,1,.926.638.913.913,0,0,1-.342,1.073c-.329.254-.665.5-1.01.733a.3.3,0,0,0-.142.414c.15.4.273.81.393,1.219a.931.931,0,0,1-.342,1.041.918.918,0,0,1-1.1.012c-.345-.232-.686-.473-1.008-.734a.334.334,0,0,0-.5.008c-.3.246-.627.469-.949.691a.938.938,0,0,1-1.129.038.959.959,0,0,1-.355-1.106c.114-.386.228-.774.377-1.148a.315.315,0,0,0-.15-.448c-.315-.211-.619-.438-.923-.665a.946.946,0,0,1-.418-1.115.945.945,0,0,1,.99-.653C-31.62,704.624-31.436,704.625-31.253,704.625Z"
        transform="translate(60.509 -690.509)"
        fill="#00173a"
      />
      <path
        id="Path_3680"
        data-name="Path 3680"
        d="M-264.15,626.8c.881,0,.88,0,1.161-.855.061-.185.121-.371.187-.555a.965.965,0,0,1,.923-.707.973.973,0,0,1,.917.718c.13.381.26.763.368,1.151a.3.3,0,0,0,.36.255c.4-.019.808-.01,1.212,0a.934.934,0,0,1,.932.633.924.924,0,0,1-.342,1.076c-.327.256-.667.5-1.01.732a.3.3,0,0,0-.14.42c.146.361.252.739.367,1.112a.974.974,0,0,1-.326,1.148.952.952,0,0,1-1.189-.056c-.31-.219-.624-.432-.918-.671a.314.314,0,0,0-.47-.01c-.3.246-.626.469-.947.694a.945.945,0,0,1-1.189.028.957.957,0,0,1-.308-1.119c.118-.385.233-.772.377-1.148a.274.274,0,0,0-.13-.386c-.334-.226-.66-.465-.982-.709a.932.932,0,0,1-.392-1.09.933.933,0,0,1,.987-.658C-264.517,626.8-264.334,626.8-264.15,626.8Z"
        transform="translate(270.502 -620.337)"
        fill="#00173a"
      />
      <path
        id="Path_3681"
        data-name="Path 3681"
        d="M-164.022,721.558v-3.493c0-.156-.009-.29.165-.4a5.687,5.687,0,0,0,2.63-4.447c.052-.5.057-.5.563-.5a1.2,1.2,0,0,1,1.35,1.359c0,1.066,0,2.132,0,3.2,0,.528.206.738.733.739,1.446,0,2.892,0,4.338,0a1.3,1.3,0,0,1,.435.042.536.536,0,0,1,.373.572.553.553,0,0,1-.5.544,5.971,5.971,0,0,1-.623.019.59.59,0,0,0-.628.539.584.584,0,0,0,.541.627.677.677,0,0,1,.553.305.584.584,0,0,1-.463.872.727.727,0,0,0-.513.242.584.584,0,0,0,.42.935.733.733,0,0,1,.514.241.585.585,0,0,1-.416.938.758.758,0,0,0-.466.183.559.559,0,0,0-.145.576.586.586,0,0,0,.473.414.921.921,0,0,1,.419.115.566.566,0,0,1,.247.575.538.538,0,0,1-.418.462,1.747,1.747,0,0,1-.4.037q-3.217,0-6.433,0a4.484,4.484,0,0,1-2.528-.758.427.427,0,0,1-.221-.413C-164.017,723.911-164.022,722.734-164.022,721.558Z"
        transform="translate(178.791 -699.716)"
        fill="#f1d0a4"
      />
      <path
        id="Path_3682"
        data-name="Path 3682"
        d="M-208.372,770.093c0,1.078-.005,2.156,0,3.234,0,.216-.045.3-.282.3q-1.488-.019-2.976,0c-.235,0-.283-.078-.283-.3q.011-3.252,0-6.5c0-.2.047-.276.263-.274,1,.01,2.009.014,3.013,0,.26,0,.263.12.262.312C-208.375,767.937-208.372,769.015-208.372,770.093Z"
        transform="translate(221.964 -748.252)"
        fill="#c8e2ff"
      />
      <path
        id="Path_3683"
        data-name="Path 3683"
        d="M-165.9,602.589c.193-.6.379-1.179.568-1.759a.6.6,0,0,0-.276-.728l-1.6-1.165h1.87c.533,0,.645-.084.815-.6a14.917,14.917,0,0,1,.612-1.635q.244.752.488,1.5c.223.687.285.732,1.017.732h1.714c-.542.4-1.008.741-1.475,1.083s-.508.458-.327,1.021q.208.645.414,1.291c.028.089.107.194,0,.263-.083.055-.156-.043-.222-.09-.4-.285-.8-.566-1.187-.864a.676.676,0,0,0-.931.007c-.366.286-.749.551-1.127.822C-165.649,602.544-165.743,602.664-165.9,602.589Z"
        transform="translate(181.661 -595.106)"
        fill="#ff7b16"
      />
      <path
        id="Path_3684"
        data-name="Path 3684"
        d="M-62.847,643.541c.337,0,.674,0,1.012,0a.665.665,0,0,0,.711-.514c.105-.336.216-.67.351-1.085.13.4.243.719.338,1.045a.688.688,0,0,0,.755.555c.329-.01.659,0,1.092,0-.354.26-.628.473-.914.668a.645.645,0,0,0-.273.8c.083.269.179.534.264.8a.172.172,0,0,1-.009.137c-.05.062-.107.015-.156-.02l-.6-.43a.673.673,0,0,0-.99,0c-.248.179-.5.359-.745.537-.017.013-.045.01-.105.022.113-.341.21-.668.329-.987a.678.678,0,0,0-.3-.885c-.268-.182-.525-.379-.787-.569Z"
        transform="translate(87.581 -635.9)"
        fill="#ff7b16"
      />
      <path
        id="Path_3685"
        data-name="Path 3685"
        d="M-294.071,719.75c.136.417.249.739.346,1.065a.674.674,0,0,0,.731.535c.337-.007.673,0,1.11,0-.3.218-.51.375-.724.53-.577.418-.6.507-.383,1.2a1.92,1.92,0,0,1,.182.723,3.635,3.635,0,0,1-.806-.535.671.671,0,0,0-.9,0c-.276.213-.563.411-.922.67.139-.417.249-.733.35-1.051.144-.452.085-.622-.307-.91a5.283,5.283,0,0,1-.754-.627c.334,0,.669-.006,1,0a.676.676,0,0,0,.733-.534C-294.317,720.489-294.206,720.166-294.071,719.75Z"
        transform="translate(297.924 -706.056)"
        fill="#ff7b16"
      />
      <path
        id="Path_3686"
        data-name="Path 3686"
        d="M-15.1,724.477c.146-.45.25-.8.371-1.139a.6.6,0,0,0-.234-.742c-.284-.213-.573-.42-.961-.7.423,0,.741,0,1.058,0,.489,0,.64-.116.8-.589.1-.29.192-.581.289-.871.006-.018.025-.033.062-.08.117.356.234.689.335,1.026a.659.659,0,0,0,.708.515c.34,0,.681,0,1.123,0-.357.262-.632.475-.919.671a.647.647,0,0,0-.268.8c.1.31.2.618.326,1a3.253,3.253,0,0,1-.827-.522.68.68,0,0,0-.991,0Z"
        transform="translate(45.25 -706.599)"
        fill="#ff7b16"
      />
      <path
        id="Path_3687"
        data-name="Path 3687"
        d="M-244.481,643.7c-.272.2-.539.4-.816.6a.642.642,0,0,0-.277.824c.114.319.209.645.347,1.076-.36-.257-.635-.444-.9-.645a.662.662,0,0,0-.9,0c-.271.211-.56.4-.915.653.127-.385.223-.668.314-.951.179-.558.136-.689-.325-1.027-.242-.177-.484-.357-.807-.6.433,0,.769-.006,1.105,0a.673.673,0,0,0,.732-.532c.1-.326.21-.648.343-1.057.124.369.222.656.318.944.184.556.305.644.9.644h.844Z"
        transform="translate(255.192 -635.987)"
        fill="#ff7b16"
      />
    </svg>
  );
}
export default RatingIcon;
