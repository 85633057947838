import React from "react";

const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="30"
    viewBox="0 0 20 16.244"
  >
    <defs>{/* <style>.a{fill="#c5c5c5"}</style> */}</defs>
    <path
      className="a"
      // fill="#c5c5c5"
      d="M17.944,7.429c.013.178.013.355.013.533A11.582,11.582,0,0,1,6.294,19.624,11.583,11.583,0,0,1,0,17.784a8.48,8.48,0,0,0,.99.051,8.209,8.209,0,0,0,5.089-1.751,4.106,4.106,0,0,1-3.833-2.843,5.169,5.169,0,0,0,.774.063,4.335,4.335,0,0,0,1.079-.14A4.1,4.1,0,0,1,.812,9.142V9.091a4.128,4.128,0,0,0,1.853.52A4.105,4.105,0,0,1,1.4,4.129,11.651,11.651,0,0,0,9.848,8.419a4.627,4.627,0,0,1-.1-.939,4.1,4.1,0,0,1,7.094-2.8,8.07,8.07,0,0,0,2.6-.99,4.088,4.088,0,0,1-1.8,2.259A8.217,8.217,0,0,0,20,5.31a8.811,8.811,0,0,1-2.056,2.119Z"
      transform="translate(0 -3.381)"
    />
  </svg>
);

export default TwitterIcon;
