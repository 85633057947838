import React from "react";

function LockWhiteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.372"
      height="30.14"
      viewBox="0 0 26.372 30.14"
    >
      <path
        id="Icon_awesome-lock"
        data-name="Icon awesome-lock"
        d="M23.547,13.186H22.134V8.948a8.948,8.948,0,0,0-17.9,0v4.238H2.826A2.826,2.826,0,0,0,0,16.012v11.3A2.826,2.826,0,0,0,2.826,30.14H23.547a2.826,2.826,0,0,0,2.826-2.826v-11.3A2.826,2.826,0,0,0,23.547,13.186Zm-6.122,0H8.948V8.948a4.238,4.238,0,0,1,8.477,0Z"
        fill="#fff"
      />
    </svg>
  );
}
export default LockWhiteIcon;
