import React from "react";

function SecurityIconLarge() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      viewBox="0 0 65 65"
    >
      <g
        id="Group_3843"
        data-name="Group 3843"
        transform="translate(-173 -1912.845)"
      >
        <path
          id="Path_3464"
          data-name="Path 3464"
          d="M32.5,0A32.5,32.5,0,1,1,0,32.5,32.5,32.5,0,0,1,32.5,0Z"
          transform="translate(173 1912.845)"
          fill="#ff7b16"
        />
        <path
          id="Icon_material-security"
          data-name="Icon material-security"
          d="M18,1.5,4.5,7.5v9c0,8.325,5.76,16.11,13.5,18,7.74-1.89,13.5-9.675,13.5-18v-9Zm0,16.485H28.5C27.7,24.165,23.58,29.67,18,31.4V18H7.5V9.45L18,4.785Z"
          transform="translate(187.5 1927.5)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default SecurityIconLarge;
