import React from "react";
import BoxContainer from "../../components/BoxContainer";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";
import { Link } from "gatsby";
import iconResolver from "../../core/icon-resolver";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import setCookie from "../../core/setCookie";

const Header = ({ data, stickyHeader = false }) => {
  const { dataType = 0 } = data;

  const location = useLocation();
  const queriedParameters = queryString.parse(location.search);
  const {
    utm_source,
    utm_campaign,
    utm_agid,
    utm_term,
    creative,
    device,
    placement,
    utm_medium,
  } = queriedParameters;

  setCookie("utm_source", utm_source, 365);
  setCookie("utm_campaign", utm_campaign, 365);
  setCookie("utm_agid", utm_agid, 365);
  setCookie("utm_term", utm_term, 365);
  setCookie("creative", creative, 365);
  setCookie("device", device, 365);
  setCookie("placement", placement, 365);
  setCookie("utm_medium", utm_medium, 365);

  return (
    <>
      {stickyHeader ? (
        <div
          className="bg-white sticky top-0 z-50 w-full px-5 xl:px-0 pt-4 pb-1 md:py-0"
          style={{ boxShadow: "0 3px 5px rgba(57, 63, 72, 0.3)" }}
        >
          <div className="w-full flex xs:flex-col md:flex-row justify-between items-center max-w-6xl mx-auto xs:gap-3 md:gap-0">
            <div className="flex gap-4 text-black items-center text-base font-opensans font-semibold">
              <Link to="/">{iconResolver(data.logoIcon)}</Link> By Tomedes
            </div>
            <ul className="flex sm:gap-8">
              {data?.navList.map((item, i) => {
                return (
                  <li
                    className={`text-base text-blue hover:text-yellow font-opensans font-semibold transition-colors cursor-pointer inline-block pl-5 py-6 relative group`}
                    key={i}
                  >
                    {item.link ? (
                      <Link to={item.link}>
                        {dataType === 1
                          ? item.text
                          : textResolver(item, "text")}
                      </Link>
                    ) : (
                      <>
                        <span>
                          {dataType === 1
                            ? item.text
                            : textResolver(item, "text")}
                        </span>
                        <div
                          className="hidden bg-white group-hover:flex flex-col gap-2 absolute 0px:top-[76px] md:top-[72px] left-0 z-50 items-start py-2"
                          style={{
                            boxShadow: "0 3px 5px rgba(57, 63, 72, 0.3)",
                          }}
                        >
                          {item.subLink &&
                            item.subLink.map((subItem, i) => {
                              return (
                                <Link
                                  to={subItem.link}
                                  key={i}
                                  className="rounded-lg shadow-subLink text-blue hover:text-yellow transition-colors px-5 py-2 whitespace-nowrap	"
                                >
                                  {dataType === 1
                                    ? subItem.text
                                    : textResolver(subItem, "text")}
                                </Link>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <div className="xs:py-7 md:py-12">
          <div className="w-full flex xs:flex-col md:flex-row justify-between items-center max-w-6xl mx-auto xs:gap-7 md:gap-0">
            {/* <Link to="#" className="flex items-center gap-x-3">
          {iconResolver(data.logoIcon)}
          <Text as="h3" className="text-blue text-2xl font-primary font-bold">
            {dataType === 1 ? data.logoText : textResolver(data, "logoText")}
          </Text>
        </Link> */}
            <div className="flex gap-4 items-center text-black text-base font-opensans font-semibold">
              <Link to="/">{iconResolver(data.logoIcon)}</Link> By Tomedes
            </div>
            <ul className="flex sm:gap-8">
              {data?.navList.map((item, i) => {
                return (
                  <li
                    className={`text-base text-blue hover:text-yellow font-opensans font-semibold transition-colors cursor-pointer inline-block lg:pb-10 p-3 pl-5 relative group`}
                    key={i}
                  >
                    {item.link ? (
                      <Link to={item.link}>
                        {dataType === 1
                          ? item.text
                          : textResolver(item, "text")}
                      </Link>
                    ) : (
                      <>
                        <span>
                          {dataType === 1
                            ? item.text
                            : textResolver(item, "text")}
                        </span>
                        <div className="hidden bg-white group-hover:flex flex-col gap-2 absolute top-16 left-0 z-50 items-start py-2 rounded-lg shadow-lighGray">
                          {item.subLink &&
                            item.subLink.map((subItem, i) => {
                              return (
                                <Link
                                  to={subItem.link}
                                  key={i}
                                  className="rounded-lg shadow-subLink text-blue hover:text-yellow transition-colors px-5 py-2 whitespace-nowrap	"
                                >
                                  {dataType === 1
                                    ? subItem.text
                                    : textResolver(subItem, "text")}
                                </Link>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
export default Header;
