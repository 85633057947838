import React from "react";

function QualityGuaranteedIcon() {
  return (
    <svg
      id="Group_3643"
      data-name="Group 3643"
      xmlns="http://www.w3.org/2000/svg"
      width="21.819"
      height="27.649"
      viewBox="0 0 21.819 27.649"
    >
      <path
        id="Path_3587"
        data-name="Path 3587"
        d="M153.437,257.28a.764.764,0,0,1-.43-.576c-.226-.877-.47-1.75-.7-2.628-.042-.165-.08-.213-.266-.161-.907.254-1.821.482-2.729.732a.552.552,0,0,1-.652-.178.566.566,0,0,1,.011-.678q1.229-2.119,2.448-4.245l.17-.294c-.235-.04-.453-.066-.666-.116a2.542,2.542,0,0,1-1.976-2.479,1.738,1.738,0,0,0-.96-1.667,2.547,2.547,0,0,1-.941-3.47,1.771,1.771,0,0,0,.005-1.947,2.554,2.554,0,0,1,.947-3.491,1.7,1.7,0,0,0,.947-1.618,2.574,2.574,0,0,1,2.6-2.6,1.686,1.686,0,0,0,1.6-.929,2.559,2.559,0,0,1,3.531-.951,1.708,1.708,0,0,0,1.874,0,2.556,2.556,0,0,1,3.531.946,1.683,1.683,0,0,0,1.6.931,2.572,2.572,0,0,1,2.607,2.6,1.7,1.7,0,0,0,.922,1.606,2.553,2.553,0,0,1,.948,3.55,1.7,1.7,0,0,0,0,1.852,2.555,2.555,0,0,1-.936,3.534,1.712,1.712,0,0,0-.939,1.624,2.584,2.584,0,0,1-2.453,2.591c-.053,0-.106.007-.162.011-.013.119.068.189.114.269q1.234,2.148,2.476,4.291a.592.592,0,0,1,.042.635.54.54,0,0,1-.656.226c-.911-.241-1.823-.475-2.728-.733-.211-.06-.252,0-.3.184-.222.869-.466,1.733-.689,2.6a.769.769,0,0,1-.431.576h-.324a.708.708,0,0,1-.429-.378q-1.4-2.442-2.815-4.88c-.1-.165-.146-.447-.332-.444-.146,0-.214.282-.307.443q-1.426,2.476-2.856,4.949a.627.627,0,0,1-.387.309Z"
        transform="translate(-146.413 -229.631)"
        fill="#00173a"
      />
      <path
        id="Path_3589"
        data-name="Path 3589"
        d="M181.123,252.181a1.676,1.676,0,0,1-.247.892,2.585,2.585,0,0,0,0,2.784,1.513,1.513,0,0,1-.587,2.193,2.578,2.578,0,0,0-1.387,2.388,1.526,1.526,0,0,1-1.614,1.631,2.6,2.6,0,0,0-2.411,1.4,1.506,1.506,0,0,1-2.171.587,2.616,2.616,0,0,0-2.809,0,1.51,1.51,0,0,1-2.188-.6,2.577,2.577,0,0,0-2.394-1.377,1.528,1.528,0,0,1-1.618-1.627,2.585,2.585,0,0,0-1.383-2.391,1.512,1.512,0,0,1-.579-2.213,2.586,2.586,0,0,0,0-2.739,1.519,1.519,0,0,1,.595-2.231,2.556,2.556,0,0,0,1.364-2.346,1.534,1.534,0,0,1,1.642-1.658,2.577,2.577,0,0,0,2.369-1.374,1.513,1.513,0,0,1,2.21-.6,2.6,2.6,0,0,0,2.785-.012,1.506,1.506,0,0,1,2.173.582,2.6,2.6,0,0,0,2.409,1.4,1.523,1.523,0,0,1,1.618,1.626,2.563,2.563,0,0,0,1.385,2.388A1.528,1.528,0,0,1,181.123,252.181Z"
        transform="translate(-160.386 -243.556)"
        fill="#c8e2ff"
      />
      <path
        id="Path_3590"
        data-name="Path 3590"
        d="M316.753,512.018,314.436,508c.222.05.393.1.567.127a2.582,2.582,0,0,0,2.686-1.24,3.068,3.068,0,0,1,.317-.467c.119-.136.182-.139.279.031.54.953,1.091,1.9,1.638,2.847l.411.714-2.121-.567c-.5-.133-.722-.007-.857.486-.173.631-.341,1.264-.511,1.9A.288.288,0,0,1,316.753,512.018Z"
        transform="translate(-302.312 -486.359)"
        fill="#ff7b16"
      />
      <path
        id="Path_3591"
        data-name="Path 3591"
        d="M205.553,507.869l-2.314,4.011a.568.568,0,0,1-.127-.322c-.169-.605-.326-1.214-.492-1.82a.553.553,0,0,0-.767-.451c-.617.16-1.231.329-1.846.5a.45.45,0,0,1-.3.012l1.574-2.731c.153-.264.314-.525.458-.794.066-.124.116-.133.21-.032a2.21,2.21,0,0,1,.29.388,2.582,2.582,0,0,0,3.145,1.265A1.65,1.65,0,0,1,205.553,507.869Z"
        transform="translate(-195.859 -486.218)"
        fill="#ff7b16"
      />
      <path
        id="Path_3592"
        data-name="Path 3592"
        d="M202.846,285.213a12.84,12.84,0,0,1,1.088-.767c.009,0,.022-.009.026-.005.35.293.683.144,1.047,0a6.77,6.77,0,0,1,7.147,1.058,6.65,6.65,0,0,1,2.5,4.626,6.98,6.98,0,0,1-13.838,1.807,6.627,6.627,0,0,1,.446-3.7c.164-.387.334-.752.034-1.144A10.127,10.127,0,0,1,202.06,286c.084-.045.155.014.23.03a.461.461,0,0,0,.584-.585C202.859,285.368,202.8,285.3,202.846,285.213Z"
        transform="translate(-196.804 -279.958)"
        fill="#fefefe"
      />
      <path
        id="Path_3593"
        data-name="Path 3593"
        d="M188.4,274.373c.311.276.345.449.163.853a6.909,6.909,0,1,0,7.618-3.844,6.74,6.74,0,0,0-4.311.522.576.576,0,0,1-.828-.172c-.115-.431-.023-.621.39-.811a7.988,7.988,0,1,1-4.53,8.282,7.855,7.855,0,0,1,.7-4.465A.573.573,0,0,1,188.4,274.373Z"
        transform="translate(-183.912 -267.243)"
        fill="#00173a"
      />
      <path
        id="Path_3594"
        data-name="Path 3594"
        d="M218.255,300.54a.582.582,0,0,1,.039.694.5.5,0,0,1-.561.21,1.407,1.407,0,0,1-.263-.119c-.225-.38-.173-.711.137-.873A.6.6,0,0,1,218.255,300.54Z"
        transform="translate(-212.214 -295.284)"
        fill="#010100"
      />
      <path
        id="Path_3595"
        data-name="Path 3595"
        d="M238.79,324.2c.326.037.632,0,.936.042a1.737,1.737,0,0,1,1.5,1.683q.017,1.214,0,2.428a1.71,1.71,0,0,1-1.663,1.685c-1.034.02-2.068.007-3.1,0a3.327,3.327,0,0,1-1.229-.27.206.206,0,0,0-.26.037.952.952,0,0,1-.693.243c-.513,0-1.025,0-1.538,0a.98.98,0,0,1-1.017-1.021q-.006-2.185,0-4.371a1,1,0,0,1,1.039-1.034c.5-.006,1.007,0,1.511,0a.67.67,0,0,1,.342.053c.257.169.422.032.611-.119a5.913,5.913,0,0,0,1.235-1.415,1.372,1.372,0,0,1,1.5-.624,1.394,1.394,0,0,1,1.141,1.12,1.439,1.439,0,0,1-.053.823C238.957,323.7,238.876,323.943,238.79,324.2Z"
        transform="translate(-225.537 -314.853)"
        fill="#00173a"
      />
      <path
        id="Path_3596"
        data-name="Path 3596"
        d="M281.677,340.55c0-.575.005-1.151,0-1.726a.317.317,0,0,1,.16-.308,5.98,5.98,0,0,0,1.806-1.861c.16-.256.359-.333.559-.216.231.135.229.338.146.564s-.155.459-.232.688a.674.674,0,0,1-.34.474.45.45,0,0,0-.2.563.485.485,0,0,0,.487.389c.53.008,1.061,0,1.591.005.6,0,.846.245.846.851,0,.692,0,1.385,0,2.077a.666.666,0,0,1-.728.742c-.926,0-1.852,0-2.778,0a3,3,0,0,1-1.075-.22.313.313,0,0,1-.244-.35C281.688,341.665,281.677,341.107,281.677,340.55Z"
        transform="translate(-271.912 -328.683)"
        fill="#fecabd"
      />
      <path
        id="Path_3597"
        data-name="Path 3597"
        d="M246.626,368.094c0-.63.011-1.259-.006-1.889-.006-.222.068-.272.273-.262.332.016.665.01,1,0,.139,0,.188.04.187.184,0,.9,0,1.792,0,2.708,0,.388,0,.8,0,1.2,0,.15-.062.18-.193.177-.351-.006-.7-.01-1.052,0-.166.006-.214-.044-.211-.211C246.632,369.371,246.626,368.733,246.626,368.094Z"
        transform="translate(-239.389 -356.107)"
        fill="#ff7b16"
      />
    </svg>
  );
}
export default QualityGuaranteedIcon;
