import React from "react";

function CostEffectiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66.572"
      height="68"
      viewBox="0 0 66.572 68"
    >
      <g
        id="Group_3828"
        data-name="Group 3828"
        transform="translate(772.9 1978.252)"
      >
        <path
          id="Path_3992"
          data-name="Path 3992"
          d="M-740.737-1978.252h2.255c.083.09.192.062.294.068h.474c.1-.006.211.021.293-.068h.4c1.065.162,2.146.133,3.211.321a32.753,32.753,0,0,1,15.663,7.346,33.065,33.065,0,0,1,8.328,10.547,32.825,32.825,0,0,1,3.26,11.006,33.177,33.177,0,0,1-2.627,17.356,32.709,32.709,0,0,1-2.586,4.778,1.593,1.593,0,0,0-.354.665,1.833,1.833,0,0,0,.831.07,2.379,2.379,0,0,1,2.562,2.316,7.8,7.8,0,0,1,.023.861q0,4.842,0,9.684a6.781,6.781,0,0,1-.032.927,2.175,2.175,0,0,1-2.405,2.116q-4.709.007-9.419,0c-4.348,0-8.7.011-13.044,0-4.371-.013-8.741.019-13.112,0a2.231,2.231,0,0,1-2.529-2.341.959.959,0,0,0-.79-.969,31.918,31.918,0,0,1-9.06-4.622,33.349,33.349,0,0,1-9.682-10.931,32.539,32.539,0,0,1-3.936-12.57,33.065,33.065,0,0,1,2.791-17.2,31.816,31.816,0,0,1,6.639-9.6,1.159,1.159,0,0,1,1.8-.172,1.218,1.218,0,0,1-.213,1.867,29.95,29.95,0,0,0-2.949,3.491c-.4.546-.385.637.164.985q8.482,5.381,16.973,10.747,2.913,1.845,5.824,3.692a5.421,5.421,0,0,0,.569.339c.184.088.3.033.324-.174a4.869,4.869,0,0,0,.014-.6q0-13.395,0-26.79c0-1.019-.031-.993-1.062-.912a31.638,31.638,0,0,0-6.193,1.079,30.513,30.513,0,0,0-9.778,4.774c-.8.587-1.323.6-1.963.067a1.307,1.307,0,0,1,.538-1.926,32.754,32.754,0,0,1,10.671-5.188,23.5,23.5,0,0,1,6.063-.978.279.279,0,0,0,.179-.068h.4c.086.092.2.061.3.068h.5C-741-1978.2-740.852-1978.138-740.737-1978.252Z"
          transform="translate(0 0)"
          fill="#00173a"
        />
        <path
          id="Path_3993"
          data-name="Path 3993"
          d="M-533.954-1978.252c-.1.166-.257.128-.407.115-.037-.029-.048-.055-.033-.08s.028-.036.042-.036Z"
          transform="translate(-206.782 0)"
          fill="#363636"
        />
        <path
          id="Path_3994"
          data-name="Path 3994"
          d="M-513.815-1978.131a.24.24,0,0,1-.27-.121h.265c.043.025.057.052.045.079S-513.8-1978.131-513.815-1978.131Z"
          transform="translate(-224.396 0)"
          fill="#303030"
        />
        <path
          id="Path_3995"
          data-name="Path 3995"
          d="M-508.15-1978.252c-.036.153-.15.125-.259.111-.048-.022-.065-.046-.051-.071s.029-.04.044-.04Z"
          transform="translate(-229.269 0)"
          fill="#7b7b7b"
        />
        <path
          id="Path_3996"
          data-name="Path 3996"
          d="M-510.145-1978.252c0,.037,0,.074.007.111h-.264c-.048-.022-.065-.046-.051-.072s.029-.04.044-.04Z"
          transform="translate(-227.54 0)"
          fill="#5d5d5d"
        />
        <path
          id="Path_3997"
          data-name="Path 3997"
          d="M-511.825-1978.252c0,.038,0,.075.007.113l-.268.008c0-.04,0-.081,0-.121Z"
          transform="translate(-226.126 0)"
          fill="#424242"
        />
        <path
          id="Path_3998"
          data-name="Path 3998"
          d="M-536.081-1978.252a.871.871,0,0,1-.009.115l-.264,0c-.038-.027-.049-.053-.035-.077s.028-.035.042-.035Z"
          transform="translate(-205.054 0)"
          fill="#555"
        />
        <path
          id="Path_3999"
          data-name="Path 3999"
          d="M-538.077-1978.252c0,.037,0,.074-.007.112h-.264c-.039-.027-.051-.052-.037-.076s.028-.035.042-.035Z"
          transform="translate(-203.323 0)"
          fill="#737373"
        />
        <path
          id="Path_4000"
          data-name="Path 4000"
          d="M-539.76-1978.252a1.022,1.022,0,0,1-.005.112c-.11.017-.229.056-.26-.112Z"
          transform="translate(-201.906 0)"
          fill="#919191"
        />
        <path
          id="Path_4001"
          data-name="Path 4001"
          d="M-510.833-1928.767a1.145,1.145,0,0,0-.58-1.557c-1.112-.68-2.2-1.4-3.312-2.078a.807.807,0,0,1-.459-.781q.019-14.759.008-29.518c0-.3-.033-.558.429-.524a29.186,29.186,0,0,1,7.406,1.306,30.816,30.816,0,0,1,12.7,11.5A31.05,31.05,0,0,1-490-1937.969a32.5,32.5,0,0,1,.249,7.2,32.4,32.4,0,0,1-2.933,11.225c-.13.282-.166.677-.627.648-1.534-.957-3.064-1.92-4.605-2.864a.6.6,0,0,1-.28-.61c.008-.684.013-1.37,0-2.054a2.129,2.129,0,0,0-2.222-2.2c-2.363-.019-4.725-.021-7.088-.029A10.732,10.732,0,0,1-510.833-1928.767Z"
          transform="translate(-223.443 -13.026)"
          fill="#ff7b16"
        />
        <path
          id="Path_4002"
          data-name="Path 4002"
          d="M-711.371-1955.552l1.867-1.612a1.171,1.171,0,0,0,1.618-.007,30.5,30.5,0,0,1,13.861-5.743c1.047-.156,2.109-.206,3.163-.317.33-.035.465.045.464.416q-.02,14.323-.02,28.646a.28.28,0,0,1-.017.066c-.208.091-.323-.088-.46-.175q-11.869-7.5-23.741-15c-.468-.295-.453-.484-.155-.886a32.527,32.527,0,0,1,3.209-3.791A1.124,1.124,0,0,0-711.371-1955.552Z"
          transform="translate(-50.197 -13.017)"
          fill="#fe8a00"
        />
        <path
          id="Path_4003"
          data-name="Path 4003"
          d="M-681.508-1717.754l3.326,2.109c-1.039.009-2.077.017-3.115.029a2.146,2.146,0,0,0-2.278,2.288c-.012,2.056-.024,4.111.01,6.167.01.568-.171.691-.709.687-3.161-.024-6.321-.015-9.482-.011-1.746,0-2.542.8-2.543,2.56q0,5.835,0,11.67a1.41,1.41,0,0,1-.081.749,28.473,28.473,0,0,1-5.918-2.6,31.11,31.11,0,0,1-9.533-8.492c-.268-.36-.3-.534.11-.809q12.262-8.128,24.5-16.287a.636.636,0,0,1,.822,0c1.069.706,2.17,1.363,3.237,2.071A1.15,1.15,0,0,0-681.508-1717.754Z"
          transform="translate(-52.769 -224.039)"
          fill="#c8e2ff"
        />
        <path
          id="Path_4004"
          data-name="Path 4004"
          d="M-728.8-1832.534h-.829q-13.069,0-26.137.01c-.534,0-.765-.048-.721-.694a31.866,31.866,0,0,1,3.98-13.613c.231-.414.385-.505.831-.222q11.237,7.145,22.5,14.254C-729.107-1832.752-729.037-1832.7-728.8-1832.534Z"
          transform="translate(-14.223 -113.61)"
          fill="#fec40d"
        />
        <path
          id="Path_4005"
          data-name="Path 4005"
          d="M-442.181-1910.365a7.838,7.838,0,0,0,1.213-2.455,30.448,30.448,0,0,0,2.106-8.412,31.307,31.307,0,0,0-.065-7.24,32.614,32.614,0,0,0-2.163-8.4,31.274,31.274,0,0,0-5.211-8.528,33.659,33.659,0,0,0-7.041-6.247c-.936-.616-1.983-1.047-2.869-1.744a25.757,25.757,0,0,1,7.018,3.03,30.546,30.546,0,0,1,14.248,18.917,30.5,30.5,0,0,1-3.011,23.165c-.112.2-.163.542-.561.279C-439.729-1908.8-440.958-1909.578-442.181-1910.365Z"
          transform="translate(-274.575 -21.558)"
          fill="#cb5800"
        />
        <path
          id="Path_4006"
          data-name="Path 4006"
          d="M-724.194-1712.816c-.1.265-.328.326-.5.443q-10.065,6.7-20.129,13.4c-.445.3-.6.24-.861-.205a32.746,32.746,0,0,1-3.283-7.781c-.114-.411-.06-.564.37-.656q12-2.576,24-5.172A2.681,2.681,0,0,1-724.194-1712.816Z"
          transform="translate(-20.702 -230.137)"
          fill="#fea200"
        />
        <path
          id="Path_4007"
          data-name="Path 4007"
          d="M-735.023-1721.87c-3.236.7-6.351,1.377-9.467,2.049-3.619.782-7.24,1.552-10.854,2.351-.509.113-.682.019-.753-.52q-.229-1.741-.364-3.489c-.024-.316.016-.469.4-.468q10.369.019,20.738.014A2.224,2.224,0,0,1-735.023-1721.87Z"
          transform="translate(-14.247 -222.22)"
          fill="#fee2db"
        />
        <path
          id="Path_4008"
          data-name="Path 4008"
          d="M-578.447-1577.739c1.268-.013,2.536-.049,3.8-.03a1.213,1.213,0,0,0,1.382-.927,2.233,2.233,0,0,1,2.224.13,1.238,1.238,0,0,0,.819.8,1.54,1.54,0,0,1-1.344,2.305c-2.092.03-4.185.008-6.278.01-.5,0-.729-.2-.672-.719A10.7,10.7,0,0,1-578.447-1577.739Z"
          transform="translate(-168.529 -346.22)"
          fill="#91c3fc"
        />
        <path
          id="Path_4009"
          data-name="Path 4009"
          d="M-483.488-1568.743c0-.681-.242-1.544.084-1.99.314-.431,1.233-.131,1.885-.134,2.119-.01,4.238.011,6.356.02a1.565,1.565,0,0,1-1.449,2.3c-2.045.017-4.09.006-6.136,0A.916.916,0,0,1-483.488-1568.743Z"
          transform="translate(-250.85 -353.123)"
          fill="#91c3fc"
        />
        <path
          id="Path_4010"
          data-name="Path 4010"
          d="M-483.659-1671.591c-.024-.68-.284-1.532.083-1.99s1.241-.142,1.891-.146c2.117-.012,4.234.013,6.351.023a1.6,1.6,0,0,1-1.412,2.31q-1.725,0-3.449,0c-.884,0-1.769,0-2.653,0A1.011,1.011,0,0,1-483.659-1671.591Z"
          transform="translate(-250.679 -263.941)"
          fill="#91c3fc"
        />
        <path
          id="Path_4011"
          data-name="Path 4011"
          d="M-483.632-1500.236c-.014-.655-.279-1.488.093-1.914.354-.4,1.2-.117,1.833-.119,2.134,0,4.269.019,6.4.031a1.59,1.59,0,0,1-1.294,2.3c-2.14.078-4.283.03-6.425.02A.6.6,0,0,1-483.632-1500.236Z"
          transform="translate(-250.708 -412.6)"
          fill="#91c3fc"
        />
        <path
          id="Path_4012"
          data-name="Path 4012"
          d="M-483.149-1602.591a11.317,11.317,0,0,1,.028-2,.867.867,0,0,1,.516-.062q3.607,0,7.214,0c.669,0,.812.164.74.822a1.583,1.583,0,0,1-1.6,1.544c-2.1.047-4.191.022-6.287.01A.6.6,0,0,1-483.149-1602.591Z"
          transform="translate(-251.191 -323.912)"
          fill="#91c3fc"
        />
        <path
          id="Path_4013"
          data-name="Path 4013"
          d="M-387.984-1568.738c-.023-.68-.288-1.54.087-1.989.361-.432,1.245-.132,1.9-.135,2.075-.012,4.151.009,6.226.017a1.478,1.478,0,0,1-1.306,2.293c-2.1.038-4.194.013-6.291.009A.8.8,0,0,1-387.984-1568.738Z"
          transform="translate(-333.63 -353.127)"
          fill="#91c3fc"
        />
        <path
          id="Path_4014"
          data-name="Path 4014"
          d="M-387.441-1499.546c-.008-.595-.007-1.19-.029-1.784-.01-.281.082-.386.373-.384,2.622.019,5.245.027,7.867.038a1.51,1.51,0,0,1-1.372,2.32c-2.053.03-4.107.01-6.161.008A.88.88,0,0,1-387.441-1499.546Z"
          transform="translate(-334.172 -413.165)"
          fill="#91c3fc"
        />
        <path
          id="Path_4015"
          data-name="Path 4015"
          d="M-578.433-1501.725c1.226-.127,2.456-.041,3.684-.05,1.515-.01,3.031.019,4.546.031a1.554,1.554,0,0,1-1.427,2.315c-1.387.042-2.776.01-4.164.011-.683,0-1.366,0-2.049,0-.452,0-.706-.159-.655-.667A11.6,11.6,0,0,1-578.433-1501.725Z"
          transform="translate(-168.544 -413.095)"
          fill="#91c3fc"
        />
        <path
          id="Path_4016"
          data-name="Path 4016"
          d="M-482.945-1533.754q.007-1,.013-1.992a.894.894,0,0,1,.518-.061q3.613,0,7.225,0c.673,0,.815.165.739.824a1.427,1.427,0,0,1-1.521,1.427c-2.1.037-4.2.018-6.3.008A.856.856,0,0,1-482.945-1533.754Z"
          transform="translate(-251.395 -383.599)"
          fill="#91c3fc"
        />
        <path
          id="Path_4017"
          data-name="Path 4017"
          d="M-482.945-1636.483q0-1,.005-1.992a.627.627,0,0,1,.385-.061q3.711,0,7.422,0c.6,0,.739.16.69.747a1.49,1.49,0,0,1-1.6,1.507c-2.054.029-4.108.014-6.163.006A.937.937,0,0,1-482.945-1636.483Z"
          transform="translate(-251.395 -294.536)"
          fill="#91c3fc"
        />
        <path
          id="Path_4018"
          data-name="Path 4018"
          d="M-387.265-1533.752q.007-1,.014-1.994a.911.911,0,0,1,.518-.06q3.544,0,7.088,0c.68,0,.8.128.793.8a1.3,1.3,0,0,1-1.375,1.444c-2.141.046-4.283.024-6.425.01A.817.817,0,0,1-387.265-1533.752Z"
          transform="translate(-334.351 -383.6)"
          fill="#91c3fc"
        />
        <path
          id="Path_4019"
          data-name="Path 4019"
          d="M-386.226-1628.639c-1.2.065-1.2.065-1.2-1.122v-5.515l3.981,2.567c.16.376-.141.626-.294.88-.578.961-1.241,1.867-1.912,2.766A1.258,1.258,0,0,1-386.226-1628.639Z"
          transform="translate(-334.211 -297.364)"
          fill="#c8e2ff"
        />
        <path
          id="Path_4020"
          data-name="Path 4020"
          d="M-378.4-1611.9a23.713,23.713,0,0,0,2.781-4.07c1.156.722,2.309,1.449,3.472,2.161.285.174.3.345.091.575a.255.255,0,0,0-.038.054,2.178,2.178,0,0,1-2.411,1.334A38.464,38.464,0,0,0-378.4-1611.9Z"
          transform="translate(-342.037 -314.1)"
          fill="#91c3fc"
        />
        <path
          id="Path_4021"
          data-name="Path 4021"
          d="M-573.589-1601.31a1.171,1.171,0,0,0-1.363-.942c-1.14.033-2.282-.009-3.422.018-.409.01-.559-.117-.513-.519a7.326,7.326,0,0,1,.118-1.989c.3.365.2.8.194,1.212,0,.46.147.695.645.692,1.921-.01,3.842.022,5.763-.017.869-.017,1.307-.389,1.382-1.046.046-.406-.093-.637-.529-.646-.663-.013-1.325-.006-1.987-.007-1.524,0-3.047,0-4.571,0a1.183,1.183,0,0,1-.881-.2c.169-.158.379-.086.569-.087,3.116,0,6.232.014,9.348-.01.552,0,.75.149.7.7a10.423,10.423,0,0,0,0,1.457c.017.34-.084.474-.442.455-.529-.028-1.061.009-1.591-.011a1.083,1.083,0,0,0-1.2.815c-.128.266-.376.191-.581.182A7.4,7.4,0,0,1-573.589-1601.31Z"
          transform="translate(-168.201 -323.738)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4022"
          data-name="Path 4022"
          d="M-573.506-1536.191c1.612,0,3.225.015,4.836-.01.438-.007.582.121.551.558-.047.657.205,1.519-.124,1.921-.347.424-1.241.129-1.9.133-2.716.015-5.433-.005-8.149.017-.471,0-.62-.139-.589-.6a11.319,11.319,0,0,0,0-1.523c-.029-.426.145-.516.535-.512,1.612.018,3.224.008,4.837.008Z"
          transform="translate(-168.217 -383.263)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4023"
          data-name="Path 4023"
          d="M-387.483-1534.19c2.3,0,4.6-.022,6.894.01,1.258.018,1.408-.811,1.307-1.719-.046-.415-.4-.274-.63-.275-2.519-.011-5.038-.009-7.557-.01.143-.219.362-.106.541-.106,3.182-.007,6.363.008,9.544-.013.493,0,.675.119.624.624-.064.633.254,1.453-.115,1.859s-1.21.126-1.841.13c-2.74.017-5.479,0-8.219.011C-387.3-1533.678-387.623-1533.671-387.483-1534.19Z"
          transform="translate(-334.133 -383.162)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4024"
          data-name="Path 4024"
          d="M-483.138-1637.052c2.321,0,4.641,0,6.962.007a1.126,1.126,0,0,0,1.173-.677c.453-1.021.266-1.32-.836-1.32h-7.293c.128-.244.354-.119.528-.119,3.2-.008,6.406.006,9.609-.013.463,0,.626.113.585.584a8.4,8.4,0,0,0,0,1.456c.044.491-.161.575-.6.573-3.181-.017-6.362-.009-9.543-.01C-482.877-1636.574-483.28-1636.484-483.138-1637.052Z"
          transform="translate(-251.202 -293.967)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4025"
          data-name="Path 4025"
          d="M-483.161-1534.181c2.322,0,4.643,0,6.965,0a1.116,1.116,0,0,0,1.23-.782c.332-1.073.227-1.21-.885-1.211l-7.3,0c.144-.217.364-.107.543-.107,3.184-.006,6.368.009,9.552-.013.5,0,.662.123.619.624-.054.634.2,1.49-.119,1.856-.373.422-1.246.131-1.9.134-2.72.015-5.439,0-8.159.009C-482.976-1533.668-483.3-1533.661-483.161-1534.181Z"
          transform="translate(-251.179 -383.172)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4026"
          data-name="Path 4026"
          d="M-483.145-1602.762a1.085,1.085,0,0,0,.638.127c2.033,0,4.065,0,6.1,0a1.425,1.425,0,0,0,1.585-1.409c.062-.462-.011-.726-.6-.718-2.408.033-4.816.014-7.225.014-.155,0-.309,0-.464-.005.147-.15.334-.082.5-.082,3.2,0,6.406.01,9.609-.007.46,0,.625.117.588.587a12.13,12.13,0,0,0,0,1.589c.02.365-.124.438-.458.436q-4.9-.016-9.808,0C-483.12-1602.229-483.24-1602.37-483.145-1602.762Z"
          transform="translate(-251.195 -323.741)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4027"
          data-name="Path 4027"
          d="M-578.811-1501.655c0,.551.018,1.1,0,1.652-.01.345.128.463.467.462,2.16-.009,4.319.008,6.479-.013a1.3,1.3,0,0,0,1.277-1.26c.02-.285,0-.573,0-.859.661-.012,1.323-.013,1.984-.04.307-.012.436.079.424.407-.021.6-.016,1.193,0,1.789.007.293-.115.379-.394.378q-4.97-.008-9.94,0c-.183,0-.4-.006-.39-.244A7.892,7.892,0,0,1-578.811-1501.655Z"
          transform="translate(-168.167 -413.165)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4028"
          data-name="Path 4028"
          d="M-578.8-1570.293c0,.132,0,.265,0,.4,0,1.7,0,1.7,1.734,1.7h4.826c1.25,0,1.661-.409,1.663-1.655,0-.154,0-.308,0-.463.639-.005,1.28.007,1.918-.022.36-.016.516.072.494.468-.033.572-.02,1.147,0,1.72.008.283-.085.386-.376.385q-5-.011-9.995-.005c-.183,0-.378,0-.365-.26A8.544,8.544,0,0,1-578.8-1570.293Z"
          transform="translate(-168.18 -353.665)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4029"
          data-name="Path 4029"
          d="M-387.4-1499.53h6.474c1.415,0,1.735-.32,1.737-1.735,0-.132,0-.264,0-.4.706-.011,1.412-.016,2.117-.035.279-.008.4.094.388.383q-.022.894,0,1.789c.009.337-.16.4-.451.4q-4.937-.008-9.875,0C-387.306-1499.121-387.49-1499.181-387.4-1499.53Z"
          transform="translate(-334.214 -413.182)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4030"
          data-name="Path 4030"
          d="M-387.418-1568.181h6.534c1.342,0,1.677-.328,1.676-1.647q0-.231,0-.462c.683-.008,1.366,0,2.048-.029.343-.012.473.113.461.459-.02.55-.022,1.1,0,1.653.014.338-.089.463-.443.462q-4.96-.016-9.921,0C-387.4-1567.744-387.5-1567.876-387.418-1568.181Z"
          transform="translate(-334.196 -353.685)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4031"
          data-name="Path 4031"
          d="M-483.139-1500.146a1.271,1.271,0,0,0,.77.125c1.9,0,3.8-.044,5.7.016,1.3.04,1.791-.488,1.86-1.812.006-.11,0-.221,0-.331a2.535,2.535,0,0,1,.393-.034c.65.042,1.519-.285,1.888.139.321.37.069,1.217.118,1.85.037.478-.147.587-.6.584-2.8-.019-5.6-.009-8.4-.009-.419,0-.839-.021-1.256.006S-483.24-1499.734-483.139-1500.146Z"
          transform="translate(-251.202 -412.69)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4032"
          data-name="Path 4032"
          d="M-483.107-1671.021l6.486,0c1.4,0,1.749-.323,1.839-1.718.009-.132,0-.265,0-.4.662-.01,1.324-.011,1.985-.035.325-.012.44.13.405.434,0,.022,0,.044,0,.066-.013.659.22,1.517-.108,1.924-.343.426-1.238.13-1.89.134-2.74.015-5.479.007-8.219.006C-482.889-1670.6-483.234-1670.531-483.107-1671.021Z"
          transform="translate(-251.231 -264.511)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4033"
          data-name="Path 4033"
          d="M-483.1-1568.164l6.629,0c1.239,0,1.352-.1,1.6-1.324a3.228,3.228,0,0,0,.1-.782c.132-.009.263-.024.395-.026,2.174-.022,2.168-.022,2,2.187-.021.282-.1.38-.381.379q-4.993-.01-9.986,0C-483.082-1567.727-483.178-1567.858-483.1-1568.164Z"
          transform="translate(-251.239 -353.702)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4034"
          data-name="Path 4034"
          d="M-538.976-1578.984a8.293,8.293,0,0,0,2.215-.123q0,.193.007.387a7.818,7.818,0,0,0-2.224-.13Z"
          transform="translate(-202.814 -346.064)"
          fill="#0c6bc2"
        />
        <path
          id="Path_4035"
          data-name="Path 4035"
          d="M-577.944-1604.166c2.519,0,5.038.017,7.557-.012.546-.006.681.178.657.683a1.349,1.349,0,0,1-1.459,1.437c-2.1.024-4.2,0-6.3.014-.393,0-.493-.166-.48-.52.019-.53.005-1.061.006-1.591Z"
          transform="translate(-169.009 -324.327)"
          fill="#91c3fc"
        />
        <path
          id="Path_4036"
          data-name="Path 4036"
          d="M-573.863-1535.336c1.17,0,2.341.021,3.51-.01.481-.012.607.169.587.616a1.265,1.265,0,0,1-1.382,1.385c-2.075,0-4.151-.015-6.226.011-.5.006-.637-.155-.632-.643.012-1.358-.009-1.359,1.361-1.359Z"
          transform="translate(-168.975 -384.005)"
          fill="#91c3fc"
        />
      </g>
    </svg>
  );
}
export default CostEffectiveIcon;
