import * as React from "react";

const BoxContainer = ({ children, background = "bg-blue", className = "" }) => {
  return (
    <div
      className={`flex flex-col w-full ${background && background} ${
        className ? className : ` py-10 px-8`
      } `}
    >
      <div className="inner-conatiner p-0 px-5 md:px-0">{children}</div>
    </div>
  );
};

export default BoxContainer;
