import React from "react";

const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 10.712 20"
  >
    <defs>{/* <style>.a{fill="#c5c5c5"}</style> */}</defs>
    <path
      className="a"
      // fill="#c5c5c5"
      d="M11.619,11.25l.555-3.62H8.7V5.282a1.81,1.81,0,0,1,2.041-1.955h1.579V.245A19.255,19.255,0,0,0,9.518,0c-2.86,0-4.73,1.734-4.73,4.872V7.63H1.609v3.62H4.789V20H8.7V11.25Z"
      transform="translate(-1.609)"
    />
  </svg>
);

export default FacebookIcon;
