import React from "react";

const MailFooterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.402"
    height="10.663"
    viewBox="0 0 15.402 10.663"
  >
    <defs>{/* <style>.a{fill="#3a7aff"}</style> */}</defs>
    <g transform="translate(-3.375 -7.875)">
      <path
        className="a"
        // fill="#c5c5c5"
        d="M18.651,10.337l-3.984,4.058a.072.072,0,0,0,0,.1l2.788,2.969a.48.48,0,0,1,0,.681.483.483,0,0,1-.681,0L14,15.19a.076.076,0,0,0-.107,0l-.678.689a2.981,2.981,0,0,1-2.125.9,3.041,3.041,0,0,1-2.17-.922l-.652-.663a.076.076,0,0,0-.107,0L5.382,18.148a.483.483,0,0,1-.681,0,.48.48,0,0,1,0-.681L7.488,14.5a.079.079,0,0,0,0-.1L3.5,10.337a.073.073,0,0,0-.126.052v8.119A1.188,1.188,0,0,0,4.56,19.692H17.592a1.188,1.188,0,0,0,1.185-1.185V10.388A.074.074,0,0,0,18.651,10.337Z"
        transform="translate(0 -1.155)"
      />
      <path
        className="a"
        d="M11.479,14.654a2.013,2.013,0,0,0,1.448-.607l5.809-5.913A1.164,1.164,0,0,0,18,7.875H4.959a1.156,1.156,0,0,0-.733.259l5.809,5.913A2.013,2.013,0,0,0,11.479,14.654Z"
        transform="translate(-0.403)"
      />
    </g>
  </svg>
);

export default MailFooterIcon;
