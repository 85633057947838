import React from "react";

function SecurityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3403"
        data-name="Group 3403"
        transform="translate(677 -2424)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-677 2424)"
          fill="#ff7b16"
        />
        <path
          id="Icon_material-security"
          data-name="Icon material-security"
          d="M12.729,1.5,4.5,5.157v5.486c0,5.075,3.511,9.82,8.229,10.972,4.718-1.152,8.229-5.9,8.229-10.972V5.157Zm0,10.049h6.4a9.788,9.788,0,0,1-6.4,8.174V11.558h-6.4V6.346l6.4-2.844Z"
          transform="translate(-671.729 2430.442)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SecurityIcon;
