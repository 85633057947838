import React from "react";

function ContractIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      viewBox="0 0 66 66"
    >
      <g
        id="Group_3400"
        data-name="Group 3400"
        transform="translate(670 -2326)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="66"
          height="66"
          rx="33"
          transform="translate(-670 2326)"
          fill="#ff7b16"
        />
        <path
          id="Icon_awesome-file-contract"
          data-name="Icon awesome-file-contract"
          d="M15.75,9.563V0H1.688A1.683,1.683,0,0,0,0,1.688V34.313A1.683,1.683,0,0,0,1.688,36H25.313A1.683,1.683,0,0,0,27,34.313V11.25H17.438A1.692,1.692,0,0,1,15.75,9.563ZM4.5,5.063A.562.562,0,0,1,5.063,4.5h5.625a.562.562,0,0,1,.563.563V6.188a.562.562,0,0,1-.562.563H5.063A.562.562,0,0,1,4.5,6.188Zm0,4.5A.562.562,0,0,1,5.063,9h5.625a.562.562,0,0,1,.563.563v1.125a.562.562,0,0,1-.562.563H5.063a.562.562,0,0,1-.562-.562ZM18.057,27h3.318a1.125,1.125,0,0,1,0,2.25H18.057a3.023,3.023,0,0,1-2.717-1.678.779.779,0,0,0-.715-.458.767.767,0,0,0-.7.435l-.539,1.079a1.124,1.124,0,0,1-1.006.622c-.027,0-.053,0-.08,0a1.121,1.121,0,0,1-.986-.766l-1.183-3.548-.746,2.242A3.036,3.036,0,0,1,6.5,29.25H5.625a1.125,1.125,0,0,1,0-2.25H6.5a.788.788,0,0,0,.748-.539L8.523,22.62a1.688,1.688,0,0,1,3.2,0l.976,2.928a3.155,3.155,0,0,1,4.641,1A.8.8,0,0,0,18.057,27ZM26.508,7.383,19.624.492A1.686,1.686,0,0,0,18.429,0H18V9h9V8.571A1.682,1.682,0,0,0,26.508,7.383Z"
          transform="translate(-650 2341)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default ContractIcon;
