import React from "react";

function DataRetentionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      viewBox="0 0 65 65"
    >
      <g
        id="Group_3849"
        data-name="Group 3849"
        transform="translate(-173 -2452)"
      >
        <path
          id="Path_3464"
          data-name="Path 3464"
          d="M32.5,0A32.5,32.5,0,1,1,0,32.5,32.5,32.5,0,0,1,32.5,0Z"
          transform="translate(173 2452)"
          fill="#ff7b16"
        />
        <path
          id="Path_4180"
          data-name="Path 4180"
          d="M2850.6,1742.2a16.005,16.005,0,0,1,.067-2.34,2.156,2.156,0,0,1,2.324-1.711,2.185,2.185,0,0,1,1.971,2.115c.014.623,0,1.247,0,1.926a10.741,10.741,0,0,1-1.165-.462,2,2,0,0,0-2.031,0A10.815,10.815,0,0,1,2850.6,1742.2Z"
          transform="translate(-2651.942 738.44)"
          fill="#fff"
        />
        <g id="Group_3848" data-name="Group 3848">
          <g
            id="Group_3847"
            data-name="Group 3847"
            transform="translate(-0.944 -0.945)"
          >
            <path
              id="Path_4178"
              data-name="Path 4178"
              d="M2778.619,1661.654a1.774,1.774,0,0,0-.41-1.183q-2.237-2.769-4.447-5.559a1.487,1.487,0,0,0-1.266-.607q-8.046.014-16.092.006c-.981,0-1.414.433-1.414,1.416v30.229c0,1,.43,1.429,1.441,1.429h20.217c-.02-.052-.02-.066-.026-.07-.04-.029-.081-.056-.122-.084a8.253,8.253,0,0,1,1.767-14.733.432.432,0,0,0,.354-.508Q2778.6,1666.822,2778.619,1661.654Zm-9.266,18.011c-.553.505-1.133.982-1.709,1.462a1.177,1.177,0,0,1-1.693-.006,20.18,20.18,0,0,1-3.777-3.761,13.033,13.033,0,0,1-2.458-7.654,1.154,1.154,0,0,1,.769-1.145c.429-.179.858-.365,1.3-.512a.387.387,0,0,0,.3-.454c-.016-.885-.011-1.771-.005-2.656a4.724,4.724,0,1,1,9.448.009c0,.406,0,.812,0,1.217,0,.5.01,1.009-.005,1.512a.3.3,0,0,0,.24.342c.426.155.846.327,1.265.5a1.209,1.209,0,0,1,.861,1.307A13.18,13.18,0,0,1,2769.353,1679.665Z"
              transform="translate(-2565.045 814.64)"
              fill="#fff"
            />
            <path
              id="Path_4179"
              data-name="Path 4179"
              d="M2953.654,1845.919a7.087,7.087,0,1,0,7.068,7.1A7.1,7.1,0,0,0,2953.654,1845.919Zm1.162,5.336v1.695a1.179,1.179,0,0,1-.687,1.128c-.765.387-1.529.775-2.3,1.146a1.179,1.179,0,1,1-1.041-2.114c.479-.246.961-.487,1.448-.717a.343.343,0,0,0,.222-.367c-.01-.823-.007-1.646,0-2.468a1.184,1.184,0,1,1,2.361,0C2954.819,1850.125,2954.817,1850.69,2954.817,1851.255Z"
              transform="translate(-2737.705 641.931)"
              fill="#fff"
            />
          </g>
          <path
            id="Path_4181"
            data-name="Path 4181"
            d="M2862.721,1823.608c0-.2-.011-.408,0-.611a1.087,1.087,0,0,1,2.168-.016,11.974,11.974,0,0,1,0,1.256,1.084,1.084,0,0,1-1.11,1.015,1.1,1.1,0,0,1-1.061-1.033C2862.712,1824.016,2862.721,1823.812,2862.721,1823.608Z"
            transform="translate(-2662.961 662.234)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default DataRetentionIcon;
