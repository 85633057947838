import React from "react";

function LawEnforcementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <g
        id="Group_3838"
        data-name="Group 3838"
        transform="translate(-333 -4442)"
      >
        <path
          id="Path_4119"
          data-name="Path 4119"
          d="M155.206-2597.276a25.06,25.06,0,0,0-25.009,25.017,25.048,25.048,0,0,0,24.99,24.983,25.061,25.061,0,0,0,25.009-25.017A25.048,25.048,0,0,0,155.206-2597.276Zm0,47.706a20.155,20.155,0,0,1-3.743-.3,21.942,21.942,0,0,1-10.652-4.854,22.469,22.469,0,0,1-7.783-12.593,23.347,23.347,0,0,1-.541-4.2c0-.22,0-.4,0-.573,0-.763.415-1.244,1.066-1.247a1.071,1.071,0,0,1,1.077,1.183,23.078,23.078,0,0,0,.393,3.735,19.958,19.958,0,0,0,3.258,7.792,20.468,20.468,0,0,0,8.611,7.142,20.9,20.9,0,0,0,7.684,1.764c.23.01.46,0,.688.029a1.052,1.052,0,0,1,1,1.091A1.044,1.044,0,0,1,155.2-2549.57Zm3.551-44.1c-.055.63-.588,1.006-1.344.948l-.264-.021v-.006c-.635-.031-1.27-.056-1.9-.1a1.068,1.068,0,0,1-1.11-1.1,1.074,1.074,0,0,1,1.16-1.046,20.929,20.929,0,0,1,2.274.115C158.34-2594.806,158.812-2594.336,158.754-2593.673Zm18.116,22.462a1.043,1.043,0,0,1-1.1-1.052,26.249,26.249,0,0,0-.507-4.468,20.241,20.241,0,0,0-7.453-11.787,21.448,21.448,0,0,0-4.937-2.839c-.624-.263-.894-.652-.851-1.138a1.083,1.083,0,0,1,1.561-.9,21.822,21.822,0,0,1,4.556,2.444,22.671,22.671,0,0,1,8.678,11.694,22.537,22.537,0,0,1,1.088,6.185c0,.247.007.5,0,.742A1.069,1.069,0,0,1,176.87-2571.211Z"
          transform="translate(202.803 7039.276)"
          fill="#ff7b16"
        />
        <g
          id="Group_3837"
          data-name="Group 3837"
          transform="translate(13 2232)"
        >
          <g
            id="Group_3483"
            data-name="Group 3483"
            transform="translate(336 2223)"
          >
            <path
              id="Path_3451"
              data-name="Path 3451"
              d="M-466.569,521.074v-1.6h7.413a2.275,2.275,0,0,0,.251,0,.38.38,0,0,0,.358-.408.374.374,0,0,0-.353-.387,1.656,1.656,0,0,0-.226,0h-13.309a1.551,1.551,0,0,0-.226.005.368.368,0,0,0-.346.392.363.363,0,0,0,.338.4,3.473,3.473,0,0,0,.443,0c0,.537,0,1.059,0,1.581a2.028,2.028,0,0,1-2.383-1.769,2.024,2.024,0,0,1,1.9-2.214c.084,0,.167,0,.251,0h15.519v.712c0,.686,0,1.372,0,2.059a1.2,1.2,0,0,1-1.205,1.244q-4.143.008-8.287,0C-466.47,521.082-466.511,521.078-466.569,521.074Z"
              transform="translate(474.621 -498.583)"
              fill="#fff"
            />
            <path
              id="Path_3452"
              data-name="Path 3452"
              d="M-424.682,555.008c-.427.43-.828.831-1.226,1.236-.148.151-.3.27-.529.178s-.256-.279-.255-.495c0-.885,0-1.77,0-2.672h4.018v.516c0,.719,0,1.438,0,2.156,0,.216-.036.4-.259.493a.438.438,0,0,1-.509-.165C-423.842,555.847-424.25,555.441-424.682,555.008Z"
              transform="translate(429.907 -532.346)"
              fill="#fff"
            />
            <path
              id="Path_3453"
              data-name="Path 3453"
              d="M-456.887,242.948a1.228,1.228,0,0,0-1.373-1.381H-472.4a2.018,2.018,0,0,0-2.168,2.16q0,8.035,0,16.071v.26a.491.491,0,0,0,.09-.045,2.962,2.962,0,0,1,2.11-.772q7.572.012,15.144,0h.336v-.3Q-456.887,250.946-456.887,242.948Zm-6.876,13.083c-1.163,0-2.326,0-3.489,0-.159,0-.318,0-.477,0a.379.379,0,0,1-.42-.394.383.383,0,0,1,.413-.405c.493-.008.987,0,1.481,0,.032,0,.064-.006.121-.013v-6h.8v6.016h1.572a.4.4,0,0,1,.443.292C-463.24,255.809-463.431,256.031-463.764,256.031Zm3.215-3.185a1.942,1.942,0,0,1-2.178,1.572,1.9,1.9,0,0,1-1.79-1.883,1.008,1.008,0,0,1,.089-.408c.431-.987.87-1.97,1.31-2.96-.58-.219-1.14-.463-1.719-.641a3.524,3.524,0,0,0-2.515.25c-.313.138-.632.26-.984.4.393.883.733,1.806,1.2,2.658a1.861,1.861,0,0,1-1.224,2.523,1.986,1.986,0,0,1-2.56-1.614,1.249,1.249,0,0,1,.079-.652c.379-.9.786-1.79,1.183-2.683.037-.082.07-.166.1-.236-.27-.141-.533-.263-.779-.412a.4.4,0,0,1-.2-.473.389.389,0,0,1,.6-.225,1.75,1.75,0,0,0,1.786.169,5.873,5.873,0,0,1,2.021-.616c0-.273,0-.521,0-.769,0-.291.141-.453.383-.459s.411.159.417.455c0,.234,0,.468.005.7a.354.354,0,0,0,.031.078,6.538,6.538,0,0,1,2.1.651,1.683,1.683,0,0,0,1.646-.194.376.376,0,0,1,.539.055.37.37,0,0,1-.021.541,2.641,2.641,0,0,1-.5.323,3.144,3.144,0,0,1-.387.151l.756,1.7c.169.382.326.77.512,1.144A1.269,1.269,0,0,1-460.549,252.846Z"
              transform="translate(474.571 -241.567)"
              fill="#fff"
            />
            <path
              id="Path_3454"
              data-name="Path 3454"
              d="M-404.3,364.1l.979,2.207h-1.956Z"
              transform="translate(409.929 -355.882)"
              fill="#fff"
            />
            <path
              id="Path_3455"
              data-name="Path 3455"
              d="M-308.558,364.153l.98,2.207h-1.957Z"
              transform="translate(320.61 -355.929)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default LawEnforcementIcon;
