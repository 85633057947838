import React from "react";
import Availability from "../assets/svg/24-7-availability";
import GuaranteeIcon from "../assets/svg/GuaranteeIcon";
import BusinessCustomersIcon from "../assets/svg/95000-business-customers";
import RapidDelivery from "../assets/svg/RapidDelivery";
import DownArrowHead from "../assets/svg/DownArrowHead";
import LogoFooterIcon from "../assets/svg/LogoFooterIcon";
import LocationFooterIcon from "../assets/svg/LocationFooterIcon";
import PhoneFooterIcon from "../assets/svg/PhoneFooterIcon";
import MailFooterIcon from "../assets/svg/MailFooterIcon";
import FacebookIcon from "../assets/svg/FacebookIcon";
import TwitterIcon from "../assets/svg/TwitterIcon";
import LinkedInIcon from "../assets/svg/LinkedInIcon";
import YoutubeIcon from "../assets/svg/YoutubeIcon";
import ContractIcon from "../assets/svg/contract";
import TranslateIcon from "../assets/svg/translate";
import LegalIcon from "../assets/svg/legal";
import NativeIcon from "../assets/svg/native";
import SecurityIcon from "../assets/svg/security";
import InstagramIcon from "../assets/svg/instagramIcon";
import LegalTranscriptionLogo from "../assets/svg/LegalTranscriptionLogo";
import ArtbitrationIcon from "../assets/svg/ArtbitrationIcon";
import CourtIcon from "../assets/svg/CourtIcon";
import DepositionIcon from "../assets/svg/DepositionIcon";
import LawEnforcementIcon from "../assets/svg/LawEnforcementIcon";
import QualityGuaranteedIcon from "../assets/svg/legal-transcription/QualityGuaranteedIcon";
import ExpertHumanIcon from "../assets/svg/legal-transcription/ExpertHumanIcon";
import FastDeliveryIconTwo from "../assets/svg/legal-transcription/FastDeliveryIconTwo";
import SecureIcon from "../assets/svg/legal-transcription/SecureIcon";
import RatingIcon from "../assets/svg/legal-transcription/RatingIcon";
import ArrowCircleDownWhite from "../assets/svg/legal-transcription/ArrowCircleDownWhite";
import LockWhiteIcon from "../assets/svg/legal-transcription/LockWhiteIcon";
import CostEffectiveIcon from "../assets/svg/CostEffectiveIcon";
import FastDeliveryIconThree from "../assets/svg/FastDeliveryIconThree";
import CollaborationIcon from "../assets/svg/CollaborationIcon";
import LawEnforcementIconTwo from "../assets/svg/LawEnforcementIconTwo";
import SecurityIconLarge from "../assets/svg/SecurityIconLarge";
import EncryptionIcon from "../assets/svg/EncryptionIcon";
import DataRetentionIcon from "../assets/svg/DataRetentionIcon";
import SecurityManagementIcon from "../assets/svg/SecurityManagementIcon";
import ConfidentialityIcon from "../assets/svg/ConfidentialityIcon";
import UnauthorizedIcon from "../assets/svg/UnauthorizedIcon";
import TechnicalControlsIcon from "../assets/svg/TechnicalControlsIcon";

export default function (icon) {
  if (icon === "availability") {
    return <Availability />;
  } else if (icon === "guarantee") {
    return <GuaranteeIcon />;
  } else if (icon === "business-customers") {
    return <BusinessCustomersIcon />;
  } else if (icon === "rapid-delivery") {
    return <RapidDelivery />;
  } else if (icon === "down-arrow-head") {
    return <DownArrowHead />;
  } else if (icon === "logo-footer") {
    return <LogoFooterIcon />;
  } else if (icon === "location-footer") {
    return <LocationFooterIcon />;
  } else if (icon === "phone-footer") {
    return <PhoneFooterIcon />;
  } else if (icon === "mail-footer") {
    return <MailFooterIcon />;
  } else if (icon === "facebook") {
    return <FacebookIcon />;
  } else if (icon === "twitter") {
    return <TwitterIcon />;
  } else if (icon === "linkedin") {
    return <LinkedInIcon />;
  } else if (icon === "youtube") {
    return <YoutubeIcon />;
  } else if (icon === "instagram") {
    return <InstagramIcon />;
  } else if (icon === "contract") {
    return <ContractIcon />;
  } else if (icon === "translate") {
    return <TranslateIcon />;
  } else if (icon === "legal") {
    return <LegalIcon />;
  } else if (icon === "native") {
    return <NativeIcon />;
  } else if (icon === "security") {
    return <SecurityIcon />;
  } else if (icon === "legal-transcription-logo") {
    return <LegalTranscriptionLogo />;
  } else if (icon === "arbitration") {
    return <ArtbitrationIcon />;
  } else if (icon === "court") {
    return <CourtIcon />;
  } else if (icon === "deposition") {
    return <DepositionIcon />;
  } else if (icon === "law-enforcement") {
    return <LawEnforcementIcon />;
  } else if (icon === "quality-guaranteed") {
    return <QualityGuaranteedIcon />;
  } else if (icon === "expert-human") {
    return <ExpertHumanIcon />;
  } else if (icon === "fast-delivery-two") {
    return <FastDeliveryIconTwo />;
  } else if (icon === "secure") {
    return <SecureIcon />;
  } else if (icon === "rating") {
    return <RatingIcon />;
  } else if (icon === "arrow-circle-down-white") {
    return <ArrowCircleDownWhite />;
  } else if (icon === "lock-white") {
    return <LockWhiteIcon />;
  } else if (icon === "cost-effective") {
    return <CostEffectiveIcon />;
  } else if (icon === "fast-delivery-three") {
    return <FastDeliveryIconThree />;
  } else if (icon === "collaboration") {
    return <CollaborationIcon />;
  } else if (icon === "law-enforcement-two") {
    return <LawEnforcementIconTwo />;
  } else if (icon === "security-large") {
    return <SecurityIconLarge />;
  } else if (icon === "encryption") {
    return <EncryptionIcon />;
  } else if (icon === "data-retention") {
    return <DataRetentionIcon />;
  } else if (icon === "security-management") {
    return <SecurityManagementIcon />;
  } else if (icon === "confidentiality") {
    return <ConfidentialityIcon />;
  } else if (icon === "unauthorized") {
    return <UnauthorizedIcon />;
  } else if (icon === "technical-controls") {
    return <TechnicalControlsIcon />;
  }
  return;
}
