import React from "react";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";
import { Link } from "gatsby";

const Footer = ({ data }) => {
  const { dataType = 0 } = data;
  return (
    <div className="bg-[#000A1A] px-5 2xl:px-0 pt-9 pb-6">
      <div className="max-w-6xl mx-auto flex 0px:flex-col sm:flex-row justify-between items-center gap-4 sm:gap-0">
        <Text as="p" className="text-xxs text-slate font-opensans">
          {dataType === 1 ? data.title : textResolver(data, "title")}
        </Text>
        <Link
          to="/security-and-confidentiality"
          className="text-xxs text-slate font-opensans hover:text-yellow"
        >
          Security & Confidentiality
        </Link>
      </div>
    </div>
  );
};

export default Footer;
