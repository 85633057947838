import React, { useCallback, useRef } from "react";
import useOnClickOutside from "../../hooks/useClickOutSide.hooks";

const Modal = ({
  visible = true,
  showCloseIcon = true,
  onClick,
  className,
  children,
}) => {
  const onCloseCallBack = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
    },
    [visible]
  );
  const ref = useRef(null);
  useOnClickOutside(ref, (event) => {
    if (onClick) {
      onClick(event);
    }
  });
  if (!visible) {
    return null;
  }
  const defaultClass = "w-auto h-auto py-6 px-6 bg-white";
  return (
    <div className="w-screen fixed h-screen top-0 left-0 z-50 duration-1000 ease-out flex justify-center items-center">
      <div className="fixed inset-0 bg-gray-800 opacity-80"></div>
      <div
        ref={ref}
        className={`relative rounded-[10px] flex flex-col ${
          className ? className : defaultClass
        }`}
      >
        {showCloseIcon && (
          <div className="absolute w-[29px] h-[29px] right-5 top-5 cursor-pointer z-[999]">
            <svg
              onClick={onCloseCallBack}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1000 1000"
              width="29px"
              height="29px"
            >
              <g>
                <path d="M500,990C229.9,990,10,770.2,10,500C10,229.8,229.9,10,500,10c270.3,0,490,219.8,490,490C990,770.2,770.3,990,500,990L500,990z M500,92.7C275.4,92.7,92.7,275.4,92.7,500c0,224.6,182.7,407.3,407.3,407.3c224.6,0,407.3-182.7,407.3-407.3C907.3,275.4,724.6,92.7,500,92.7L500,92.7z" />
                <path d="M699.7,252.1l54.8,54.8l-433,433l-54.8-54.8L699.7,252.1z" />
                <path d="M754.6,685.1L699.7,740l-433-433l54.8-54.8L754.6,685.1z" />
              </g>
            </svg>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
