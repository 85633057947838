import React from "react";

function TranslateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      viewBox="0 0 66 66"
    >
      <g
        id="Group_3404"
        data-name="Group 3404"
        transform="translate(-100 -2326)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="66"
          height="66"
          rx="33"
          transform="translate(100 2326)"
          fill="#ff7b16"
        />
        <path
          id="Icon_material-translate"
          data-name="Icon material-translate"
          d="M21.256,24.753l-4.227-4.177.05-.05A29.157,29.157,0,0,0,23.253,9.657h4.876V6.329H16.479V3H13.15V6.329H1.5V9.641H20.09a26.151,26.151,0,0,1-5.276,8.921,26.039,26.039,0,0,1-3.845-5.575H7.641a29.229,29.229,0,0,0,4.96,7.589L4.13,28.93l2.363,2.363,8.322-8.322,5.176,5.176,1.265-3.4Zm9.37-8.438H27.3L19.808,36.286h3.329L25,31.293h7.906l1.881,4.993h3.329Zm-4.361,11.65,2.7-7.207,2.7,7.207Z"
          transform="translate(113.5 2339)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default TranslateIcon;
