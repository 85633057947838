import React from "react";

const LinkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.476"
    height="19.476"
    viewBox="0 0 19.476 19.476"
  >
    <defs>{/* <style>.a{fill="#c5c5c5"}</style> */}</defs>
    <path
      className="a"
      // fill="#c5c5c5"
      d="M4.36,19.476H.322v-13H4.36ZM2.338,4.7A2.35,2.35,0,1,1,4.677,2.339,2.358,2.358,0,0,1,2.338,4.7ZM19.472,19.476H15.443v-6.33c0-1.509-.03-3.443-2.1-3.443-2.1,0-2.421,1.639-2.421,3.334v6.438H6.889v-13h3.873V8.247h.057a4.243,4.243,0,0,1,3.82-2.1c4.086,0,4.838,2.691,4.838,6.186v7.143Z"
      transform="translate(0 0)"
    />
  </svg>
);

export default LinkedInIcon;
