import React from "react";

function FastDeliveryIconTwo() {
  return (
    <svg
      id="Group_3653"
      data-name="Group 3653"
      xmlns="http://www.w3.org/2000/svg"
      width="29.068"
      height="27.912"
      viewBox="0 0 29.068 27.912"
    >
      <path
        id="Path_3635"
        data-name="Path 3635"
        d="M743.187,693.555a.6.6,0,0,1-.287.88,11.475,11.475,0,0,1-16.727-5.328.4.4,0,0,0-.448-.3c-1.163.014-2.327.007-3.491.006a2.008,2.008,0,0,1-.339-.019.556.556,0,0,1-.016-1.09,1.7,1.7,0,0,1,.338-.024c1.06,0,2.119-.007,3.179,0,.224,0,.3-.04.233-.275a7.841,7.841,0,0,1-.272-1.7c-.009-.182-.079-.213-.242-.211-.738.008-1.476,0-2.214,0-.41,0-.668-.225-.666-.57s.26-.563.673-.564c.728,0,1.457-.006,2.185,0,.169,0,.224-.038.232-.212a9.434,9.434,0,0,1,.237-1.8c.057-.234-.1-.17-.208-.17q-2.7,0-5.393,0a1.671,1.671,0,0,1-.366-.031.556.556,0,0,1,.065-1.094,2.707,2.707,0,0,1,.312-.009c1.892,0,3.784,0,5.676,0a.315.315,0,0,0,.359-.253,10.176,10.176,0,0,1,.789-1.72c.035-.062.1-.116.073-.223h-2.854c-.132,0-.265,0-.4,0a.565.565,0,1,1-.017-1.131c.189-.012.378,0,.568,0,1.088,0,2.176,0,3.264,0a.484.484,0,0,0,.427-.2,11.232,11.232,0,0,1,6.153-3.9c.193-.047.259-.124.246-.323a8.561,8.561,0,0,1,0-.908.309.309,0,0,0-.238-.365,1.92,1.92,0,0,1,.177-3.644,2.053,2.053,0,0,1,.53-.078c1.362-.006,2.725-.019,4.087,0a1.93,1.93,0,0,1,1.412,3.227,1.611,1.611,0,0,1-.771.509c-.156.046-.2.125-.191.281a8.63,8.63,0,0,1,0,.964c-.015.216.071.283.264.331a11.207,11.207,0,0,1,6.259,4.042,11.371,11.371,0,0,1,2.395,5.9c.007.058-.033.137.058.169v.17c-.041.038-.026.089-.029.135v.145c.008.058-.021.122.029.174v.284a.97.97,0,0,0,0,.511v.227a.13.13,0,0,0,0,.17v.227a7.03,7.03,0,0,0-.211,1.147,11.569,11.569,0,0,1-1.562,3.945.593.593,0,0,1-.859.283.493.493,0,0,1-.307-.609,1.523,1.523,0,0,1,.212-.431,9.923,9.923,0,0,0,1.441-4.205,10.152,10.152,0,0,0-1.568-6.713,10.244,10.244,0,0,0-18.64,3.417,10.167,10.167,0,0,0,.831,6.683,9.859,9.859,0,0,0,1.658,2.393,10,10,0,0,0,3.833,2.607,10.387,10.387,0,0,0,3.234.672,10.133,10.133,0,0,0,5.938-1.547C742.66,693.16,742.977,693.208,743.187,693.555Z"
        transform="translate(-719.17 -668.298)"
        fill="#00173a"
      />
      <path
        id="Path_3636"
        data-name="Path 3636"
        d="M1101.449,884.233a.513.513,0,0,1,0-.511Z"
        transform="translate(-1072.381 -867.377)"
        fill="#0f0f0f"
      />
      <path
        id="Path_3637"
        data-name="Path 3637"
        d="M1101.606,875.424v-.171c.019-.022.035-.021.05,0v.17C1101.638,875.453,1101.621,875.452,1101.606,875.424Z"
        transform="translate(-1072.589 -859.536)"
        fill="#4d4d4d"
      />
      <path
        id="Path_3638"
        data-name="Path 3638"
        d="M1101.543,877.695a.45.45,0,0,0,.051,0v.17C1101.525,877.826,1101.537,877.76,1101.543,877.695Z"
        transform="translate(-1072.526 -861.807)"
        fill="#303030"
      />
      <path
        id="Path_3639"
        data-name="Path 3639"
        d="M1101.479,893.619c-.1-.057-.078-.114,0-.17Z"
        transform="translate(-1072.41 -876.366)"
        fill="#6a6a6a"
      />
      <path
        id="Path_3640"
        data-name="Path 3640"
        d="M1101.617,874.109a.253.253,0,0,1-.05,0c0-.046-.019-.1.05-.11Z"
        transform="translate(-1072.549 -858.388)"
        fill="#7e7e7e"
      />
      <path
        id="Path_3641"
        data-name="Path 3641"
        d="M831.787,767.911a.607.607,0,0,0-.91-.069,10.3,10.3,0,1,1,3.234-3.233.593.593,0,0,0,.095.9l-.727.876c-.088.031-.165-.019-.248-.033-.465-.078-.731.227-.587.674.02.061.066.117.029.187Z"
        transform="translate(-807.769 -742.654)"
        fill="#c8e2ff"
      />
      <path
        id="Path_3642"
        data-name="Path 3642"
        d="M720.307,924.314a.567.567,0,0,1-1.133.013.567.567,0,0,1,1.133-.013Z"
        transform="translate(-719.174 -904.373)"
        fill="#00173a"
      />
      <path
        id="Path_3643"
        data-name="Path 3643"
        d="M1045.49,980.233a.587.587,0,0,1-.009-.791c.2-.219.473-.231.815-.037.219.349.215.617-.015.832A.594.594,0,0,1,1045.49,980.233Z"
        transform="translate(-1020.585 -955.674)"
        fill="#00173a"
      />
      <path
        id="Path_3644"
        data-name="Path 3644"
        d="M916.494,684.893c-.652,0-1.3,0-1.956,0a.787.787,0,0,1-.865-.774.8.8,0,0,1,.862-.812q1.97-.009,3.941,0a.8.8,0,1,1,0,1.586C917.817,684.9,917.155,684.893,916.494,684.893Z"
        transform="translate(-898.915 -682.162)"
        fill="#ff7b16"
      />
      <path
        id="Path_3645"
        data-name="Path 3645"
        d="M934.309,719.208c.387,0,.774.006,1.161,0,.145,0,.2.034.195.188-.011.283-.009.566,0,.849,0,.126-.037.163-.161.149a11.012,11.012,0,0,0-2.429,0c-.133.015-.159-.038-.157-.156.006-.283.01-.566,0-.849-.006-.163.07-.183.2-.181C933.516,719.213,933.913,719.208,934.309,719.208Z"
        transform="translate(-916.697 -715.343)"
        fill="#d4f4fa"
      />
      <path
        id="Path_3646"
        data-name="Path 3646"
        d="M843.914,769.012a8.806,8.806,0,1,1-8.816,8.762A8.8,8.8,0,0,1,843.914,769.012Z"
        transform="translate(-826.303 -761.37)"
        fill="#00173a"
      />
      <path
        id="Path_3647"
        data-name="Path 3647"
        d="M857.736,783.977a7.671,7.671,0,1,1-7.675,7.654A7.667,7.667,0,0,1,857.736,783.977Z"
        transform="translate(-840.13 -775.2)"
        fill="#fefefe"
      />
      <path
        id="Path_3648"
        data-name="Path 3648"
        d="M912.746,829.448a.736.736,0,0,1,.067-.418c.166-.283.02-.438-.173-.616-.292-.269-.565-.558-.845-.839-.346-.347-.388-.673-.121-.933s.59-.2.915.122.673.664,1,1a.245.245,0,0,0,.315.068,1.607,1.607,0,0,1,1.19-.005.285.285,0,0,0,.359-.09q1.3-1.308,2.605-2.607a.566.566,0,0,1,.719-.131.547.547,0,0,1,.274.6.724.724,0,0,1-.256.4c-.842.842-1.681,1.687-2.53,2.523a.334.334,0,0,0-.094.409,1.757,1.757,0,0,1-2.384,2.157A1.747,1.747,0,0,1,912.746,829.448Z"
        transform="translate(-896.907 -813.044)"
        fill="#00173a"
      />
      <path
        id="Path_3649"
        data-name="Path 3649"
        d="M944.776,798.457c0,.085,0,.17,0,.254a.557.557,0,0,1-.553.561.564.564,0,0,1-.576-.542,4.355,4.355,0,0,1,0-.537.563.563,0,0,1,.573-.573.554.554,0,0,1,.56.582C944.779,798.287,944.776,798.372,944.776,798.457Z"
        transform="translate(-926.605 -787.808)"
        fill="#00173a"
      />
      <path
        id="Path_3650"
        data-name="Path 3650"
        d="M1017.669,877.671c.056,0,.113,0,.17,0a.577.577,0,0,1,.636.568.588.588,0,0,1-.643.567,4.5,4.5,0,0,1-.48,0,.556.556,0,0,1-.53-.584.551.551,0,0,1,.566-.545C1017.48,877.668,1017.575,877.671,1017.669,877.671Z"
        transform="translate(-994.236 -861.784)"
        fill="#00173a"
      />
      <path
        id="Path_3651"
        data-name="Path 3651"
        d="M943.589,951.437c0-.085,0-.17,0-.254a.565.565,0,0,1,1.129-.019,4.021,4.021,0,0,1,0,.565.566.566,0,1,1-1.13-.037C943.586,951.607,943.589,951.522,943.589,951.437Z"
        transform="translate(-926.56 -929.198)"
        fill="#00173a"
      />
      <path
        id="Path_3652"
        data-name="Path 3652"
        d="M864.469,877.541a2.881,2.881,0,0,1,.338,0,.558.558,0,0,1,.059,1.094,2.044,2.044,0,0,1-.758.01.558.558,0,0,1,.05-1.108c.1-.01.207,0,.311,0Z"
        transform="translate(-852.673 -861.659)"
        fill="#00173a"
      />
      <path
        id="Path_3653"
        data-name="Path 3653"
        d="M943.5,876.845a.619.619,0,0,1,.64.615.625.625,0,0,1-1.25.028A.618.618,0,0,1,943.5,876.845Z"
        transform="translate(-925.919 -861.021)"
        fill="#ff7b16"
      />
    </svg>
  );
}
export default FastDeliveryIconTwo;
