import getCookie from "./getCookie";

export default function formCommonPostData() {
  const data = {
    source: getCookie("utm_source"),
    utm_campaign: getCookie("utm_campaign"),
    utm_agid: getCookie("utm_agid"),
    utm_term: getCookie("utm_term"),
    creative: getCookie("creative"),
    device: getCookie("device"),
    placement: getCookie("placement"),
    utm_medium: getCookie("utm_medium"),
  };
  return data;
}
