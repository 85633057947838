import React from "react";

function TechnicalControlsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      viewBox="0 0 65 65"
    >
      <g
        id="Group_3859"
        data-name="Group 3859"
        transform="translate(-861 -3341)"
      >
        <path
          id="Path_3464"
          data-name="Path 3464"
          d="M32.5,0A32.5,32.5,0,1,1,0,32.5,32.5,32.5,0,0,1,32.5,0Z"
          transform="translate(861 3341)"
          fill="#ff7b16"
        />
        <g
          id="Group_3858"
          data-name="Group 3858"
          transform="translate(415.961 1460.998)"
        >
          <path
            id="Path_4192"
            data-name="Path 4192"
            d="M461.039,2007.684v-20.05a.759.759,0,0,1,.537-.1q3.377,0,6.755,0H494.1c.714,0,.714,0,.714.708q0,9.531,0,19.061c0,.881-.377,1.271-1.256,1.274-2,.006-4,0-6,0a2.066,2.066,0,0,1-.3-.015.261.261,0,0,1-.244-.35,1.541,1.541,0,0,1,.051-.191,10.9,10.9,0,0,0,.5-3.442c-.025-2.143-.006-4.287-.008-6.43a2.771,2.771,0,0,0-1.809-2.589c-2.22-.8-4.453-1.572-6.671-2.384a3.353,3.353,0,0,0-2.409.028c-2.134.784-4.274,1.551-6.426,2.283a2.969,2.969,0,0,0-2,2.767c.039,2.132.028,4.265,0,6.4a10.837,10.837,0,0,0,.489,3.344c.146.474.073.579-.419.58q-3.064.007-6.128,0A1.033,1.033,0,0,1,461.039,2007.684Z"
            transform="translate(0 -80.717)"
            fill="#fff"
          />
          <path
            id="Path_4193"
            data-name="Path 4193"
            d="M494.054,1899h-32.29a.881.881,0,0,0-.725.725v5.21q16.7,0,33.409.007c.272,0,.342-.059.339-.336-.016-1.429-.028-2.858,0-4.287A1.257,1.257,0,0,0,494.054,1899Zm-10.214,3.955a.988.988,0,1,1,.987-.981A1,1,0,0,1,483.84,1902.957Zm3.971,0a.988.988,0,1,1,.971-1A1,1,0,0,1,487.811,1902.957Zm3.946,0a.988.988,0,1,1,.979-.99A1,1,0,0,1,491.757,1902.957Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_4194"
            data-name="Path 4194"
            d="M579.211,2073.911a1.014,1.014,0,0,0-.8-1.132q-3.276-1.17-6.553-2.336a1.3,1.3,0,0,0-.926.009c-2.172.781-4.348,1.55-6.523,2.322a1.024,1.024,0,0,0-.817,1.15c0,2.077-.036,4.154.007,6.23a8.651,8.651,0,0,0,5.3,8.176,5.227,5.227,0,0,0,3.659.492,8.884,8.884,0,0,0,6.62-8.055c.053-1.107.008-2.219.008-3.329h.022C579.212,2076.262,579.215,2075.087,579.211,2073.911Zm-4.548,5.355q-1.3,1.307-2.606,2.608a1,1,0,0,1-1.556.009c-.475-.472-.952-.941-1.417-1.422a.943.943,0,0,1-.2-1.066.977.977,0,0,1,1.606-.31c.2.2.415.394.6.608.135.154.214.148.357,0,.6-.614,1.2-1.216,1.811-1.819a.992.992,0,1,1,1.4,1.393Z"
            transform="translate(-93.494 -156.241)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default TechnicalControlsIcon;
