import React from "react";
import { Link } from "gatsby";
import {
  Capsule,
  Primary,
  Secondary,
  Transparent,
  Sorting,
  Remove,
} from "./Button.style";

const ButtonVariants = {
  capsule: Capsule,
  outlined: Transparent,
  primary: Primary,
  secondary: Secondary,
  sorting: Sorting,
  remove: Remove,
};

function Button({
  onClick,
  title,
  variant,
  className,
  disabled = false,
  href,
}) {
  return (
    <>
      {href ? (
        <Link
          to={href}
          className={`${className && className}`}
          onClick={onClick}
          style={variant ? ButtonVariants[variant] : {}}
        >
          {title}
        </Link>
      ) : (
        <button
          disabled={disabled}
          id="dropdownButton"
          data-dropdown-toggle="dropdown"
          className={` ${className && className}  ${disabled && "disabled"}`}
          onClick={onClick}
          style={variant ? ButtonVariants[variant] : {}}
        >
          {title}
          {variant === "sorting" && <span class="caret"></span>}
        </button>
      )}
    </>
  );
}

export default Button;
