import React from "react";

function RapidDelivery() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.934"
      height="22"
      viewBox="0 0 24.934 22"
    >
      <defs></defs>
      <g transform="translate(-4 -11.686)">
        <g transform="translate(4 11.686)">
          <rect
            className="a"
            width="5.188"
            height="2.964"
            rx="1.482"
            transform="translate(13.208 0)"
            fill="#ff7b16"
          />

          <path
            className="a"
            d="M48.551,188H44.594a.594.594,0,0,0,0,1.187h3.958a.594.594,0,0,0,0-1.187Z"
            transform="translate(-42.021 -179.06)"
            fill="#ff7b16"
          />

          <path
            fill="#ff7b16"
            className="a"
            d="M33.145,284.594a.594.594,0,0,0-.594-.594H28.594a.594.594,0,1,0,0,1.187h3.958A.594.594,0,0,0,33.145,284.594Z"
            transform="translate(-26.813 -270.311)"
          />
          <path
            fill="#ff7b16"
            className="a"
            d="M63.364,332h-2.77a.594.594,0,0,0,0,1.187h2.77a.594.594,0,0,0,0-1.187Z"
            transform="translate(-57.23 -315.937)"
          />

          <path
            fill="#ff7b16"
            className="a"
            d="M10.332,236.594A.594.594,0,0,0,9.739,236H4.594a.594.594,0,1,0,0,1.187H9.739A.594.594,0,0,0,10.332,236.594Z"
            transform="translate(-4 -224.686)"
          />

          <path
            fill="#ff7b16"
            className="a"
            d="M198.728,142.728V136a6.728,6.728,0,1,0,6.728,6.728Z"
            transform="translate(-182.699 -129.633)"
          />

          <path
            fill="#ff7b16"
            className="a"
            d="M163.416,75.827l.637-.637a.594.594,0,1,0-.84-.84l-.663.662a8.868,8.868,0,0,0-4.458-1.945V72h-2.375v1.069a8.868,8.868,0,0,0-4.458,1.944l-.663-.662a.594.594,0,1,0-.84.84l.637.637a8.9,8.9,0,1,0,13.023,0ZM156.9,89.216a7.322,7.322,0,1,1,7.322-7.322,7.322,7.322,0,0,1-7.322,7.322Z"
            transform="translate(-140.876 -68.799)"
          />
        </g>
      </g>
    </svg>
  );
}

export default RapidDelivery;
