import React from "react";

function NativeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3402"
        data-name="Group 3402"
        transform="translate(740 -2424)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-740 2424)"
          fill="#ff7b16"
        />
        <path
          id="Icon_material-language"
          data-name="Icon material-language"
          d="M13.048,3A10.058,10.058,0,1,0,23.116,13.058,10.053,10.053,0,0,0,13.048,3Zm6.97,6.035H17.051a15.74,15.74,0,0,0-1.388-3.581A8.076,8.076,0,0,1,20.018,9.035Zm-6.96-3.983a14.168,14.168,0,0,1,1.921,3.983H11.137A14.168,14.168,0,0,1,13.058,5.052ZM5.273,15.069a7.868,7.868,0,0,1,0-4.023h3.4a16.611,16.611,0,0,0-.141,2.012,16.611,16.611,0,0,0,.141,2.012ZM6.1,17.081H9.065a15.74,15.74,0,0,0,1.388,3.581A8.033,8.033,0,0,1,6.1,17.081ZM9.065,9.035H6.1a8.033,8.033,0,0,1,4.355-3.581A15.74,15.74,0,0,0,9.065,9.035Zm3.993,12.029a14.168,14.168,0,0,1-1.921-3.983h3.842A14.168,14.168,0,0,1,13.058,21.064Zm2.354-5.994H10.7a14.8,14.8,0,0,1-.161-2.012,14.669,14.669,0,0,1,.161-2.012h4.707a14.669,14.669,0,0,1,.161,2.012A14.8,14.8,0,0,1,15.411,15.069Zm.251,5.592a15.74,15.74,0,0,0,1.388-3.581h2.967a8.076,8.076,0,0,1-4.355,3.581Zm1.78-5.592a16.611,16.611,0,0,0,.141-2.012,16.611,16.611,0,0,0-.141-2.012h3.4a7.868,7.868,0,0,1,0,4.023Z"
          transform="translate(-735.058 2428.942)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default NativeIcon;
