import React from "react";

function LawEnforcementIconTwo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="67.927"
      viewBox="0 0 68 67.927"
    >
      <g
        id="Group_3831"
        data-name="Group 3831"
        transform="translate(-12437 -2788)"
      >
        <g
          id="Group_3831-2"
          data-name="Group 3831"
          transform="translate(12437 2788)"
        >
          <path
            id="Path_4099"
            data-name="Path 4099"
            d="M538.8-1725.319c.022-7.343-1.444-11.807-4.417-15.775-.6-.8-1.21-1.6-1.805-2.411a1.124,1.124,0,0,1,.1-1.75q4.233-4.245,8.478-8.479a1.123,1.123,0,0,1,1.883.027,15.071,15.071,0,0,0,5.762,4.06,13.607,13.607,0,0,0,12.728-1.491c1.257-.827,2.459-1.736,3.685-2.611.89-.635,1.1-.638,2.008.009,1.171.833,2.328,1.686,3.509,2.5a13.782,13.782,0,0,0,17.59-1.428c.361-.349.71-.711,1.07-1.062a1.141,1.141,0,0,1,1.888,0q3.132,3.118,6.251,6.249c.727.727,1.459,1.448,2.18,2.18a1.159,1.159,0,0,1,.122,1.806,43.227,43.227,0,0,0-3.9,5.768c-3.859,7.608-3.141,18.328,3.741,25.262a1.75,1.75,0,0,1,.537,1.341,19.4,19.4,0,0,1-13.651,18.168q-9.756,3.24-19.506,6.5a2.511,2.511,0,0,1-1.712-.019q-9.933-3.333-19.882-6.62a19.375,19.375,0,0,1-13.242-18.049,1.731,1.731,0,0,1,.5-1.286C536.853-1716.8,538.8-1721.7,538.8-1725.319Z"
            transform="translate(-532.207 1754.231)"
            fill="#00173a"
          />
          <path
            id="Path_4100"
            data-name="Path 4100"
            d="M576.152-1677.409c-1.792-.451-3.515-1.116-5.266-1.692-4.83-1.59-9.663-3.174-14.472-4.825-6.653-2.286-10.6-6.933-11.877-13.849-.025-.134-.013-.277-.049-.407a2.9,2.9,0,0,1,.884-2.858,23.51,23.51,0,0,0,5.468-17.823,24.133,24.133,0,0,0-5.6-13.5c-.453-.557-.446-.859.084-1.37,2.119-2.047,4.192-4.142,6.256-6.244.363-.37.545-.352.911,0a15.961,15.961,0,0,0,20.7,1.47c.853-.612,1.7-1.227,2.571-1.82.15-.1.284-.43.558-.159a12.442,12.442,0,0,1-.039,2.792c-1.174.82-2.359,1.624-3.61,2.325a18.531,18.531,0,0,1-10.277,2.339,17.75,17.75,0,0,1-9.68-2.988.989.989,0,0,0-1.474.121q-1.534,1.469-3,3c-.53.557-.514.963.028,1.708a31.91,31.91,0,0,1,2.6,4.266,22.833,22.833,0,0,1,2.436,12.07,27.622,27.622,0,0,1-6.067,15.792,1.826,1.826,0,0,0-.414,1.574,13.638,13.638,0,0,0,7.323,10.016,21.715,21.715,0,0,0,3.6,1.5q8.95,3.042,17.867,6.179c.253.089.54.137.678.421A2.871,2.871,0,0,1,576.152-1677.409Z"
            transform="translate(-542.152 1743.089)"
            fill="#c8e2ff"
          />
          <path
            id="Path_4101"
            data-name="Path 4101"
            d="M717.808-1737.139c0-.751-.01-1.5,0-2.254,0-.217-.079-.464.157-.626q1.453,1.026,2.9,2.053a15.965,15.965,0,0,0,20.581-1.53c.384-.369.578-.381.954,0q3.168,3.225,6.392,6.4c.357.352.415.578.081.993a23.983,23.983,0,0,0-4.551,7.974c-2.723,8.954-1.384,17.124,4.882,24.279a1.457,1.457,0,0,1,.368,1.238c-.831,7.391-4.671,12.519-11.683,15.02-6.476,2.31-13.04,4.373-19.565,6.548a1.036,1.036,0,0,1-.527.105l.011-2.043a9.322,9.322,0,0,1,2.584-1.031q5.788-2.021,11.585-4.019c1.986-.686,3.994-1.314,5.947-2.085a14.381,14.381,0,0,0,8.518-8.217,13.893,13.893,0,0,0,.75-2.983,1.451,1.451,0,0,0-.4-1.185,27.433,27.433,0,0,1-5.132-9.983c-2.025-7.452-1.17-14.473,3.164-20.966.33-.494.659-.987,1-1.475a.992.992,0,0,0-.077-1.475q-1.49-1.562-3.052-3.051a.99.99,0,0,0-1.477-.072,17.264,17.264,0,0,1-8.528,2.867,20.019,20.019,0,0,1-8.57-.95,22.4,22.4,0,0,1-5.35-2.882A3.926,3.926,0,0,1,717.808-1737.139Z"
            transform="translate(-683.797 1742.622)"
            fill="#fff"
          />
          <path
            id="Path_4102"
            data-name="Path 4102"
            d="M586.228-1724.3a5.1,5.1,0,0,1,1.9,1.052,19.108,19.108,0,0,0,14.006,3.14,16.048,16.048,0,0,0,7.533-2.836,1.059,1.059,0,0,1,1.49.118c1.076,1.055,2.145,2.118,3.2,3.2.524.538.508.991-.005,1.711a27.988,27.988,0,0,0-4,7.6,25.492,25.492,0,0,0,.32,16.008,25.9,25.9,0,0,0,4.687,8.522,1.736,1.736,0,0,1,.408,1.511,13.751,13.751,0,0,1-9.335,11.125c-6.573,2.447-13.241,4.636-19.87,6.935a2.717,2.717,0,0,1-.333.073c-2.158-.732-4.318-1.454-6.472-2.2-4.588-1.587-9.209-3.088-13.748-4.805a13.942,13.942,0,0,1-9.183-10.255,3.056,3.056,0,0,1,.621-2.884,26.987,26.987,0,0,0,5.651-14.442,24.151,24.151,0,0,0-4.78-16.853c-.867-1.214-.853-1.425.21-2.489q1.284-1.284,2.569-2.566c.685-.679,1.092-.722,1.855-.18a16.534,16.534,0,0,0,8.049,2.793,19.377,19.377,0,0,0,10.61-1.642,34.548,34.548,0,0,0,3.688-2.226A2.032,2.032,0,0,1,586.228-1724.3Z"
            transform="translate(-552.217 1729.78)"
            fill="#00173a"
          />
          <path
            id="Path_4103"
            data-name="Path 4103"
            d="M716.813-1710.672a15.205,15.205,0,0,1,2.087,1.277,21.418,21.418,0,0,0,13.168,2.729,20.078,20.078,0,0,0,8.278-2.617.553.553,0,0,1,.821.112,10.674,10.674,0,0,0,1.163,1.159c.406.338.377.6.082,1.011a25.114,25.114,0,0,0-4.378,10.515,28.82,28.82,0,0,0,1.875,15.951,27.518,27.518,0,0,0,3.867,6.667,1.332,1.332,0,0,1,.25,1.265,11.792,11.792,0,0,1-7.713,8.513c-4.392,1.654-8.857,3.113-13.291,4.654q-2.841.987-5.688,1.958a4.58,4.58,0,0,1-.527.116.745.745,0,0,1-.2-.5c0-1.925-.1-3.851.051-5.773.138-.376.5-.355.8-.431a3.165,3.165,0,0,0,2.324-2.2,2.709,2.709,0,0,0-.323-2.364,1.083,1.083,0,0,1,.027-1.266c.865-1.424,1.7-2.866,2.519-4.318a1.31,1.31,0,0,1,1.269-.744c1.689.013,3.379.018,5.069,0a1.273,1.273,0,0,1,1.216.692,2.873,2.873,0,0,0,2.96,1.449,3.024,3.024,0,0,0,2.633-2.285,3.087,3.087,0,0,0-2.782-3.839,1.626,1.626,0,0,1-1.511-.934c-.761-1.4-1.6-2.767-2.4-4.148a1.077,1.077,0,0,1-.01-1.21c.828-1.42,1.687-2.822,2.474-4.265a1.455,1.455,0,0,1,1.362-.823,3.09,3.09,0,0,0,2.95-3.544,3.165,3.165,0,0,0-2.434-2.575,2.987,2.987,0,0,0-3.19,1.365,1.451,1.451,0,0,1-1.456.762c-1.6-.03-3.2-.024-4.795,0a1.39,1.39,0,0,1-1.379-.793c-.8-1.437-1.634-2.853-2.483-4.26a1.08,1.08,0,0,1-.042-1.264,3.1,3.1,0,0,0-2.221-4.653c-.226-.03-.459-.071-.559-.329a40.279,40.279,0,0,1-.063-4.561A.715.715,0,0,1,716.813-1710.672Z"
            transform="translate(-682.804 1718.651)"
            fill="#0c6bc2"
          />
          <path
            id="Path_4104"
            data-name="Path 4104"
            d="M596.259-1710.672q0,2.583.009,5.166c-.143.231-.395.2-.609.248a3.063,3.063,0,0,0-2.119,4.465,1.425,1.425,0,0,1-.06,1.577c-.848,1.381-1.655,2.787-2.456,4.2a1.213,1.213,0,0,1-1.152.7c-1.758-.008-3.516-.015-5.274,0a1.235,1.235,0,0,1-1.118-.737,3.013,3.013,0,0,0-3.9-1.143,3.073,3.073,0,0,0-1.625,3.793,3.07,3.07,0,0,0,2.791,2.077,1.523,1.523,0,0,1,1.447.921c.762,1.4,1.6,2.766,2.4,4.147a1.074,1.074,0,0,1,.021,1.208c-.828,1.42-1.681,2.826-2.475,4.265a1.457,1.457,0,0,1-1.357.834,3.078,3.078,0,0,0-2.977,2.783,3.1,3.1,0,0,0,2.378,3.316,2.987,2.987,0,0,0,3.3-1.39,1.345,1.345,0,0,1,1.339-.715q2.465.036,4.932,0a1.342,1.342,0,0,1,1.315.779q1.187,2.121,2.45,4.2a1.2,1.2,0,0,1,.059,1.391,3.075,3.075,0,0,0,2,4.526c.237.056.508.051.68.277l-.011,6.425c-1.593-.4-3.115-1.017-4.665-1.545-4.854-1.653-9.717-3.284-14.532-5.047a11.933,11.933,0,0,1-8-8.582,1.483,1.483,0,0,1,.277-1.395,28.68,28.68,0,0,0,5.778-13.635,26.1,26.1,0,0,0-4.414-19.371c-.324-.464-.366-.754.1-1.127a11.328,11.328,0,0,0,1.166-1.157.5.5,0,0,1,.72-.085,19.021,19.021,0,0,0,7.384,2.545,21.343,21.343,0,0,0,15.036-3.226A3.548,3.548,0,0,1,596.259-1710.672Z"
            transform="translate(-562.25 1718.651)"
            fill="#012458"
          />
          <path
            id="Path_4105"
            data-name="Path 4105"
            d="M634.619-1640.751a3.289,3.289,0,0,1-2.754-5.071.633.633,0,0,0,0-.79q-1.372-2.288-2.686-4.61a.913.913,0,0,0-.928-.521c-1.781.022-3.562.018-5.343,0a.759.759,0,0,0-.8.458,3.2,3.2,0,0,1-3.964,1.539,3.306,3.306,0,0,1-2.154-3.631,3.274,3.274,0,0,1,3.284-2.728.932.932,0,0,0,.958-.562c.859-1.531,1.756-3.041,2.658-4.547a.687.687,0,0,0-.02-.809c-.9-1.508-1.794-3.019-2.655-4.549a.888.888,0,0,0-.908-.53,3.247,3.247,0,0,1-3.344-3.38,3.271,3.271,0,0,1,3.488-3.171,3.043,3.043,0,0,1,2.6,1.64.911.911,0,0,0,.954.549q2.6-.036,5.206,0a.958.958,0,0,0,.979-.551c.857-1.533,1.751-3.044,2.644-4.556.16-.271.259-.466.044-.788a3.287,3.287,0,0,1,2.739-5.114,3.271,3.271,0,0,1,2.69,5.145.568.568,0,0,0,.011.671q1.4,2.389,2.788,4.79a.711.711,0,0,0,.711.4q2.808-.023,5.615,0a.615.615,0,0,0,.622-.394,3.239,3.239,0,0,1,4-1.613,3.316,3.316,0,0,1,2.161,3.632,3.307,3.307,0,0,1-3.416,2.738.732.732,0,0,0-.748.433q-1.34,2.347-2.724,4.668a.68.68,0,0,0,.006.8q1.366,2.292,2.685,4.612a.831.831,0,0,0,.86.48,3.293,3.293,0,0,1,3.348,2.9,3.281,3.281,0,0,1-2.611,3.573,3.177,3.177,0,0,1-3.516-1.637.752.752,0,0,0-.785-.469c-1.8.018-3.607.021-5.41,0a.852.852,0,0,0-.857.506c-.877,1.548-1.783,3.078-2.682,4.614-.138.235-.281.422-.064.729A3.287,3.287,0,0,1,634.619-1640.751Z"
            transform="translate(-600.606 1695.616)"
            fill="#00173a"
          />
          <path
            id="Path_4106"
            data-name="Path 4106"
            d="M661.191-1618.006c-.479.152-.726-.072-.96-.484-.988-1.745-2.026-3.461-3.012-5.207a1.565,1.565,0,0,0-1.549-.878c-2.053.033-4.108.013-6.161.011a1,1,0,0,1-.884-1.506c1.032-1.772,2.055-3.55,3.108-5.309a1.515,1.515,0,0,0,0-1.721c-1.054-1.759-2.076-3.537-3.109-5.309a1,1,0,0,1,.882-1.505c2.076,0,4.153-.017,6.23.009a1.483,1.483,0,0,0,1.444-.821c1.006-1.761,2.048-3.5,3.047-5.265.233-.411.481-.637.961-.481a.824.824,0,0,1,.209.632c.01,2.554.03,5.108-.01,7.662a1.084,1.084,0,0,1-.048.267c-.167.444-.592.366-.929.431a5.274,5.274,0,0,0-4,2.984,5.3,5.3,0,0,0,3.63,7.432c.439.1.99-.041,1.285.474a6.321,6.321,0,0,1,.076,1.559q.01,2.994,0,5.988A1.378,1.378,0,0,1,661.191-1618.006Z"
            transform="translate(-627.187 1666.252)"
            fill="#ff7b16"
          />
          <path
            id="Path_4107"
            data-name="Path 4107"
            d="M717.843-1618q0-4.365,0-8.729c.238-.275.586-.223.888-.283a5.3,5.3,0,0,0,4.415-5.255,5.3,5.3,0,0,0-4.415-5.187c-.3-.06-.651-.008-.888-.284v-8.729c.481-.155.726.077.958.486.989,1.744,2.027,3.46,3.012,5.206a1.565,1.565,0,0,0,1.55.877c2.076-.032,4.153-.014,6.23-.01a1.015,1.015,0,0,1,.846,1.448c-1.042,1.792-2.078,3.589-3.144,5.367a1.511,1.511,0,0,0,0,1.72c1.054,1.759,2.076,3.537,3.108,5.309a1,1,0,0,1-.884,1.5c-2.077,0-4.154.018-6.23-.009a1.48,1.48,0,0,0-1.444.819c-1,1.761-2.046,3.5-3.045,5.266C718.57-1618.074,718.323-1617.844,717.843-1618Z"
            transform="translate(-683.839 1666.247)"
            fill="#ff8f3a"
          />
          <path
            id="Path_4108"
            data-name="Path 4108"
            d="M630.252-1621.348a1.081,1.081,0,0,1-1.049,1.121,1.082,1.082,0,0,1-1.138-1.1,1.079,1.079,0,0,1,1.083-1.087A1.082,1.082,0,0,1,630.252-1621.348Z"
            transform="translate(-610.506 1646.56)"
            fill="#91c3fc"
          />
          <path
            id="Path_4109"
            data-name="Path 4109"
            d="M797.855-1621.331a1.1,1.1,0,0,1-1.058,1.111,1.1,1.1,0,0,1-1.128-1.041,1.1,1.1,0,0,1,1.093-1.145A1.1,1.1,0,0,1,797.855-1621.331Z"
            transform="translate(-747.408 1646.553)"
            fill="#fff"
          />
          <path
            id="Path_4110"
            data-name="Path 4110"
            d="M629.155-1526.637a1.082,1.082,0,0,1,1.092,1.079,1.083,1.083,0,0,1-1.13,1.109,1.081,1.081,0,0,1-1.058-1.112A1.083,1.083,0,0,1,629.155-1526.637Z"
            transform="translate(-610.501 1568.327)"
            fill="#91c3fc"
          />
          <path
            id="Path_4111"
            data-name="Path 4111"
            d="M796.776-1526.629a1.1,1.1,0,0,1,1.08,1.09,1.1,1.1,0,0,1-1.142,1.1,1.1,1.1,0,0,1-1.045-1.125A1.1,1.1,0,0,1,796.776-1526.629Z"
            transform="translate(-747.409 1568.32)"
            fill="#fff"
          />
          <path
            id="Path_4112"
            data-name="Path 4112"
            d="M712.975-1668.063c-.732-.1-1.093-.442-1.11-1.045-.018-.634.354-1.007,1.11-1.115A2.673,2.673,0,0,1,712.975-1668.063Z"
            transform="translate(-678.956 1685.611)"
            fill="#91c3fc"
          />
          <path
            id="Path_4113"
            data-name="Path 4113"
            d="M717.926-1668.063q0-1.08,0-2.16c.727.136,1.077.488,1.077,1.08S718.654-1668.2,717.926-1668.063Z"
            transform="translate(-683.907 1685.611)"
            fill="#fff"
          />
          <path
            id="Path_4114"
            data-name="Path 4114"
            d="M712.972-1476.513c-.731-.1-1.093-.442-1.111-1.045-.019-.632.355-1.008,1.109-1.115A2.668,2.668,0,0,1,712.972-1476.513Z"
            transform="translate(-678.952 1529.149)"
            fill="#91c3fc"
          />
          <path
            id="Path_4115"
            data-name="Path 4115"
            d="M717.922-1476.513q0-1.08,0-2.16c.726.138,1.1.492,1.072,1.078A1.039,1.039,0,0,1,717.922-1476.513Z"
            transform="translate(-683.903 1529.149)"
            fill="#fff"
          />
          <path
            id="Path_4116"
            data-name="Path 4116"
            d="M693.4-1598.6a5.514,5.514,0,0,1,5.471,5.233c.143,3.142-2.1,5.5-5.471,5.776a5.516,5.516,0,0,1-5.481-5.368C687.86-1596.049,690.086-1598.338,693.4-1598.6Z"
            transform="translate(-659.395 1627.109)"
            fill="#00173a"
          />
          <path
            id="Path_4117"
            data-name="Path 4117"
            d="M703.194-1579.833a3.272,3.272,0,0,1-3.3-3.3,3.267,3.267,0,0,1,3.3-3.227,1.008,1.008,0,0,1,.205.77q.014,2.494,0,4.989A1.01,1.01,0,0,1,703.194-1579.833Z"
            transform="translate(-669.181 1617.112)"
            fill="#c8e2ff"
          />
          <path
            id="Path_4118"
            data-name="Path 4118"
            d="M717.887-1579.833q0-3.265,0-6.529a3.257,3.257,0,0,1,3.277,3.3A3.263,3.263,0,0,1,717.887-1579.833Z"
            transform="translate(-683.875 1617.112)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default LawEnforcementIconTwo;
