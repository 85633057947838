import React from "react";

function UnauthorizedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="65"
      viewBox="0 0 65 65"
    >
      <g
        id="Group_3857"
        data-name="Group 3857"
        transform="translate(-481 -3341)"
      >
        <path
          id="Path_3464"
          data-name="Path 3464"
          d="M32.5,0A32.5,32.5,0,1,1,0,32.5,32.5,32.5,0,0,1,32.5,0Z"
          transform="translate(481 3341)"
          fill="#ff7b16"
        />
        <g
          id="Group_3856"
          data-name="Group 3856"
          transform="translate(-778.679 1641.925)"
        >
          <path
            id="Path_4188"
            data-name="Path 4188"
            d="M1307,1748.115q0-12.1,0-24.192c0-.1,0-.2,0-.3-.015-.422-.094-.5-.528-.514-.124,0-.248,0-.371,0h-7.456c-.62,0-.62,0-.62-.634q0-3.813,0-7.625c0-.64,0-.64-.622-.64h-19.94c-.787,0-.787,0-.787.778q0,16.55,0,33.1c0,.124,0,.247,0,.371a.508.508,0,0,0,.108.346H1306.9A1,1,0,0,0,1307,1748.115Zm-15.177-2.355a10.06,10.06,0,1,1,10.075-10.071A10.061,10.061,0,0,1,1291.827,1745.76Z"
            transform="translate(0)"
            fill="#fff"
          />
          <path
            id="Path_4189"
            data-name="Path 4189"
            d="M1518.74,1715.754l8.261,8.261h-7.968c-.164,0-.315.05-.314-.237.013-2.655.008-5.31.009-7.965A.182.182,0,0,1,1518.74,1715.754Z"
            transform="translate(-220.203 -1.411)"
            fill="#fff"
          />
          <path
            id="Path_4190"
            data-name="Path 4190"
            d="M1365.46,1865.408a7.869,7.869,0,0,1,4.769,1.574c.191.141.212.213.033.392q-5.33,5.313-10.642,10.645c-.2.2-.271.145-.412-.043a7.872,7.872,0,0,1,5.169-12.487C1364.746,1865.436,1365.116,1865.429,1365.46,1865.408Z"
            transform="translate(-73.656 -137.563)"
            fill="#fff"
          />
          <path
            id="Path_4191"
            data-name="Path 4191"
            d="M1403.478,1903.754a7.462,7.462,0,0,1-4.312,6.548,7.622,7.622,0,0,1-8.217-.671c-.24-.166-.268-.256-.041-.482q5.3-5.273,10.571-10.57c.217-.217.3-.18.46.043A8.27,8.27,0,0,1,1403.478,1903.754Z"
            transform="translate(-103.781 -167.61)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default UnauthorizedIcon;
