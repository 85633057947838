import React from "react";
import Modal from "../../Modal/index";
import Text from "../../Text/index";
import Button from "../../Button/index";
import SuccessIcon from "../../../assets/svg/SuccessIcon";
import { thankYouPopUpData } from "../../../core/utils/sample-data";
import textResolver from "../../../core/utils/text-resolver";

function ThankYouPopUp({ open, setOpen, dataType = 0 }) {
  return (
    <Modal visible={open} onClick={() => setOpen(!open)}>
      <div className="text-darkBlue w-[260px] flex justify-center items-center flex-col text-center">
        <div className="pt-2">
          <SuccessIcon />
        </div>
        <Text highlightedColor="yellow" as="h2" highlightedText={["Thank"]}>
          {dataType === 1
            ? thankYouPopUpData.title
            : textResolver(thankYouPopUpData, "title")}
        </Text>
        <Text as="p">
          {dataType === 1
            ? thankYouPopUpData.message
            : textResolver(thankYouPopUpData, "message")}
        </Text>
        <Button
          onClick={() => setOpen(!open)}
          title={
            dataType === 1
              ? thankYouPopUpData.buttonTitle
              : textResolver(thankYouPopUpData, "buttonTitle")
          }
          variant="capsule"
          className="text-white text-[16px] mt-5 uppercase font-opensans font-bold px-12 w-[130px]"
        />
      </div>
    </Modal>
  );
}
export default ThankYouPopUp;
