import React from "react";

const PhoneFooterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.008"
    height="13.008"
    viewBox="0 0 13.008 13.008"
  >
    <defs>{/* <style>.a{fill="#3a7aff"}</style> */}</defs>
    <path
      className="a"
      // fill="#c5c5c5"
      d="M12.637,9.192,9.791,7.973a.61.61,0,0,0-.711.175L7.82,9.688a9.417,9.417,0,0,1-4.5-4.5l1.54-1.26a.608.608,0,0,0,.175-.711L3.813.369a.614.614,0,0,0-.7-.353L.473.626A.61.61,0,0,0,0,1.22,11.787,11.787,0,0,0,11.789,13.008a.61.61,0,0,0,.595-.473l.61-2.642a.617.617,0,0,0-.356-.7Z"
      transform="translate(0 0)"
    />
  </svg>
);

export default PhoneFooterIcon;
