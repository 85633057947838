import React from "react";

function ArbitrationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <g
        id="Group_3833"
        data-name="Group 3833"
        transform="translate(-12526 -4023)"
      >
        <path
          id="Path_4119"
          data-name="Path 4119"
          d="M155.206-2597.276a25.06,25.06,0,0,0-25.009,25.017,25.048,25.048,0,0,0,24.99,24.983,25.061,25.061,0,0,0,25.009-25.017A25.048,25.048,0,0,0,155.206-2597.276Zm0,47.706a20.155,20.155,0,0,1-3.743-.3,21.942,21.942,0,0,1-10.652-4.854,22.469,22.469,0,0,1-7.783-12.593,23.347,23.347,0,0,1-.541-4.2c0-.22,0-.4,0-.573,0-.763.415-1.244,1.066-1.247a1.071,1.071,0,0,1,1.077,1.183,23.078,23.078,0,0,0,.393,3.735,19.958,19.958,0,0,0,3.258,7.792,20.468,20.468,0,0,0,8.611,7.142,20.9,20.9,0,0,0,7.684,1.764c.23.01.46,0,.688.029a1.052,1.052,0,0,1,1,1.091A1.044,1.044,0,0,1,155.2-2549.57Zm3.551-44.1c-.055.63-.588,1.006-1.344.948l-.264-.021v-.006c-.635-.031-1.27-.056-1.9-.1a1.068,1.068,0,0,1-1.11-1.1,1.074,1.074,0,0,1,1.16-1.046,20.929,20.929,0,0,1,2.274.115C158.34-2594.806,158.812-2594.336,158.754-2593.673Zm18.116,22.462a1.043,1.043,0,0,1-1.1-1.052,26.249,26.249,0,0,0-.507-4.468,20.241,20.241,0,0,0-7.453-11.787,21.448,21.448,0,0,0-4.937-2.839c-.624-.263-.894-.652-.851-1.138a1.083,1.083,0,0,1,1.561-.9,21.822,21.822,0,0,1,4.556,2.444,22.671,22.671,0,0,1,8.678,11.694,22.537,22.537,0,0,1,1.088,6.185c0,.247.007.5,0,.742A1.069,1.069,0,0,1,176.87-2571.211Z"
          transform="translate(12395.803 6620.276)"
          fill="#ff7b16"
        />
        <g
          id="Group_3832"
          data-name="Group 3832"
          transform="translate(12540.922 4038.113)"
        >
          <path
            id="Path_3461"
            data-name="Path 3461"
            d="M-734.927,873.858c0-.424.031-.844-.007-1.258a2.254,2.254,0,0,1,.457-1.61c.356-.49.658-1.02.97-1.541a.809.809,0,0,0,.113-.4q.01-3.588.005-7.176V861.6h16.934v.259c0,2.392,0,4.784.005,7.176a.889.889,0,0,0,.127.436c.428.7.874,1.4,1.3,2.1a.684.684,0,0,1,.1.333c.009.644,0,1.289,0,1.952Zm9.248-2.3h1.509v-8.413h-1.509Zm-3.1,0v-8.414h-1.513v8.414Zm9.227,0v-8.412h-1.512v8.412Z"
            transform="translate(734.946 -853.945)"
            fill="#fff"
          />
          <path
            id="Path_3462"
            data-name="Path 3462"
            d="M-714.675,740.3h-20.009c0-.229-.013-.451.008-.671.006-.064.1-.137.169-.175q2.425-1.31,4.854-2.612,2.388-1.283,4.775-2.57a.357.357,0,0,1,.389-.008q4.822,2.6,9.651,5.188a.259.259,0,0,1,.166.277C-714.684,739.918-714.675,740.1-714.675,740.3Z"
            transform="translate(734.703 -734.214)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default ArbitrationIcon;
