import React from "react";

const YoutubeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="30"
    viewBox="0 0 20 15.003"
  >
    <defs>{/* <style>.a{fill="#c5c5c5;"}</style> */}</defs>
    <path
      // fill="#c5c5c5"
      className="a"
      d="M19.87,7.813a3.037,3.037,0,0,0-2.891-3.172c-2.164-.1-4.372-.141-6.626-.141h-.7c-2.25,0-4.462.039-6.626.141A3.044,3.044,0,0,0,.137,7.821C.039,9.212,0,10.6,0,11.993s.039,2.782.133,4.176A3.047,3.047,0,0,0,3.02,19.353c2.274.105,4.606.152,6.978.148s4.7-.039,6.978-.148a3.048,3.048,0,0,0,2.891-3.184c.094-1.395.137-2.786.133-4.18S19.963,9.208,19.87,7.813ZM8.087,15.826V8.149l5.665,3.836Z"
      transform="translate(0 -4.5)"
    />
  </svg>
);

export default YoutubeIcon;
